<template>
  <div class="flex items-center rounded-md shadow-sm md:items-stretch">
    <button
      type="button"
      @click="goToPreviousWeek"
      class="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
    >
      <span class="sr-only">Previous week</span>
      <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
    </button>
    <button
      type="button"
      @click="isDatePickerModalOpen = true"
      class="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block whitespace-nowrap"
    >
      Week {{ calendarWeek }}
    </button>
    <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
    <button
      type="button"
      @click="goToNextWeek"
      class="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
    >
      <span class="sr-only">Next week</span>
      <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
    </button>
  </div>
  <DatePickerModal
    v-if="isDatePickerModalOpen"
    :open="true"
    @closeModal="isDatePickerModalOpen = false"
    @searchDate="handleDatePickerModalChange"
  ></DatePickerModal>
</template>

<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { add, endOfWeek, parseISO, startOfWeek } from "date-fns";
import { ref } from "vue";
import { DateInterval } from "@/types/DateInterval";
import DatePickerModal from "@/views/process_validation/components/inputs/DatePickerModal.vue";

const props = defineProps<{ startDate: Date; endDate: Date; calendarWeek: number }>();

const emit = defineEmits<{ (eventName: "change", event: DateInterval): void }>();

const isDatePickerModalOpen = ref(false);

const changeStartEndByDaysDelta = (daysDelta: number) => {
  const startDate = add(props.startDate, { days: daysDelta });
  const endDate = add(props.endDate, { days: daysDelta });
  emit("change", { startDate, endDate });
};

const goToPreviousWeek = () => {
  changeStartEndByDaysDelta(-7);
};

const goToNextWeek = () => {
  changeStartEndByDaysDelta(7);
};

const handleDatePickerModalChange = (dateText: string) => {
  const date = parseISO(dateText);
  const startDate = startOfWeek(date, { weekStartsOn: 1 });
  const endDate = endOfWeek(date, { weekStartsOn: 1 });
  emit("change", { startDate, endDate });
  isDatePickerModalOpen.value = false;
};
</script>
