import axios from "axios";
import { getConfig } from "@/services/config";

const config = getConfig();

const apiClient = axios.create({
  baseURL: config.API_BASE,
});

export { apiClient };
