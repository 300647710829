import { AxiosResponse } from "axios";
import { apiClient } from "@/repositories/clients";
import { HierarchyTagStore } from "@/types/HierarchyTag";

type HierarchyTagPostResponse = {
  tags: HierarchyTagStore[];
  id_map: Record<string, string>;
  splits_id_map: Record<string, string>;
};

const loadHierarchyData = (customerName: string, siteId: string): Promise<HierarchyTagStore[]> =>
  apiClient
    .get<void, AxiosResponse<HierarchyTagStore[]>>(
      `/planner-v2/hierarchy/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const saveHierarchyData = (
  customerName: string,
  siteId: string,
  updatedTags: HierarchyTagStore[],
): Promise<HierarchyTagPostResponse> =>
  apiClient
    .post<void, AxiosResponse<HierarchyTagPostResponse>>(
      `/planner-v2/hierarchy/${customerName}/${siteId}`,
      { hierarchy_tags: updatedTags },
    )
    .then((response) => response.data);

const loadAllHierarchyData = (): Promise<HierarchyTagStore[]> =>
  apiClient
    .get<void, AxiosResponse<HierarchyTagStore[]>>(`/planner-v2/hierarchy/all`)
    .then((response) => response.data);

export default {
  loadHierarchyData,
  saveHierarchyData,
  loadAllHierarchyData,
};
