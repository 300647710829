const configEnum = {
  SENTRY_DSN: String,
  API_BASE: String,
} as const;

type Config = {
  SENTRY_DSN: string;
  API_BASE: string;
};

let config = null as unknown as Config;

export const getConfig = () => {
  if (config === null) {
    const missingConfigurations = {} as Config;

    Object.entries(configEnum).forEach((entry) => {
      const key = entry[0] as keyof typeof configEnum;
      const type = entry[1];

      const value = process.env[`VUE_APP_${key}`];

      if (value === undefined) {
        throw new Error(`Missing configuration for ${key} in .env file`);
      }

      if (type === String) {
        (missingConfigurations[key] as unknown) = value;
      } else {
        throw new Error(`Invalid type ${type}`);
      }
    });

    config = missingConfigurations;
  }

  return config;
};
