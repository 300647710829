import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useCurrentCustomerName, useCurrentSiteId } from "oai-planner";
import { useCustomToast } from "@/composables/toast";
import PlannerRepository from "@/repositories/PlannerRepository";
import logger from "@/services/logger";
import { SectioningPdf } from "@/types/SectioningPdf";

export const useSectioningPdfs = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const { data, isLoading } = useQuery<SectioningPdf[]>({
    queryKey: ["sectioning-pdfs", currentCustomerName, currentSiteId],
    queryFn: () => PlannerRepository.loadSectioningPdfs(currentCustomerName, currentSiteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load sectioning PDFs");
      return false;
    },
  });

  return { sectioningPdfs: data, isLoading };
};

export const useCreateSectioningPdf = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();

  const { mutateAsync: createSectioningPdf, isLoading } = useMutation<SectioningPdf, Error, File>({
    mutationFn: (file) =>
      PlannerRepository.createSectioningPdf(currentCustomerName, currentSiteId, file),
    onSuccess: (sectioningPdf) => {
      queryClient.setQueryData(
        ["sectioning-pdfs", currentCustomerName, currentSiteId],
        (data?: SectioningPdf[]) => data && [...data, sectioningPdf],
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to create sectioning PDF");
      return false;
    },
  });

  return { createSectioningPdf, isLoading };
};

export const useDeleteSectioningPdf = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteSectioningPdf, isLoading } = useMutation<void, Error, string>({
    mutationFn: (_id) =>
      PlannerRepository.deleteSectioningPdf(currentCustomerName, currentSiteId, _id),
    onSuccess: (result, _id) => {
      queryClient.setQueryData(
        ["sectioning-pdfs", currentCustomerName, currentSiteId],
        (data?: SectioningPdf[]) => data?.filter((sectioningPdf) => sectioningPdf._id !== _id),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to delete sectioning PDF");
      return false;
    },
  });

  return { deleteSectioningPdf, isLoading };
};
