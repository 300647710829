<template>
  <SlideOver :open="open" @closeSlideOver="$emit('closed')" customCls="sm:w-2/3">
    <template #title>{{ resourceFullName }}</template>
    <template #content>
      <div class="flex flex-col gap-3 mx-2">
        <div
          class="text-right plannedBg rounded bg-yell text-gray-600 w-min self-end px-1.5 py-0.5 text-sm"
        >
          {{ formatDate(processes[0].start_time) }}
        </div>
        <div
          v-if="processes.length > 1"
          class="flex items-center gap-2 border-b-2 mb-3 select-none"
        >
          <ChevronLeftIcon
            class="w-5 h-5 cursor-pointer"
            @click="goTabLeft"
            :class="[selectedProcessIndex > 0 ? '' : 'text-gray-300']"
            v-if="processes.length > visibleTabCount"
          />
          <nav class="isolate flex flex-1">
            <div
              v-for="process in processes.slice(processTabSkip, processTabSkip + visibleTabCount)"
              :key="process._id"
              :class="[
                selectedProcess === process ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                'group relative min-w-0 flex-1 bg-white py-2 px-3 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 cursor-pointer',
              ]"
              @click="selectProcess(process)"
            >
              <div class="break-all">
                {{ getProcessLabel(process.encoded_label) }}
              </div>
              <div class="text-xs text-gray-500">
                {{ formatTime(process.start_time) }} - {{ formatTime(process.end_time) }}
              </div>
              <span
                aria-hidden="true"
                :class="[
                  selectedProcess === process ? 'bg-yellow-400' : 'bg-transparent',
                  'absolute inset-x-0 h-0.5',
                ]"
                style="bottom: -2px"
              />
            </div>
          </nav>
          <ChevronRightIcon
            class="w-5 h-5 cursor-pointer"
            @click="goTabRight"
            :class="[selectedProcessIndex < processes.length - 1 ? '' : 'text-gray-300']"
            v-if="processes.length > visibleTabCount"
          />
        </div>
        <div v-if="selectedProcess">
          <div class="border-b pb-5 flex flex-col gap-3">
            <div class="text-xl font-bold">
              {{ getProcessLabel(selectedProcess.encoded_label) }}
            </div>
            <div class="text-sm text-gray-600">
              {{ formatTime(selectedProcess.start_time) }} -
              {{ formatTime(selectedProcess.end_time) }} ({{
                getFormattedDuration(selectedProcess.start_time, selectedProcess.end_time)
              }})
            </div>
            <div v-if="selectedProcess.breaks.length > 0" class="text-sm text-gray-600 font-normal">
              <div>Breaks:</div>
              <div
                :key="`${breakItem.start_time}-${breakItem.end_time}`"
                v-for="(breakItem, index) in selectedProcess.breaks"
              >
                {{ index + 1 }}. {{ formatTime(breakItem.start_time) }} -
                {{ formatTime(breakItem.end_time) }} ({{
                  getFormattedDuration(breakItem.start_time, breakItem.end_time)
                }})
              </div>
            </div>
          </div>
          <div class="py-5 flex flex-col gap-2">
            <VideoPlayer
              v-if="videoUrls[selectedProcess._id]"
              :src="videoUrls[selectedProcess._id]"
              controls
              class="video-js vjs-big-play-centered vjs-4-3"
              :playbackRates="[0.25, 0.5, 1, 2, 5, 10]"
              fluid
              fill
              muted
            />
            <div
              v-if="videoUrlLoading"
              class="text-white-400 flex items-center justify-center video-aspect-ratio bg-gray-900"
            >
              Loading...
            </div>
            <div
              v-if="!videoUrlLoading && !videoUrls[selectedProcess._id]"
              class="text-white-400 flex items-center justify-center video-aspect-ratio bg-gray-900"
            >
              Video not found
            </div>
            <div
              @click="openProcessValidationPage(selectedProcess)"
              class="flex self-end gap-1 items-center cursor-pointer text-sm"
            >
              <ArrowTopRightOnSquareIcon class="w-5 h-5" />
              Validation
            </div>
          </div>
        </div>
      </div>
    </template>
  </SlideOver>
</template>

<script lang="ts">
import {
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/vue/24/outline";
import { VideoPlayer } from "@videojs-player/vue";
import { format, intervalToDuration } from "date-fns";
import { SimplifiedPlannerProcess } from "oai-planner";
import { defineComponent, PropType } from "vue";
import { useRouter, useRoute } from "vue-router";
import SlideOver from "@/components/other/SlideOver.vue";
import { useProcessClasses } from "@/composables/process";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";
import logger from "@/services/logger";

export default defineComponent({
  name: "ProcessSideBar",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    processes: {
      type: Array as PropType<Array<SimplifiedPlannerProcess>>,
      required: true,
    },
    resourceFullName: {
      type: String,
      required: true,
    },
  },
  emits: ["closed"],
  components: {
    ArrowTopRightOnSquareIcon,
    SlideOver,
    VideoPlayer,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      videoUrls: {} as Record<string, string>,
      videoUrlLoading: false as boolean,
      selectedProcessIndex: 0 as number,
      processTabSkip: 0 as number,
    };
  },
  setup() {
    const processClasses = useProcessClasses();
    const route = useRoute();
    const router = useRouter();
    return {
      processClasses,
      route,
      router,
    };
  },
  mounted() {
    if (this.open) {
      this.selectProcess(this.processes[0]);
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.processTabSkip = 0;
        this.selectProcess(this.processes[0]);
      }
    },
  },
  computed: {
    encodedLabelsToDecodedLabel() {
      return this.processClasses.reduce((acc, processClass) => {
        acc[processClass.encodedLabel] = processClass.decodedLabel;
        return acc;
      }, {} as Record<string, string>);
    },
    visibleTabCount() {
      return 2;
    },
    selectedProcess() {
      return this.processes[this.selectedProcessIndex];
    },
  },
  methods: {
    openProcessValidationPage(selectedProcess: SimplifiedPlannerProcess) {
      const link = {
        name: "ValidationPrdDate",
        params: {
          customer_name: this.route.params.customer_name,
          site_id: this.route.params.site_id,
          date: selectedProcess.date,
        },
        query: {
          camera_id: selectedProcess.camera_id,
          process_id: selectedProcess._id,
        },
      };
      const routeData = this.router.resolve(link);
      window.open(routeData.href, "_blank");
    },

    goTabLeft() {
      if (this.selectedProcessIndex > 0) {
        this.selectedProcessIndex -= 1;
        if (this.selectedProcessIndex < this.processTabSkip) {
          this.processTabSkip -= 1;
        }
      }
    },
    goTabRight() {
      if (this.selectedProcessIndex < this.processes.length - 1) {
        this.selectedProcessIndex += 1;
        if (this.selectedProcessIndex >= this.processTabSkip + this.visibleTabCount) {
          this.processTabSkip += 1;
        }
      }
    },
    selectProcess(process: SimplifiedPlannerProcess) {
      this.selectedProcessIndex = this.processes.indexOf(process);
      this.loadProcessVideoUrl();
    },
    loadProcessVideoUrl() {
      if (!this.selectedProcess) {
        this.videoUrlLoading = false;
        return;
      }
      const process = this.selectedProcess;
      if (this.videoUrls[process._id]) {
        this.videoUrlLoading = false;
        return;
      }
      this.videoUrlLoading = true;
      const { customer_name, site_id } = this.route.params;
      return OpsProcessesRepository.loadProcessVideoUrl(
        customer_name as string,
        site_id as string,
        process._id,
      )
        .then(async ({ url }) => {
          this.videoUrls[process._id] = url;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
          }
        })
        .finally(() => {
          if (this.selectedProcess === process) {
            this.videoUrlLoading = false;
          }
        });
    },
    getProcessLabel(encodedLabel: number) {
      return this.encodedLabelsToDecodedLabel[encodedLabel];
    },
    formatDate(date: Date) {
      return format(date, "dd.MM.yyyy");
    },
    formatTime(time: Date) {
      return format(time, "HH:mm");
    },
    getFormattedDuration(startTime: Date, endTime: Date) {
      const duration = intervalToDuration({
        start: startTime,
        end: endTime,
      });
      const durationFormattedHours = (duration.hours || 0).toString().padStart(2, "0");
      const durationFormattedMinutes = (duration.minutes || 0).toString().padStart(2, "0");
      return `${durationFormattedHours}:${durationFormattedMinutes}h`;
    },
  },
});
</script>

<style scoped>
.video-aspect-ratio {
  aspect-ratio: 4 / 3;
}
</style>
