<template>
  <ModalTW :open="true" @onCloseModal="emit('close')" customCls="w-full m-3 xl:w-1/3">
    <template #title>
      <div class="flex items-center gap-2">
        <div class="flex items-center">Sectioning PDFs</div>
        <PlusCircleIcon v-if="!isCreating" class="w-6 h-6 cursor-pointer" @click="handleAddClick" />
      </div>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
    </template>
    <template #content>
      <div style="height: 300px" class="flex flex-col text-left">
        <div
          class="flex flex-col gap-1 items-center justify-center flex-1"
          v-if="isLoading || isCreating"
        >
          <LoadingSpinner />
          <div v-if="isCreating">Uploading...</div>
        </div>
        <div
          class="flex flex-col flex-1 overflow-hidden"
          v-else
          @dragenter="isDropping = true"
          @dragleave="isDropping = false"
          @dragover.prevent
          @drop="handleDrop"
        >
          <input
            type="file"
            ref="fileInputRef"
            accept="application/pdf"
            class="hidden"
            multiple
            @change="handleFileChange"
          />
          <div
            v-if="!hasSectioningPdfs && !isDropping"
            class="flex items-center justify-center flex-1 flex-col"
          >
            No sectioning PDFs found
          </div>
          <div
            v-if="hasSectioningPdfs && !isDropping"
            class="overflow-y-auto flex-1 flex flex-col gap-2"
          >
            <div
              v-for="sectioningPdf in sectioningPdfs"
              :key="sectioningPdf._id"
              class="flex gap-3 items-center"
            >
              <a class="truncate flex-1" :href="sectioningPdf.url" target="_blank">
                {{ sectioningPdf.filename }}
              </a>
              <TrashIcon
                class="w-5 h-5"
                :class="{ 'cursor-pointer': !isDeleting, 'text-gray-400': isDeleting }"
                @click="handleDeleteClick(sectioningPdf)"
              />
            </div>
          </div>
          <div
            v-if="isDropping"
            class="flex items-center justify-center flex-1 border border-dashed"
          >
            Drop files...
          </div>
        </div>
      </div>
    </template>
  </ModalTW>
</template>

<script lang="ts" setup>
import { TrashIcon, PlusCircleIcon } from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import { useCustomToast } from "@/composables/toast";
import { SectioningPdf } from "@/types/SectioningPdf";
import {
  useSectioningPdfs,
  useDeleteSectioningPdf,
  useCreateSectioningPdf,
} from "@/views/planner/composables";

const emit = defineEmits<{
  (eventName: "close"): void;
}>();

const isDropping = ref(false);
const fileInputRef = ref<HTMLInputElement | null>(null);

const { sectioningPdfs, isLoading } = useSectioningPdfs();
const { createSectioningPdf, isLoading: isCreating } = useCreateSectioningPdf();
const { deleteSectioningPdf, isLoading: isDeleting } = useDeleteSectioningPdf();

const hasSectioningPdfs = computed(() => sectioningPdfs.value && sectioningPdfs.value.length > 0);

const handleDeleteClick = (sectioningPdf: SectioningPdf) => {
  if (isDeleting.value) {
    return;
  }
  if (window.confirm(`Are you sure to delete "${sectioningPdf.filename}"?`)) {
    deleteSectioningPdf(sectioningPdf._id);
  }
};

const addFiles = (files: FileList) => {
  const hasInvalidFiles = [...files].some(
    (file) => file.type !== "application/pdf" || !file.name.toLowerCase().endsWith(".pdf"),
  );
  if (hasInvalidFiles) {
    useCustomToast().error("Only PDF files are allowed");
    return;
  }
  for (const file of files) {
    createSectioningPdf(file);
  }
};

const handleAddClick = () => {
  fileInputRef.value?.click();
};

const handleDrop = (event: DragEvent) => {
  isDropping.value = false;
  event.preventDefault();
  if (event.dataTransfer?.files) {
    addFiles(event.dataTransfer.files);
  }
};

const handleFileChange = (event: Event) => {
  const inputElement = event.target as HTMLInputElement;
  if (inputElement.files) {
    addFiles(inputElement.files);
  }
};
</script>
