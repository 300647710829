import { ProjectFeature } from "@/types/Project";

export const projectFeatureLabels: Record<ProjectFeature, string> = {
  site_activity: "Bauaktivität (Laufwege)",
  camera: "Kamera",
  planner: "Terminplan",
  processes: "Prozessdaten",
  daily_report: "Bautagesbericht",
  working_hours: "Arbeitsstunden",
};

export const vivotek5mpPreset = {
  resolution_width: 2560,
  resolution_height: 1920,
  fps: 1,
};

export const cameraPresets = {
  vivotek_5mp: vivotek5mpPreset,
};
