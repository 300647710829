import { Project } from "@/types/Project";
import { UnitValueAggregate, UnitValueAggregateByProject, UnitValueType } from "@/types/UnitValue";
import { allTypeGroups } from "@/views/unit_values/types";

export const precision = 2;

const getProjectSortScore = (project: Project, legacy: boolean) => {
  if (legacy) {
    return 0;
  }
  return project.status === "active" ? 2 : 1;
};

export const calculateProjects = (
  unitValueAggregatesByProject: UnitValueAggregateByProject[],
  allProjects: Project[],
): (Project & { legacy: boolean })[] => {
  const customerNameSiteIds = new Set(
    unitValueAggregatesByProject.map(
      (unitValueAggregateByProject) =>
        `${unitValueAggregateByProject.customer_name}_${unitValueAggregateByProject.site_id}`,
    ),
  );
  const legacyCustomerNameSiteIds = new Set(
    unitValueAggregatesByProject
      .filter((unitValueAggregateByProject) => unitValueAggregateByProject.legacy)
      .map(
        (unitValueAggregateByProject) =>
          `${unitValueAggregateByProject.customer_name}_${unitValueAggregateByProject.site_id}`,
      ),
  );
  const matchingProjects = allProjects.filter((project) =>
    customerNameSiteIds.has(`${project.customer_name}_${project.site_id}`),
  );
  const finalProjects = matchingProjects.map((project) => {
    const legacy = legacyCustomerNameSiteIds.has(`${project.customer_name}_${project.site_id}`);
    return {
      ...project,
      legacy,
      sortScore: getProjectSortScore(project, legacy),
    };
  });
  finalProjects.sort((a, b) => b.sortScore - a.sortScore || a.name.localeCompare(b.name));
  return finalProjects;
};

export const calculateTypeGroups = (
  unitValueAggregatesByProject: UnitValueAggregateByProject[],
  { all }: { all: boolean },
) => {
  const nonEmptyTypes = new Set(
    unitValueAggregatesByProject.map((unitValueAggregateByType) => unitValueAggregateByType.type),
  );
  return allTypeGroups
    .map((group) => ({
      ...group,
      items: group.items.filter((item) => all || nonEmptyTypes.has(item.key)),
    }))
    .filter((group) => group.items.length > 0);
};

export const calculateTypes = (
  unitValueAggregatesByProject: UnitValueAggregateByProject[],
  { all }: { all: boolean },
) => {
  const typeGroups = calculateTypeGroups(unitValueAggregatesByProject, { all });
  return typeGroups.flatMap((group) =>
    group.items.map((type) => ({
      ...type,
      lastInGroup: type.key === group.items[group.items.length - 1].key,
    })),
  );
};

export const calculateUnitValuesByProjectAndType = (
  unitValueAggregatesByProject: UnitValueAggregateByProject[],
) =>
  unitValueAggregatesByProject.reduce((acc, unitValueAggregatesByProject) => {
    const { customer_name, site_id, type } = unitValueAggregatesByProject;
    acc[`${customer_name}_${site_id}_${type}`] = unitValueAggregatesByProject;
    return acc;
  }, {} as Record<string, UnitValueAggregateByProject>);

export const calculateUnitValueAggregatesByType = (unitValueAggregates: UnitValueAggregate[]) =>
  unitValueAggregates.reduce((acc, unitValueAggregate) => {
    acc[unitValueAggregate.type] = unitValueAggregate;
    return acc;
  }, {} as Record<UnitValueType, UnitValueAggregate>);

export const formatNumber = (value: number | null) => {
  if (value === null || !Number.isFinite(value)) {
    return "";
  }
  return value.toLocaleString(window.navigator.language, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};
