import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = {
  key: 1,
  class: "font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.name)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          (_ctx.name.length > 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(`${_ctx.name.slice(0, -1).join(" / ")} / `), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.name[_ctx.name.length - 1]), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.name)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, "<Removed>"))
      : _createCommentVNode("", true)
  ], 64))
}