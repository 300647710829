<template>
  <span v-if="name">
    <span v-if="name.length > 1">
      {{ `${name.slice(0, -1).join(" / ")} / ` }}
    </span>
    <span class="font-bold">
      {{ name[name.length - 1] }}
    </span>
  </span>
  <span class="font-bold" v-if="!name">&lt;Removed&gt;</span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PlannerItemName",
  props: {
    name: {
      type: Array as PropType<Array<string>>,
    },
  },
});
</script>
