<template>
  <div class="fixed inset-0 flex justify-center items-center flex-col">
    <img class="mx-auto h-12 w-auto mb-6" :src="oculaiLogoSrc" alt="oculai logo" />
    <div class="mb-3">Your session has expired. Please refresh the page.</div>
    <div @click="refreshPage" class="cursor-pointer text-blue-700 hover:underline">
      refresh page
    </div>
  </div>
</template>

<script setup lang="ts">
import oculaiLogoSrc from "@/assets/imgs/logo/logo-multiple-no-padding.svg";

const refreshPage = () => {
  window.location.reload();
};
</script>
