import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useCustomToast } from "@/composables/toast";
import UnitValueRepository from "@/repositories/UnitValueRepository";
import logger from "@/services/logger";
import {
  UnitValue,
  UnitValueAggregate,
  UnitValueAggregateByProject,
  UnitValueAggregateParameters,
  UnitValueType,
} from "@/types/UnitValue";

export const useUnitValueAggregates = () => {
  const { data, isLoading } = useQuery<UnitValueAggregate[]>({
    queryKey: ["unit-value-aggregates"],
    queryFn: () => UnitValueRepository.loadUnitValueAggregates(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load unit value aggregates");
      return false;
    },
  });

  return { unitValueAggregates: data, areUnitValueAggregatesLoading: isLoading };
};

export const useUnitValueAggregatesByProject = () => {
  const { data, isLoading } = useQuery<UnitValueAggregateByProject[]>({
    queryKey: ["unit-value-aggregates-by-project"],
    queryFn: () => UnitValueRepository.loadUnitValueAggregatesByProject(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load unit value aggregates by project");
      return false;
    },
  });

  return { unitValueAggregatesByProject: data, areUnitValueAggregatesByProjectLoading: isLoading };
};

export const useCalculateUnitValueAggregates = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: calculateUnitValueAggregates } = useMutation<
    UnitValueAggregate[],
    Error,
    UnitValueAggregateParameters
  >({
    mutationFn: (parameters) => UnitValueRepository.calculateUnitValueAggregates(parameters),
    onSuccess: (unitValueAggregates) => {
      queryClient.setQueryData(["unit-value-aggregates"], unitValueAggregates);
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to calculate unit value aggregates");
      return false;
    },
  });

  return { calculateUnitValueAggregates };
};

export const useUnitValuesByType = (type: UnitValueType) => {
  const { data, isLoading } = useQuery<UnitValue[]>({
    queryKey: ["unit-values-by-type", type],
    queryFn: () => UnitValueRepository.loadUnitValuesByType(type),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load unit values by type");
      return false;
    },
  });

  return { unitValues: data, areUnitValuesLoading: isLoading };
};
