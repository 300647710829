<template>
  <TransitionRoot as="template" :show="this.$parent.addStreamModalOpen">
    <Dialog as="div" class="relative z-10" @close="this.$parent.addStreamModalOpen = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden bg-white rounded-lg px-2 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-2"
            >
              <div class="p-4">
                <h1 class="text-green-gray-900 text-xl font-extrabold">Stream hinzufügen</h1>
                <form
                  @submit.prevent="addStream"
                  class="divide-y-green-gray-200 mt-6 space-y-8 divide-y"
                >
                  <div v-for="field in fields" :key="field.field_identifier">
                    <div class="max-w-3xl pt-2">
                      <label
                        :for="field.field_identifier"
                        class="text-green-gray-900 block text-sm font-medium"
                        >{{ field.field_name }}</label
                      >
                      <input
                        :type="field.field_type"
                        :name="field.field_identifier"
                        :id="field.field_identifier"
                        :placeholder="field.placeholder"
                        :v-model="field.field_identifier"
                        class="border-green-gray-300 text-green-gray-900 mt-1 block w-full rounded-md shadow-sm focus:border-yellow-700 focus:ring-yellow-700 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div class="flex justify-end pt-4">
                    <button
                      type="submit"
                      class="focus:outline-none ml-3 inline-flex justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-700 focus:ring-offset-2"
                      @click="this.$parent.addStreamModalOpen = false"
                    >
                      Stream hinzufügen
                    </button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import StreamRepository from "@/repositories/StreamRepository";
import logger from "@/services/logger";

export default {
  name: "AddStreamModal",
  data() {
    return {
      fields: [],
    };
  },
  mounted() {
    this.assignAddStreamFields();
  },
  components: {
    DialogPanel,
    Dialog,
    TransitionChild,
    TransitionRoot,
  },
  methods: {
    assignAddStreamFields() {
      this.fields = [
        {
          field_name: "Kameraname",
          field_identifier: "name",
          field_type: "text",
          placeholder: "Beliebiger Anzeigename",
        },
      ];
    },
    addStream(submitEvent) {
      const new_stream = {
        customer_name: this.$parent.project["customer_name"],
        site_id: this.$parent.project["site_id"],
        name: submitEvent.target.elements.name.value,
        rtp: false,
        image_start: "06:00",
        image_end: "20:00",
        image_weekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        archive_duration: 7,
      };

      StreamRepository.createStream(new_stream)
        .then((created_stream) => {
          this.$parent.project["streams"].push(created_stream);
        })
        .catch((error) => {
          logger.error(error);
          alert("Unable to create stream");
        });
    },
  },
};
</script>
