import { AxiosResponse } from "axios";
import { PlannerRepository } from "oai-planner";
import { apiClient } from "@/repositories/clients";
import { SectioningPdf } from "@/types/SectioningPdf";

const loadSectioningPdfs = (customerName: string, siteId: string) =>
  apiClient
    .get<void, AxiosResponse<SectioningPdf[]>>(
      `planner-v2/sectioning-pdfs/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const createSectioningPdf = (customerName: string, siteId: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return apiClient
    .post<void, AxiosResponse<SectioningPdf>>(
      `planner-v2/sectioning-pdfs/${customerName}/${siteId}`,
      formData,
    )
    .then((response) => response.data);
};

const deleteSectioningPdf = (customerName: string, siteId: string, _id: string) =>
  apiClient
    .delete<void, AxiosResponse<void>>(
      `planner-v2/sectioning-pdfs/${customerName}/${siteId}/${_id}`,
    )
    .then((response) => response.data);

export default {
  ...PlannerRepository(apiClient),
  loadSectioningPdfs,
  createSectioningPdf,
  deleteSectioningPdf,
};
