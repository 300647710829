import { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { apiClient } from "@/repositories/clients";
import {
  SectionMask,
  SectionMaskPossibility,
  SectionMaskDateRangeItem,
} from "@/types/HierarchyTag";

const formatUtcDate = (date: Date): string =>
  zonedTimeToUtc(date, "UTC").toISOString().replace("Z", "+00:00");

const parseUtcDate = (dateText: string): Date => utcToZonedTime(parseISO(dateText), "UTC");

const mapSectionMask = (
  mask: SectionMask<string>,
  showOnCanvasAsDefault = false,
): SectionMask<Date> => ({
  ...mask,
  validity_start_local: mask.validity_start_local ? parseUtcDate(mask.validity_start_local) : null,
  validity_end_local: mask.validity_end_local ? parseUtcDate(mask.validity_end_local) : null,
  show_on_canvas: showOnCanvasAsDefault ? true : mask.validity_end_local === null,
});

const loadSectionMasks = (customerName: string, siteId: string): Promise<SectionMask[]> =>
  apiClient
    .get<void, AxiosResponse<SectionMask<string>[]>>(
      `/planner-v2/section-masks/${customerName}/${siteId}`,
    )
    .then((response) => response.data.map((mask) => mapSectionMask(mask)));

const loadValidSectionMasks = (customerName: string, siteId: string): Promise<SectionMask[]> =>
  apiClient
    .get<void, AxiosResponse<SectionMask<string>[]>>(
      `/planner-v2/section-masks/valid/${customerName}/${siteId}`,
    )
    .then((response) =>
      response.data.map((mask) => mapSectionMask(mask, true) as SectionMask<Date>),
    );

const loadMostRecentSectionMasks = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
): Promise<{ [key: string]: SectionMask[] }> =>
  apiClient
    .get<void, AxiosResponse<{ [key: string]: SectionMask<string>[] }>>(
      `/planner-v2/section-masks/${customerName}/${siteId}/${cameraId}/${date}/most-recent`,
    )
    .then((response) =>
      Object.fromEntries(
        Object.entries(response.data).map(([encodedLabel, maskList]) => [
          encodedLabel,
          maskList.map((mask) => mapSectionMask(mask, true) as SectionMask<Date>),
        ]),
      ),
    );

const saveSectionMasks = (
  customerName: string,
  siteId: string,
  updatedMasks: SectionMask[],
): Promise<SectionMask[]> =>
  apiClient
    .post<void, AxiosResponse<SectionMask<string>[]>>(
      `/planner-v2/section-masks/${customerName}/${siteId}`,
      {
        section_masks: updatedMasks.map(
          ({ show_on_canvas, validity_start_local, validity_end_local, ...item }) => ({
            ...item,
            validity_start_local: validity_start_local ? formatUtcDate(validity_start_local) : null,
            validity_end_local: validity_end_local ? formatUtcDate(validity_end_local) : null,
          }),
        ),
      },
    )
    .then((response) => response.data.map((mask) => mapSectionMask(mask)));

const loadSectionMaskPossibilityMap = (
  customerName: string,
  siteId: string,
): Promise<SectionMaskPossibility[]> =>
  apiClient
    .get<void, AxiosResponse<SectionMaskPossibility[]>>(
      `/planner-v2/section-masks/${customerName}/${siteId}/possibilities`,
    )
    .then((response) => response.data);

const loadProcessBasedSectionMaskDateRanges = (
  customerName: string,
  siteId: string,
): Promise<Record<string, { start: Date; end: Date }>> =>
  apiClient
    .get<void, AxiosResponse<SectionMaskDateRangeItem[]>>(
      `/planner-v2/section-masks/${customerName}/${siteId}/possibilities/date-range`,
    )
    .then((response) =>
      response.data.reduce((acc, item) => {
        acc[
          `${String(item.building_id)}#${String(item.level_id)}#${String(
            item.level_split_id,
          )}#${String(item.section_id)}#${item.camera_id}`
        ] = {
          start: parseUtcDate(item.start),
          end: parseUtcDate(item.end),
        };
        return acc;
      }, {} as Record<string, { start: Date; end: Date }>),
    );

export default {
  loadSectionMasks,
  loadValidSectionMasks,
  loadMostRecentSectionMasks,
  saveSectionMasks,
  loadSectionMaskPossibilityMap,
  loadProcessBasedSectionMaskDateRanges,
};
