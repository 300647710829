<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 top-10 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-x-full opacity-0"
        enter-to-class="translate-x-0 opacity-100"
        leave-active-class="transform ease-in duration-300 transition"
        leave-from-class="translate-x-0 opacity-100"
        leave-to-class="translate-x-full opacity-0"
      >
        <div
          v-if="show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="shrink-0">
                <!--                <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />-->
                <ExclamationTriangleIcon
                  v-if="alert.type === 'error'"
                  class="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
                <InformationCircleIcon
                  v-if="alert.type === 'info'"
                  class="h-6 w-6 text-yellow-600"
                  aria-hidden="true"
                />
                <CheckCircleIcon
                  v-if="alert.type === 'success'"
                  class="h-6 w-6 text-green-300"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">{{ alert.title }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ alert.text }}</p>
              </div>
              <div class="ml-4 flex shrink-0">
                <button
                  type="button"
                  @click="$emit('closeAlert')"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { XMarkIcon } from "@heroicons/vue/20/solid";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/24/outline";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomNotifications",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["closeAlert"],
  components: {
    ExclamationTriangleIcon,
    InformationCircleIcon,
    XMarkIcon,
    CheckCircleIcon,
  },
});
</script>
