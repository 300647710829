<template>
  <ModalBasic :open="open" width="w-9/12" maxHeight="95vh" @onCloseModal="closeModal()">
    <template #title>
      <div class="flex items-center gap-8">
        <div class="flex items-center flex-1">
          <div class="inline-flex">Process Location Mapping</div>
          <div class="inline-flex pl-4 h-10 w-10" v-if="!locationCanvas">
            <LoadingSpinner />
          </div>
          <span v-if="noImgFound" class="pl-4 text-red font-normal">No image found.</span>
        </div>
        <div class="flex gap-6 pr-6">
          <label class="flex gap-2 items-center">
            <input
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-yellow-600"
              v-model="isMulti"
            />
            <span>Multi</span>
          </label>
          <button
            type="button"
            class="flex gap-2 justify-center items-center rounded-md bg-gray-400 px-4 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-gray-600"
            @click="handleClearClick"
          >
            Clear
          </button>
        </div>
      </div>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
    </template>
    <template #content-left>
      <canvas
        class="bg-gray-100"
        ref="locationCanvas"
        style="width: 100%; display: block"
        @mousedown="locationCanvas && locationCanvas.onMouseDownBbox($event)"
        @mousemove="locationCanvas && locationCanvas.onMouseMoveBbox($event)"
        @contextmenu="locationCanvas && locationCanvas.onContextMenuBbox($event)"
        @mouseout="
          locationCanvas && locationCanvas.onMouseOutBbox($event);
          calculateLocationMapping();
        "
        @mouseup="
          locationCanvas && locationCanvas.onMouseUpBbox($event, validateBboxSize);
          calculateLocationMapping();
        "
      />
    </template>
    <template #content-right>
      <div class="space-y-6 sm:space-y-5">
        <div class="flex justify-between">
          <div class="font-semibold">
            {{ process.decoded_label }}
          </div>
        </div>
        <div
          class="sm:grid sm:grid-cols-4 sm:items-center sm:gap-2 sm:border-t sm:border-gray-200 sm:pt-4"
          v-if="showBuildingFilter && hasBuildings"
        >
          <label class="block text-sm font-medium text-gray-700 sm:mt-px">Building</label>
          <div class="mt-1 sm:col-span-3 sm:mt-0 flex items-center">
            <SearchList
              :defaultOptions="buildingOptions"
              :selectedValue="selectedBuildingId"
              @updateEvent="handleBuildingChange"
              :nameMap="tagNameMap"
              :editMode="!isSelectDisabled"
            />
            <button
              class="text-gray-700 hover:text-yellow-900 underline pl-2"
              v-if="selectedBuildingId"
              @click="selectedBuildingId = null"
            >
              <XMarkIcon class="h-4 w-4"></XMarkIcon>
            </button>
          </div>
        </div>
        <div
          class="sm:grid sm:grid-cols-4 sm:items-center sm:gap-2 sm:border-t sm:border-gray-200 sm:pt-4"
        >
          <label class="block text-sm font-medium text-gray-700 sm:mt-px">Level</label>
          <div class="mt-1 sm:col-span-3 sm:mt-0 flex items-center">
            <SearchList
              :defaultOptions="levelOptions"
              :selectedValue="selectedLevelId"
              @updateEvent="handleLevelChange"
              tagLabel="masks"
              :tagsFor="levelIdsWithMasks"
              :nameMap="tagNameMap"
              :editMode="!isSelectDisabled"
            />
            <button
              class="text-gray-700 hover:text-yellow-900 underline pl-2"
              v-if="selectedLevelId"
              @click="selectedLevelId = null"
            >
              <XMarkIcon class="h-4 w-4"></XMarkIcon>
            </button>
          </div>
        </div>
      </div>
      <div v-if="isSelectDisabled" class="mt-6 p-1 bg-red-200 text-xs rounded-md w-fit">
        Please select process first to enable level selection.
      </div>
      <div v-if="!validateSelectedLevel()" class="mt-6 p-1 bg-red-200 text-xs rounded-md">
        The applied level is not valid for selected process. Please select another one.
      </div>
      <div
        v-if="hasPermission(['pct_admin']) && getSplitParent(selectedLevelId)"
        class="mt-6 200 text-xs"
      >
        Split:
        {{ getSplitParent(selectedLevelId)?.splits?.find((s) => s.id === selectedLevelId)?.name }}
      </div>
      <div class="pt-6" v-if="mappingOverlaps.length > 0">
        <small class="font-medium">Automatic Mapping (overlap percentage):</small>
        <div v-for="(overlap, idx) in mappingOverlaps" :key="idx" class="mt-0">
          <div class="flex">
            <small
              class="pr-1 text-xs"
              :class="{
                'bg-green-100 rounded-md':
                  overlap.sectionMask._id === getMaxOverlapSectionMaskId() &&
                  !overlapTooSmallWarning,
              }"
              >{{
                `${getDisplayedSectionMaskText(
                  overlap.sectionMask,
                  selectedLevelId === null,
                )} - ${overlap.overlap.toFixed(1)}
              %`
              }}</small
            >
          </div>
        </div>
        <p v-if="overlapTooSmallWarning" class="text-orange-500 text-xs">
          Overlap too small (&#60;5%)
        </p>
        <div class="flex justify-center items-center">
          <button
            type="button"
            class="inline-flex px-3 justify-center rounded-md bg-yellow-500 p-2 text-white shadow-sm hover:bg-yellow-600 mt-4 text-xs"
            v-if="!selectedLevelId && !overlapTooSmallWarning"
            @click="selectedLevelId = getMaxOverlapLevelId()"
          >
            <Square3Stack3DIcon class="h-4 w-4 mr-2" aria-hidden="true" />

            Apply Level Recommendation
          </button>
        </div>
      </div>

      <div
        class="pt-6"
        v-if="hasPermission(['pct_admin']) && mappingOverlaps.length > 0 && selectedLevelId"
      >
        <small class="font-medium">Applied Masks:</small>
        <div v-for="(mask, idx) in filteredSectionMasks" :key="idx" class="mt-0">
          <div class="flex">
            <small class="pr-1 text-xs">{{
              `${getDisplayedSectionMaskText(mask, selectedLevelId === null)} ${
                mask.validity_start_local ? formatDateDisplay(mask.validity_start_local) : "start"
              } - ${mask.validity_end_local ? formatDateDisplay(mask.validity_end_local) : "today"}`
            }}</small>
          </div>
        </div>
      </div>
      <div class="mt-6" v-if="selectedLevelId && filteredSectionMasks.length === 0">
        <small class="font-medium bg-yellow-100 rounded-md"
          >No section masks available yet. Boxes can be drawn and mapped at a later point - the
          oculai will be notified automatically.</small
        >
      </div>
    </template>
  </ModalBasic>
</template>

<script lang="ts">
import { XMarkIcon, Square3Stack3DIcon } from "@heroicons/vue/20/solid";
import area from "@turf/area";
import { polygon } from "@turf/helpers";
import intersect from "@turf/intersect";
import { format, parseISO, parse } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { EncodedLabel } from "oai-planner";
import { defineComponent, PropType } from "vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalBasic from "@/components/modals/ModalBasic.vue";
import SearchList from "@/components/other/SearchList.vue";
import { useCurrentProject } from "@/composables/project";
import { useMostRecentSectionMasks } from "@/composables/sectionMasks";
import CameraRepository from "@/repositories/CameraRepository";
import { Drawing } from "@/services/drawing";
import logger from "@/services/logger";
import { SingleImage } from "@/types/Camera";
import { SectionMask, HierarchyTagStore, HierarchyTagSplit } from "@/types/HierarchyTag";
import { ReviewProcess, LocationMappingOverlap } from "@/types/Validation";

export default defineComponent({
  name: "LocationMapping",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    sectionMasks: {
      type: Object as PropType<SectionMask[]>,
      required: true,
    },
    process: {
      type: Object as PropType<ReviewProcess>,
      required: true,
    },
    tagMap: {
      type: Object as PropType<Record<string, HierarchyTagStore & Partial<HierarchyTagSplit>>>,
      required: true,
    },
    formatTagText: {
      type: Function,
      required: true,
    },
    getDisplayedSectionMaskText: {
      type: Function,
      required: true,
    },
  },
  emits: ["closeModal", "updateProcess"],
  components: {
    ModalBasic,
    SearchList,
    XMarkIcon,
    Square3Stack3DIcon,
    LoadingSpinner,
  },
  setup(props) {
    const mostRecentSectionMasks = useMostRecentSectionMasks(
      props.process.customer_name,
      props.process.site_id,
      props.process.camera_id,
      props.process.date,
    ).sectionMasks;

    const currentProject = useCurrentProject();
    const showBuildingFilter = currentProject?.process_groups?.includes("flc_production_line");

    return { mostRecentSectionMasks, showBuildingFilter };
  },
  data() {
    return {
      selectedLevelId: null as string | null,
      selectedBuildingId: null as string | null,
      mappingOverlaps: [] as Array<LocationMappingOverlap>,
      locationCanvas: null as null | Drawing,
      overlapTooSmallWarning: false as boolean,
      noImgFound: false as boolean,
      filteredSectionMasks: [] as Array<SectionMask>,
      isMulti: false,
    };
  },
  computed: {
    hasBuildings() {
      return this.sectionMasks.some((item) => item.building_id !== null);
    },
    buildingOptions() {
      return [
        ...new Set(
          Object.values(this.tagMap)
            .flat()
            .filter((item) => item.type === "building")
            .map((item) => item._id),
        ),
      ].sort((a, b) => this.tagMap[a].number - this.tagMap[b].number);
    },
    levelOptions() {
      const itemHasEncodedLabel = (item: (typeof this.tagMap)[keyof typeof this.tagMap]) => {
        if (typeof this.process.encoded_label !== "number") {
          return true;
        }

        return item.processes
          ? item.processes.includes(this.process.encoded_label as EncodedLabel)
          : true;
      };

      return [
        ...new Set(
          Object.values(this.tagMap)
            .flat()
            .filter((item) => item.type === "level" && !item.splits && itemHasEncodedLabel(item))
            .map((item) => item._id),
        ),
      ].sort((a, b) => this.tagMap[a].number - this.tagMap[b].number);
    },
    tagNameMap() {
      return Object.values(this.tagMap).reduce(
        (dict: Record<string, string>, item: HierarchyTagStore) => {
          const splitParent = this.getSplitParent(item._id);

          dict[item._id] = splitParent?.name || item.name;
          return dict;
        },
        {},
      );
    },
    levelIdsWithMasks() {
      return [
        ...new Set(
          this.sectionMasks
            .map((item) => item.level_split_id || item.level_id)
            .filter((id) => id !== null),
        ),
      ];
    },
    isSelectDisabled() {
      const tagsHaveSplits = Object.values(this.tagMap).some((item) => item.splits);

      if (tagsHaveSplits && typeof this.process.encoded_label !== "number") {
        return true;
      }
      return false;
    },
  },
  watch: {
    open: {
      handler(value) {
        if (value) {
          const isLevelValid = this.validateSelectedLevel();
          this.initializeCanvas(!isLevelValid);
        } else {
          this.setLocationMapping();
          this.selectedLevelId = null;
          this.mappingOverlaps = [];
          this.overlapTooSmallWarning = false;
          this.locationCanvas = null;
        }
      },
      immediate: true,
    },
    filteredSectionMasks(newValue) {
      if (this.locationCanvas) {
        this.locationCanvas.setSectionMasks(newValue, true);
        this.calculateLocationMapping();
      }
    },
    selectedLevelId() {
      this.setFilteredSectionMasks();
    },
    selectedBuildingId() {
      this.setFilteredSectionMasks();
    },
    isMulti() {
      if (this.locationCanvas && this.locationCanvas.setIsMulti) {
        this.locationCanvas.setIsMulti(this.isMulti);
      }
    },
  },

  methods: {
    getSplitParent(splitId: string | null) {
      return Object.values(this.tagMap).find((item) =>
        item.splits?.find((split) => split.id === splitId),
      );
    },
    setFilteredSectionMasks() {
      if (!this.selectedLevelId) {
        this.filteredSectionMasks = [];
        return;
      }

      const parseUtcDate = (dateText: string): Date => utcToZonedTime(parseISO(dateText), "UTC");
      const processStart =
        this.process.work_intervals.length > 0 && this.process.work_intervals[0].start_time
          ? parseUtcDate(this.process.work_intervals[0].start_time)
          : parse(this.process.date, "yyyy-MM-dd", new Date());

      this.filteredSectionMasks = this.sectionMasks.filter(
        (item) =>
          (!this.selectedBuildingId || item.building_id === this.selectedBuildingId) &&
          [item.level_id, item.level_split_id].includes(this.selectedLevelId) &&
          (!item.validity_start_local || processStart > item.validity_start_local) &&
          (!item.validity_end_local || processStart < item.validity_end_local),
      );
    },

    setBuildingId(sectionMaskId: string) {
      const mask = this.sectionMasks.find((item) => item._id === sectionMaskId);
      if (mask) {
        this.selectedBuildingId = mask.building_id;
      }
    },

    async initializeCanvas(ignoreSelectedLevel = false) {
      const { customer_name, site_id, camera_id, date } = this.process;
      this.noImgFound = false;
      const tag = this.tagMap[this.process.section_mask_mapping.level_id as string];

      const selectedSplit = tag?.splits?.find((s) =>
        s.processes.includes(this.process.encoded_label as EncodedLabel),
      );

      if (selectedSplit) {
        this.selectedLevelId = selectedSplit.id as string;
      } else {
        this.selectedLevelId = ignoreSelectedLevel
          ? null
          : this.process.section_mask_mapping.level_id;
      }

      if (this.showBuildingFilter && this.hasBuildings && this.process.section_mask_mapping.id) {
        this.setBuildingId(this.process.section_mask_mapping.id);
      }

      this.setFilteredSectionMasks();
      this.isMulti = this.process.location.length > 1;

      const img = await this.resolveDisplayedImg(customer_name, site_id, camera_id, date);
      if (img && this.$refs.locationCanvas) {
        const imgElement = await this.getImgMeta(img.url);
        this.locationCanvas = new Drawing(
          this.$refs.locationCanvas as HTMLCanvasElement,
          imgElement,
          this.filteredSectionMasks,
          customer_name as string,
          site_id as string,
          camera_id as string,
          this.tagMap,
          this.isMulti,
        );
        this.locationCanvas.setAllBbox(this.process.location);
        this.locationCanvas.renderAll();
        this.calculateLocationMapping();
      } else {
        this.noImgFound = true;
      }
    },
    async resolveDisplayedImg(
      customer_name: string,
      site_id: string,
      camera_id: string,
      date: string,
    ) {
      let img = null;
      if (
        this.process.start_time !== "" &&
        this.process.end_time !== "" &&
        this.process.start_time < this.process.end_time
      ) {
        const start_hour = new Date(this.process.start_time).getUTCHours();
        const end_hour = new Date(this.process.end_time).getUTCHours();
        const hour = Math.ceil((start_hour + end_hour) / 2);
        img = await this.loadImg(customer_name, site_id, camera_id, date, hour.toString());
      }
      if (!img) {
        img = await this.loadImg(customer_name, site_id, camera_id, date);
      }
      return img;
    },
    async loadImg(
      customer_name: string,
      site_id: string,
      camera_id: string,
      date: string,
      hour?: string,
    ): Promise<SingleImage | null> {
      const img = await CameraRepository.loadSingleImage(
        customer_name,
        site_id,
        camera_id,
        date,
        hour,
        false,
      )
        .then((data) => {
          return data;
        })
        .catch((error) => {
          logger.error(error);
          return null;
        });
      return img;
    },
    async getImgMeta(url: string): Promise<HTMLImageElement> {
      const img = new Image();
      img.src = url;
      await img.decode();
      return img;
    },
    closeModal() {
      if (this.locationCanvas) {
        this.locationCanvas.clearCanvas();
      }
      this.$emit("closeModal");
    },
    calculateLocationMapping() {
      if (!this.locationCanvas || this.locationCanvas.getAllBbox().length === 0) {
        return;
      }

      this.mappingOverlaps = [];
      const bboxes = this.locationCanvas.getAllBbox();

      const mostRecentSectionMasksForProcess =
        this.mostRecentSectionMasks && this.process.encoded_label !== null
          ? this.mostRecentSectionMasks[String(this.process.encoded_label)]
          : [];

      const masks = this.selectedLevelId
        ? this.filteredSectionMasks
        : mostRecentSectionMasksForProcess;
      if (!masks) {
        return;
      }

      if (
        !this.selectedLevelId &&
        this.mostRecentSectionMasks &&
        this.locationCanvas.getSectionMasks().length === 0
      ) {
        this.locationCanvas.setSectionMasks([], true);
        setTimeout(() => {
          if (this.locationCanvas && this.mostRecentSectionMasks) {
            this.locationCanvas.setSectionMasks(mostRecentSectionMasksForProcess, true);
          }
        }, 200);
      }

      masks.forEach((sectionMask) => {
        let highestOverlap = 0;
        sectionMask.mask.forEach((subMask) => {
          const overlaps = bboxes.map((bbox) => this.getMaskOverlap(subMask, bbox));
          const maskOverlap = overlaps.reduce((acc, overlap) => acc + overlap, 0) / overlaps.length;
          highestOverlap = Math.max(isNaN(maskOverlap) ? 0 : maskOverlap, highestOverlap);
        });

        this.mappingOverlaps.push({
          sectionMask: sectionMask,
          overlap: highestOverlap,
        } as LocationMappingOverlap);
      });

      this.overlapTooSmallWarning = !this.validateSectionMaskMapping(this.mappingOverlaps);
    },
    getMaxOverlapLevelId() {
      const mask = this.mappingOverlaps.reduce((max, item) =>
        max.overlap > item.overlap ? max : item,
      ).sectionMask;

      return mask.level_split_id || mask.level_id;
    },
    getMaxOverlapSectionMaskId() {
      return this.mappingOverlaps.reduce((max, item) => (max.overlap > item.overlap ? max : item))
        .sectionMask._id;
    },
    validateSectionMaskMapping(overLaps: LocationMappingOverlap[]) {
      const isValid = overLaps.some((item) => item.overlap >= 5);

      return isValid;
    },
    validateBboxSize(bboxes: [number, number][][]) {
      const isValid = bboxes.every((bbox) => {
        if (bbox.length !== 4 || bbox.some((coordinate) => coordinate.length !== 2)) {
          return false;
        }
        const width = Math.abs(bbox[0][0] - bbox[1][0]);
        const height = Math.abs(bbox[1][1] - bbox[2][1]);

        const isValid = width >= 0.015 && height >= 0.015;

        return isValid;
      });

      if (!isValid) {
        this.showToast("error", "Bounding box too small (<1.5%). It won't be saved");
      }

      return isValid;
    },
    setLocationMapping() {
      const isSectionMaskMappingValid = this.validateSectionMaskMapping(this.mappingOverlaps);
      const isSelectedLevelValid = this.validateSelectedLevel();

      let maxOverlapSectionId = null;

      if (isSectionMaskMappingValid && this.mappingOverlaps.length > 0) {
        maxOverlapSectionId = this.getMaxOverlapSectionMaskId();
      }

      let selectedLevelId = null;

      if (isSelectedLevelValid) {
        const splitParent = this.getSplitParent(this.selectedLevelId);

        if (splitParent) {
          selectedLevelId = splitParent._id;
        } else {
          selectedLevelId = this.selectedLevelId;
        }
      }

      if (this.locationCanvas) {
        this.$emit("updateProcess", {
          maskId: maxOverlapSectionId,
          levelId: selectedLevelId,
          bbox: this.selectedLevelId && this.locationCanvas ? this.locationCanvas.getAllBbox() : [],
        });
      }
    },
    getMaskOverlap(subMask: [number, number][], bbox: [number, number][]): number {
      if (subMask.length === 0 || bbox.length === 0) {
        return 0;
      }
      // Append first coordinate again to comply with format
      subMask = [...subMask, subMask[0]];
      bbox = [...bbox, bbox[0]];

      const polySection = polygon([subMask]);
      const polyRect = polygon([bbox]);

      const intersection = intersect(polyRect, polySection);

      if (intersection) {
        const intersectionArea = area(intersection);
        const bboxArea = area(polygon([bbox]));

        return Math.round((intersectionArea / bboxArea) * 100);
      }
      return 0;
    },
    formatDateDisplay(date: Date) {
      return format(date, "dd.MM.yy (HH:mm)");
    },
    handleClearClick() {
      if (this.locationCanvas) {
        this.locationCanvas.clearAllBbox();
        this.locationCanvas.renderAll();
      }

      this.calculateLocationMapping();
    },
    hasSectionMasksForLevel(levelId: string) {
      return this.filteredSectionMasks.some((item) =>
        [item.level_id, item.level_split_id].includes(levelId),
      );
    },
    validateSelectedLevel() {
      const levelId = this.process.section_mask_mapping.level_id;

      if (!levelId || typeof this.process.encoded_label !== "number") {
        return true;
      }

      const tag = this.tagMap[levelId];

      if (!tag) {
        return true;
      }

      if (tag.processes && !tag.processes.includes(this.process.encoded_label as EncodedLabel)) {
        return false;
      }

      return true;
    },
    handleLevelChange(levelId: string) {
      const tag = this.tagMap[levelId];

      if (
        typeof this.process.encoded_label === "number" &&
        tag &&
        tag.processes &&
        !tag.processes.includes(this.process.encoded_label as EncodedLabel)
      ) {
        this.selectedLevelId = null;
      } else {
        this.selectedLevelId = levelId;
      }
    },
    handleBuildingChange(buildingId: string) {
      const tag = this.tagMap[buildingId];

      if (
        typeof this.process.encoded_label === "number" &&
        tag &&
        tag.processes &&
        !tag.processes.includes(this.process.encoded_label as EncodedLabel)
      ) {
        this.selectedBuildingId = null;
      } else {
        this.selectedBuildingId = buildingId;
      }
    },
  },
});
</script>
