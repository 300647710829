<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="$emit('closeModal')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            >
              <div class="pb-2">Select a date to find calendar week.</div>
              <div class="flex">
                <input
                  type="date"
                  max="9999-12-12T00:00:00.00"
                  id="searchDate"
                  class="form-control max-w-lg block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:max-w-xs sm:text-xs border-gray-300 rounded-md mr-2"
                  v-model="date"
                />
                <button
                  type="button"
                  class="inline-flex items-center rounded-md border border-transparent bg-green-400 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none"
                  @click="searchDate()"
                >
                  Search
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DatePickerModal",
  props: ["open"],
  emits: ["closeModal", "searchDate"],
  data() {
    return {
      date: "" as string,
    };
  },

  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  methods: {
    searchDate() {
      this.$emit("searchDate", this.date);
      this.date = "";
    },
  },
});
</script>
