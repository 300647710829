<template>
  <span :class="cls">{{ formattedDate }}</span>
</template>

<script lang="ts">
import { format, parseISO } from "date-fns";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormattedDate",
  props: {
    dateString: {
      type: String,
      required: false,
    },
    date: {
      type: Date,
      required: false,
    },
    cls: {
      type: String,
      required: false,
    },
  },
  computed: {
    dateObject() {
      if (this.date) {
        return this.date;
      }
      if (this.dateString) {
        return parseISO(this.dateString);
      }
      return null;
    },
    formattedDate() {
      return this.dateObject ? format(this.dateObject, "dd.MM.yyyy HH:mm") : "";
    },
  },
});
</script>
