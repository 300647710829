<template>
  <QualitySamplingDetailView v-if="allowed" />
</template>

<script setup lang="ts">
import { useHasPermission } from "oai-planner";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import QualitySamplingDetailView from "@/views/process_validation/components/QualitySamplingDetailView.vue";

const router = useRouter();
const allowed = computed(() => useHasPermission("pct"));

onMounted(() => {
  if (!allowed.value) {
    router.replace({ name: "QualitySamplingOverviewView" });
  }
});
</script>
