import * as Sentry from "@sentry/vue";
import { App } from "vue";
import router from "@/router";
import { getConfig } from "@/services/config";

const init = (app: App<Element>) => {
  if (process.env.NODE_ENV === "production") {
    const config = getConfig();

    Sentry.init({
      app,
      dsn: config.SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.2,
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

const setUser = (email: string | null) => {
  if (process.env.NODE_ENV === "production") {
    Sentry.setUser(email ? { email } : null);
  }
};

const error = <TError = Error>(errorOrMessage: TError | string | unknown, err?: TError) => {
  if (process.env.NODE_ENV === "production") {
    if (typeof errorOrMessage === "string") {
      Sentry.captureMessage(errorOrMessage, {
        level: "error",
        extra: {
          error: err,
        },
      });
    } else {
      Sentry.captureException(errorOrMessage);
    }
  } else {
    if (err) {
      // eslint-disable-next-line no-console
      console.error(errorOrMessage, err);
    } else {
      // eslint-disable-next-line no-console
      console.error(errorOrMessage);
    }
  }
};

export default { init, setUser, error };
