<template>
  <header
    class="flex flex-none items-center justify-between bg-gray-50 border-b border-gray-200 py-4 px-6 sticky top-0 z-10"
  >
    <div class="flex items-center">
      <div>
        <div class="text-lg font-semibold text-gray-900">Week {{ calendarWeek }}</div>
        <div class="text-xs">{{ `${formatDate(startDate)} - ${formatDate(endDate)} ` }}</div>
      </div>
    </div>
    <div class="flex items-center gap-4">
      <WeekSelector
        :startDate="startDate"
        :endDate="endDate"
        :calendarWeek="calendarWeek"
        @change="$emit('weekChange', $event)"
      />
      <div
        v-if="hasPermission(['pct_tracking_admin_prd_review', 'pct_tracking_admin_plan'])"
        class="hidden md:flex md:items-center"
      >
        <Menu as="div" class="relative">
          <MenuButton
            type="button"
            class="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
          >
            {{ Object.keys(currentUser).length === 0 ? "All projects" : currentUser.email }}
            <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-3 w-48 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none"
            >
              <div class="py-1">
                <MenuItem
                  v-slot="{ active }"
                  v-for="(user, index) in userOptions"
                  :key="index"
                  @click="$emit('updateUser', user)"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >{{ user.email }}</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }" @click="$emit('updateUser', {})">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >All Projects</a
                  >
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      <!-- Status Dropdown-->
      <div class="hidden md:flex md:items-center">
        <Menu as="div" class="relative">
          <MenuButton
            type="button"
            class="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 capitalize"
          >
            {{ projectStatusFilter }}
            <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none"
            >
              <div class="py-1 capitalize">
                <MenuItem
                  v-slot="{ active }"
                  v-for="(statusItem, index) in statusList"
                  :key="index"
                  @click="$emit('updateProjectStatus', statusItem)"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >{{ statusItem }}</a
                  >
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      <!-- Reset Button -->
      <button
        type="button"
        class="pl-2 inline-flex items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none"
        @click="$emit('resetFilters')"
      >
        <ArrowUturnLeftIcon class="h-5 w-5 text-white" aria-hidden="true" />
        <span class="pl-2">Reset</span>
      </button>
      <Menu as="div" class="inline-block text-left leading-none">
        <MenuButton class="flex items-center rounded-full text-gray-500 hover:text-gray-600">
          <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-5 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray ring-opacity-5 focus:outline-none"
          >
            <div class="py-1 divide-y">
              <MenuItem v-slot="{ active }">
                <router-link
                  :to="{
                    name: 'QualitySamplingOverviewView',
                    query: { start: formatDateForUrl(startDate), end: formatDateForUrl(endDate) },
                  }"
                  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'extraMenuItem']"
                  class="flex items-center gap-1"
                >
                  <BeakerIcon class="h-4 w-4" aria-hidden="true" />
                  <span class="pl-1">Quality Sampling</span>
                </router-link>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </header>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { ArrowUturnLeftIcon, BeakerIcon, EllipsisVerticalIcon } from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import { defineComponent, PropType } from "vue";
import { User } from "@/types/User";
import WeekSelector from "@/views/process_validation/components/inputs/WeekSelector.vue";

export default defineComponent({
  name: "OverviewHeader",
  props: {
    currentUser: {
      type: Object as PropType<User>,
      required: true,
    },
    userOptions: {
      type: Object as PropType<User[]>,
      required: true,
    },
    projectStatusFilter: {
      type: String,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    calendarWeek: {
      type: Number,
      required: true,
    },
  },
  emits: ["updateUser", "updateProjectStatus", "resetFilters", "weekChange"],
  data() {
    return {
      statusList: ["active", "completed"] as Array<string>,
    };
  },
  components: {
    EllipsisVerticalIcon,
    WeekSelector,
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ArrowUturnLeftIcon,
    BeakerIcon,
  },
  methods: {
    formatDate(date: Date) {
      return format(date, "dd.MM.yyyy");
    },
    formatDateForUrl(date: Date) {
      return format(date, "yyyy-MM-dd");
    },
  },
});
</script>
