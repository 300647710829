<template>
  <!-- @vue-skip fix https://github.com/vuejs/language-tools/issues/3138 -->
  <MainLayout :activeItem="'Planner'">
    <div class="flex flex-col flex-1">
      <header
        :class="[
          'flex flex-none items-center justify-between  border-b border-gray-200 py-4 px-6 sticky top-0 z-10',
          { 'bg-gray-100': currentMode === 'visitor' },
          { 'bg-yellow-100': currentMode === 'tracker' },
          { 'bg-green-50/20': currentMode === 'revision' },
        ]"
      >
        <div class="text-lg font-semibold text-gray-900">
          {{ planConfig?.plan.name }}
        </div>
        <div class="flex min-h-9 items-center flex-row gap-2">
          <button
            type="button"
            :class="[
              'inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm',
              { 'bg-green-400 hover:bg-green-600': hasChanges, 'bg-gray-200': !hasChanges },
            ]"
            @click="saveTrackerModeChanges"
            v-if="currentMode === 'tracker' && hasChanges"
          >
            <CheckIcon class="h-5 w-5" aria-hidden="true" />
            <span>Save</span>
          </button>
          <button
            type="button"
            :class="[
              'inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm',
              { 'bg-green-400 hover:bg-green-600': hasChanges, 'bg-gray-200': !hasChanges },
            ]"
            @click="createNewRevision"
            v-if="currentMode === 'revision' && hasChanges"
            :disabled="!hasChanges"
          >
            <CheckIcon class="h-5 w-5" aria-hidden="true" />
            <span>Save</span>
          </button>
          <button
            @click="startRevertChanges"
            :class="[
              'inline-flex items-center rounded-md px-3 p-2 text-sm font-medium text-white shadow-sm',
              { 'bg-gray-400 hover:bg-gray-600': hasChanges, 'bg-gray-200': !hasChanges },
            ]"
            v-if="['tracker', 'revision'].includes(currentMode) && hasChanges"
          >
            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <span class="isolate inline-flex rounded-md shadow-sm">
            <button
              @click="zoomOut"
              type="button"
              class="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              <MinusIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              @click="zoomIn"
              type="button"
              class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              <PlusIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </span>
          <span class="isolate inline-flex rounded-md shadow-sm">
            <button
              @click="undoChange"
              v-if="['tracker', 'revision'].includes(currentMode)"
              type="button"
              class="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              <ArrowUturnLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              @click="redoChange"
              v-if="['tracker', 'revision'].includes(currentMode)"
              type="button"
              class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              <ArrowUturnRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </span>
          <Menu as="div">
            <MenuButton
              type="button"
              class="inline-flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 capitalize"
            >
              {{ currentMode }}
              <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>
            <MenuItems
              class="absolute right-50 z-50 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none"
            >
              <MenuItem
                v-slot="{ active }"
                v-for="mode in modes"
                :key="mode"
                @click="changeMode(mode)"
                class="capitalize"
              >
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  >{{ mode }}</a
                >
              </MenuItem>
            </MenuItems>
          </Menu>
          <Menu as="div" class="inline-block text-left leading-none">
            <MenuButton class="flex items-center rounded-full text-gray-500 hover:text-gray-600">
              <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-5 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 divide-y">
                  <MenuItem v-if="currentMode === 'visitor'">
                    <span class="pl-1 text-gray-700 extraMenuItem">&lt;No actions&gt;</span>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="currentMode === 'tracker'">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="openActualsEditor"
                      v-if="currentMode === 'tracker'"
                    >
                      <FunnelIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Actuals Editor</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="currentMode === 'tracker'">
                    <button
                      @click="isActualsChangelogOpen = true"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                    >
                      <QueueListIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Actuals Changelog</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="currentMode === 'revision'">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="isPlanRevisionsOpen = true"
                    >
                      <FunnelIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Revisions</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="currentMode === 'revision' && !hasChanges">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="openImportPlanModal"
                    >
                      <ArrowUpOnSquareIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Import</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="currentMode === 'revision' && !hasChanges">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="exportXlsx"
                    >
                      <ArrowDownTrayIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Export XLSX</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="currentMode === 'revision'">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="repairSchedule"
                    >
                      <DocumentCheckIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Repair Schedule</span>
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="currentMode === 'revision' && !planConfig">
                    <button
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'extraMenuItem',
                      ]"
                      @click="initPlan"
                    >
                      <BuildingLibraryIcon class="h-4 w-4" aria-hidden="true" />
                      <span class="pl-1">Initialize</span>
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </header>
      <div class="relative flex-1">
        <bryntum-scheduler-pro
          ref="schedulerProRef"
          :key="schedulerProKey"
          :resources="initialSchedulerProData?.resources"
          :events="initialSchedulerProData?.events"
          :dependencies="initialSchedulerProData?.dependencies"
          v-bind="schedulerProConfig"
          @beforeEventEdit="beforeEventEditHandler($event)"
          @beforeEventAdd="beforeEventAddHandler($event)"
          @eventClick="handleEventClick"
          @eventDblClick="handleEventDblClick"
          @dataChange="updateHasChanges"
          @gridRowDrop="handleGridRowDrop"
          @wheel="handleWheel"
        />
        <div v-if="loading" class="absolute inset-0 bg-white opacity-80" />
        <div
          v-if="loading"
          class="absolute inset-0 flex items-center justify-center"
          style="z-index: 5"
        >
          <LoadingSection :loading="loading" />
        </div>
      </div>
      <EventEditor
        :open="showEditor"
        :eventRecord="eventRecord"
        :scheduler="getSchedulerPro()"
        @saveEvent="saveEvent"
        @cancelEdit="cancelEdit"
        @deleteEvent="deleteEvent"
        :now="eventNow"
      />
      <ActualsChangelog
        :customerName="route.params.customer_name"
        :siteId="route.params.site_id"
        :plannerItems="planConfig?.planner_items"
        :open="isActualsChangelogOpen"
        @closed="isActualsChangelogOpen = false"
      />
      <PlanRevisions
        :customerName="route.params.customer_name"
        :siteId="route.params.site_id"
        :currentPlanId="planConfig?.plan?.id"
        :open="isPlanRevisionsOpen"
        @closed="isPlanRevisionsOpen = false"
        @planActivationStarted="loading = true"
        @planActivationFinished="handlePlanActivationFinished"
        @planActivationFailed="loading = false"
      />
      <NotificationsTW :show="openAlert" :alert="alert" @closeAlert="openAlert = false" />
      <ActualsEditor
        :open="isActualsEditorOpen"
        :actual-events="actualEventsForActualsEditor"
        :planner-items="plannerItemsForActualsEditor"
        :now="eventNow"
        @confirmed="handleActualsEditorConfirmed"
        @closed="isActualsEditorOpen = false"
      />
      <ImportPlanModal
        :open="isImportPlanModalOpen"
        :originalPlanConfig="importPlanOriginalPlanConfig ?? undefined"
        @confirmed="handleImportPlanModalConfirmed"
        @closed="handleImportPlanModalClosed"
      />
      <ProcessSideBar
        :open="isProcessSideBarOpen"
        @closed="isProcessSideBarOpen = false"
        :processes="processSideBarProcesses"
        :resourceFullName="processSideBarResourceFullName"
      />
    </div>
  </MainLayout>
</template>
<script lang="ts">
import {
  SchedulerPro,
  SchedulerEventModel,
  Model,
  EventModel,
  ResourceModel,
  ViewPreset,
  ViewPresetConfig,
  SchedulerResourceModel,
} from "@bryntum/schedulerpro";
import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-react";
import { BryntumSchedulerPro } from "@bryntum/schedulerpro-vue-3";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ArrowUpOnSquareIcon,
  CheckIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  PlusIcon,
  MinusIcon,
  ChevronDownIcon,
  QueueListIcon,
  EllipsisVerticalIcon,
  FunnelIcon,
  XMarkIcon,
  ArrowDownTrayIcon,
  DocumentCheckIcon,
  BuildingLibraryIcon,
} from "@heroicons/vue/24/outline";
import { set } from "date-fns";
import {
  ActualEvent,
  PlanConfig,
  PlannerItem,
  PlannerMode,
  EventToEdit,
  isModeCompatible,
  isParentResource,
  hasEventForMode,
  resetLag,
  PlanMixins,
  SchedulerProBaseEvent,
  updateAllParentEvents,
  updateParentEventsForDependencies,
  updateParentEventsForResourceAndItsParents,
  SchedulerProMixins,
  MergedPlannerItem,
  SimplifiedPlannerProcess,
  editPlannedEvent,
  useOutages,
  useHolidaysInScheduler,
  useNonWorkingDaysInScheduler,
} from "oai-planner";
import { defineComponent } from "vue";
import { NavigationGuardNext, RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import * as XLSX from "xlsx";
import MainLayout from "@/components/layout/MainLayout.vue";
import LoadingSection from "@/components/loading_state/LoadingSection.vue";
import NotificationsTW from "@/components/other/NotificationsTW.vue";
import { useCurrentProject } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import { useConfirmationModal, useSaveBeforeLeaveConfirmationModal } from "@/composables/toast";
import PlannerRepository from "@/repositories/PlannerRepository";
import ProjectRepository from "@/repositories/ProjectRepository";
import { apiClient } from "@/repositories/clients";
import logger from "@/services/logger";
import { schedulerProConfigMode } from "@/views/planner/SchedulerProConfigMode";
import ActualsChangelog from "@/views/planner/components/ActualsChangelog.vue";
import ActualsEditor from "@/views/planner/components/ActualsEditor.vue";
import EventEditor from "@/views/planner/components/EventEditor.vue";
import PlanRevisions from "@/views/planner/components/PlanRevisions.vue";
import ProcessSideBar from "@/views/planner/components/ProcessSideBar.vue";
import ImportPlanModal from "@/views/planner/components/import/ImportPlanModal.vue";

const now = set(new Date(), { seconds: 0, milliseconds: 0 });

const configs: Record<PlannerMode, Partial<BryntumSchedulerProProps>> = {
  visitor: schedulerProConfigMode("visitor", now),
  tracker: schedulerProConfigMode("tracker", now),
  revision: schedulerProConfigMode("revision", now),
  hierarchy: {},
  process: {},
};

export default defineComponent({
  name: "Planner",
  components: {
    ImportPlanModal,
    ArrowUpOnSquareIcon,
    CheckIcon,
    ChevronDownIcon,
    ArrowUturnLeftIcon,
    ArrowUturnRightIcon,
    PlusIcon,
    MinusIcon,
    ArrowDownTrayIcon,
    DocumentCheckIcon,
    EventEditor,
    MainLayout,
    BryntumSchedulerPro,
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    ActualsChangelog,
    QueueListIcon,
    PlanRevisions,
    NotificationsTW,
    LoadingSection,
    EllipsisVerticalIcon,
    FunnelIcon,
    ActualsEditor,
    XMarkIcon,
    BuildingLibraryIcon,
    ProcessSideBar,
  },
  mixins: [SchedulerProMixins, PlanMixins],
  data: () => ({
    planConfig: null as PlanConfig | null,
    processes: null as SimplifiedPlannerProcess[] | null,
    showEditor: false as boolean,
    eventRecord: {} as EventToEdit,
    isActualsChangelogOpen: false as boolean,
    isPlanRevisionsOpen: false as boolean,
    alert: {
      type: "",
      title: "",
      text: "",
    } as Record<string, string>,
    openAlert: false as boolean,
    loading: false as boolean,
    isActualsEditorOpen: false as boolean,
    plannerItemsForActualsEditor: undefined as PlannerItem[] | undefined,
    actualEventsForActualsEditor: undefined as ActualEvent[] | undefined,
    hasChanges: false as boolean,
    isImporting: false as boolean,
    isImportPlanModalOpen: false as boolean,
    importPlanOriginalPlanConfig: null as PlanConfig | null,
    mergedPlannerItems: [] as MergedPlannerItem[],
    presetIndex: 0 as number,
    isProcessSideBarOpen: false as boolean,
    processSideBarProcesses: [] as SimplifiedPlannerProcess[],
    processSideBarResourceFullName: "" as string,
    clickTimeoutId: "" as unknown as ReturnType<typeof setTimeout>,
    isProjectCompleted: false as boolean,
  }),
  computed: {
    eventNow() {
      return now;
    },
    modes() {
      return [
        "visitor",
        this.hasPermission(["pct_tracking_admin_plan", "pct_tracking_plan"]) && "tracker",
        this.hasPermission(["pct_admin"]) && "revision",
        this.hasPermission(["pct_admin"]) && "hierarchy",
      ].filter((mode) => mode) as PlannerMode[];
    },
    schedulerProConfig() {
      return configs[this.currentMode];
    },
    schedulerProKey() {
      return `${this.currentMode}:${this.planConfig?.plan._id}`;
    },
    initialSchedulerProData() {
      if (!this.planConfig) {
        return {
          resources: null,
          events: null,
          dependencies: null,
        };
      }
      return this.createSchedulerProData(this.planConfig, now);
    },
    currentMode(): PlannerMode {
      return (
        this.modes.find((mode) => mode === (this.route.query.mode as PlannerMode)) || "visitor"
      );
    },
  },
  watch: {
    currentMode: {
      handler() {
        if (this.planConfig) {
          this.planConfig = {
            ...this.createPlanConfigFromSchedulerData(this.getSchedulerPro()),
            plan: this.planConfig.plan,
          } as PlanConfig;
        }
      },
    },
    planConfig(newValue: PlanConfig | null, oldValue: PlanConfig | null) {
      const scrollState = this.getSchedulerPro().storeScroll();
      this.$nextTick(async () => {
        this.setProjectStartEndTimeFlag(this.getSchedulerPro(), this.isProjectCompleted);
        this.refreshHolidaysInScheduler(this.getSchedulerPro());
        this.refreshNonWorkingDaysInScheduler(this.getSchedulerPro());

        if (oldValue) {
          this.restoreView(this.getSchedulerPro(), this.presetIndex, scrollState);
        } else {
          this.resetView(this.getSchedulerPro(), this.schedulerProConfig.viewPreset as string);
          this.updatePresetIndex();
        }
      });
    },
  },
  mounted() {
    this.loading = true;
    Promise.all([this.loadPlanConfig(), this.loadProcesses(), this.loadProjectStatus()]).finally(
      () => {
        setTimeout(() => {
          if (this.getSchedulerPro()) {
            this.scrollToActiveActual(this.getSchedulerPro());
          }
        }, 500);
        this.loading = false;
      },
    );
    this.updatePresetIndex();
  },
  beforeRouteLeave(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    if (!this.hasChanges) {
      next();
    } else {
      this.showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
        if (confirmed) {
          next();
        }
      });
    }
  },
  methods: {
    changeMode(mode: PlannerMode) {
      if (this.currentMode === mode) {
        return;
      }

      if (this.hasChanges) {
        this.showConfirmationModal({
          header: "Unsaved edits",
          message: "Unsaved changes will be lost. Continue anyway?",
          confirmAction: "Leave anyway",
          cancelAction: "Continue editing",
        }).then((confirmed) => {
          if (confirmed) {
            this.confirmModeSwitch(mode);
          }
        });
      } else {
        if (mode === "hierarchy") {
          const { customer_name, site_id } = this.route.params;
          this.router.push(`/plan-hierarchy/${customer_name}/${site_id}`);
        } else {
          this.router.replace({ query: { mode } });
        }
      }
    },
    confirmModeSwitch(mode: string) {
      if (this.isImporting) {
        this.planConfig = this.importPlanOriginalPlanConfig;
        this.resetImportPlan();
      }
      if (mode === "hierarchy") {
        const { customer_name, site_id } = this.route.params;
        this.router.push(`/plan-hierarchy/${customer_name}/${site_id}`);
        this.revertAllChanges(this.getSchedulerPro());
        this.resetHasChanges();
        this.resetImportPlan();
      } else {
        this.resetHasChanges();
        this.router.replace({ query: { mode: this.tempMode } });
      }
    },

    getSchedulerPro(): SchedulerPro {
      const schedulerProRef = this.$refs.schedulerProRef as {
        instance: { value: SchedulerPro };
      };
      return schedulerProRef?.instance?.value;
    },
    loadPlanConfig() {
      const { customer_name, site_id } = this.route.params;
      return PlannerRepository.loadPlanConfig(customer_name as string, site_id as string)
        .then((planConfig) => {
          this.planConfig = planConfig;
          this.resetHasChanges();
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load plan");
          }
        });
    },
    loadProcesses() {
      const { customer_name, site_id } = this.route.params;
      return PlannerRepository.loadProcesses(customer_name as string, site_id as string)
        .then((processes) => {
          this.processes = processes;
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    loadProjectStatus() {
      const { customer_name, site_id } = this.route.params;
      return ProjectRepository.loadProject(customer_name as string, site_id as string)
        .then((project) => {
          this.isProjectCompleted = project.status === "completed";
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    saveTrackerModeChanges() {
      const changes = this.getActualEventChanges(this.getSchedulerPro());
      if (!changes) {
        return;
      }
      this.loading = true;
      const { customer_name, site_id } = this.route.params;
      PlannerRepository.saveActualEventChanges(customer_name as string, site_id as string, changes)
        .then((response) => {
          this.getSchedulerPro().eventStore.applyChangeset({
            modified: response.added_ids.map(({ _id, db_id: dbId }) => ({
              $PhantomId: _id,
              id: dbId,
            })),
          });
          this.getSchedulerPro().eventStore.commit();
          this.resetUndoRedo(this.getSchedulerPro());
          this.resetHasChanges();
          this.setProjectStartEndTimeFlag(this.getSchedulerPro(), this.isProjectCompleted);
        })
        .catch((error) => {
          logger.error(error);
          this.loading = false;
          this.openAlert = true;
          this.alert = {
            type: "error",
            title: "error",
            text: "Something went wrong. Please, try again!",
          };
          setTimeout(() => {
            this.openAlert = false;
            this.alert = {};
          }, 3000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getActualEventsWithUpdatedSourceIds() {
      if (!this.importPlanOriginalPlanConfig || !this.planConfig) {
        return [];
      }
      const originalActualEventsById = this.importPlanOriginalPlanConfig?.actual_events.reduce(
        (acc, originalActualEvent) => {
          acc[originalActualEvent._id] = originalActualEvent;
          return acc;
        },
        {} as Record<string, ActualEvent | undefined>,
      );
      return this.planConfig.actual_events.filter((actualEvent) => {
        const originalActualEvent = originalActualEventsById[actualEvent._id];
        return originalActualEvent && actualEvent.source_id !== originalActualEvent.source_id;
      });
    },
    createNewRevision() {
      const { customer_name, site_id } = this.route.params;
      if (!this.planConfig) {
        return;
      }
      this.loading = true;

      const planConfig = {
        plan: this.planConfig.plan,
        ...this.createPlanConfigFromSchedulerData(this.getSchedulerPro()),
        planner_comments: undefined,
      };

      PlannerRepository.createNewRevision(
        customer_name as string,
        site_id as string,
        planConfig as unknown as PlanConfig,
        this.mergedPlannerItems,
      )
        .then((newPlanConfig) => {
          this.planConfig = newPlanConfig;
          this.getSchedulerPro().resourceStore.commit();
          this.getSchedulerPro().eventStore.commit();
          this.getSchedulerPro().dependencyStore.commit();
          this.resetUndoRedo(this.getSchedulerPro());
          this.resetHasChanges();
          this.resetImportPlan();
        })
        .catch((error) => {
          logger.error(error);
          this.loading = false;
          this.openAlert = true;
          this.alert = {
            type: "error",
            title: "error",
            text: "Something went wrong. Please, try again!",
          };
          setTimeout(() => {
            this.openAlert = false;
            this.alert = {};
          }, 3000);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    noChangeAlert(text: string) {
      this.openAlert = true;
      this.alert = {
        type: "info",
        title: "No changes so far!",
        text: "All good, nothing to" + " " + text,
      };
      setTimeout(() => {
        this.openAlert = false;
        this.alert = {};
      }, 3000);
    },
    undoChange() {
      if (this.getSchedulerPro().project.stm.canUndo) {
        this.getSchedulerPro().project.stm.undo();
      } else {
        this.noChangeAlert("undo");
      }
    },
    redoChange() {
      if (this.getSchedulerPro().project.stm.canRedo) {
        this.getSchedulerPro().project.stm.redo();
      } else {
        this.noChangeAlert("redo");
      }
    },
    zoomIn() {
      this.getSchedulerPro().zoomIn();
      this.updatePresetIndex();
    },
    zoomOut() {
      this.getSchedulerPro().zoomOut();
      this.updatePresetIndex();
    },
    beforeEventAddHandler(event: SchedulerProBaseEvent) {
      const resourceRecord = event.resourceRecords.length > 0 && event.resourceRecords[0];
      if (!resourceRecord) {
        return false;
      }
      if (isParentResource(resourceRecord)) {
        return false;
      }
      if (!resourceRecord.getData("trackingEnabled")) {
        return false;
      }
      const eventForModeExists = hasEventForMode(
        this.currentMode,
        event.eventRecord.resourceId,
        event.source.eventStore,
      );

      if (eventForModeExists) {
        const eventForMode = event.source.eventStore
          .getEventsForResource(resourceRecord.id)
          .find((obj: SchedulerEventModel) => isModeCompatible(obj, this.currentMode));

        if (eventForMode) {
          event.source.editEvent(eventForMode);
        }
        return false;
      }
      this.initializeAddEventModal(event.eventRecord, resourceRecord);
      return false;
    },
    getEndDateForAddedEvent(eventRecord: Model, resourceRecord: Model, startDate: Date) {
      if (eventRecord.getData("type") === "actual") {
        const allEventsForResource = (resourceRecord as ResourceModel).events;
        const plannedEvent = allEventsForResource.find(
          (event) => event.getData("type") === "planned",
        );
        if (plannedEvent && plannedEvent.isMilestone) {
          return startDate;
        }
      }
      return eventRecord.getData("type") === "actual"
        ? now
        : set(new Date(eventRecord.getData("endDate")), {
            hours: 17,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          });
    },
    initializeAddEventModal(eventRecord: Model, resourceRecord: Model) {
      const startDate = set(new Date(eventRecord.getData("startDate")), {
        hours: 7,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
      const endDate = this.getEndDateForAddedEvent(eventRecord, resourceRecord, startDate);
      this.eventRecord = {
        _id: eventRecord.id,
        resourceId: eventRecord.getData("resourceId"),
        type: eventRecord.getData("type"),
        active: eventRecord.getData("active"),
        isCreating: eventRecord.isCreating,
        startDate,
        endDate,
        name: resourceRecord.getData("name"),
        sourceId: resourceRecord.getData("sourceId"),
      } as EventToEdit;
      this.showEditor = true;
    },
    beforeEventEditHandler(event: SchedulerProBaseEvent) {
      if (!isModeCompatible(event.eventRecord, this.currentMode)) {
        return false;
      }
      const resourceOfEvent = event.source.project.resourceStore.getById(
        event.eventRecord.getData("resourceId"),
      );
      if (isParentResource(resourceOfEvent)) {
        return false;
      }

      this.eventRecord = {
        _id: event.eventRecord.id,
        resourceId: event.eventRecord.getData("resourceId"),
        type: event.eventRecord.getData("type"),
        active: event.eventRecord.getData("active"),
        isCreating: event.eventRecord.isCreating,
        startDate: event.eventRecord.startDate,
        endDate: event.eventRecord.endDate,
        name: event.eventRecord.resource.name,
        sourceId: resourceOfEvent.getData("sourceId"),
      } as EventToEdit;

      this.showEditor = true;
      return false;
    },
    async saveEvent(startDate: Date, endDate: Date, isActive: boolean) {
      resetLag(this.getSchedulerPro(), this.eventRecord._id);
      if (this.eventRecord.isCreating) {
        const eventToAdd = {
          resourceId: this.eventRecord.resourceId,
          startDate: startDate,
          endDate: endDate,
          active: isActive,
        } as EventModel & { active: boolean };
        this.getSchedulerPro().project.eventStore.add(eventToAdd);
      } else {
        const eventToUpdate = this.getSchedulerPro().project.eventStore.getById(
          this.eventRecord._id,
        ) as EventModel;
        if (eventToUpdate.getData("startDate").getTime() !== startDate.getTime()) {
          eventToUpdate.setStartDate(startDate, false);
        }
        eventToUpdate.setEndDate(endDate);
        eventToUpdate.set("active", isActive);
      }
      const resource = this.getSchedulerPro().resourceStore.getById(this.eventRecord.resourceId);
      this.showEditor = false;
      await updateParentEventsForResourceAndItsParents(this.getSchedulerPro(), resource.parent);
      await updateParentEventsForDependencies(this.getSchedulerPro(), resource);
    },
    async deleteEvent() {
      if (this.eventRecord.type === "actual") {
        const processEvents = this.getSchedulerPro()
          .eventStore.getEventsForResource(this.eventRecord.resourceId)
          .filter((event) => event.getData("type")?.startsWith("process_"));
        this.getSchedulerPro().eventStore.remove(processEvents);
      }
      this.getSchedulerPro().eventStore.remove(this.eventRecord._id);
      const resource = this.getSchedulerPro().resourceStore.getById(this.eventRecord.resourceId);
      this.showEditor = false;
      await updateParentEventsForResourceAndItsParents(this.getSchedulerPro(), resource.parent);
      await updateParentEventsForDependencies(this.getSchedulerPro(), resource);
    },
    cancelEdit() {
      this.showEditor = false;
    },
    handlePlanActivationFinished(planConfig: PlanConfig) {
      this.planConfig = planConfig;
      this.loading = false;
      this.resetImportPlan();
      this.resetHasChanges();
    },
    openActualsEditor() {
      const { planner_items: plannerItems, actual_events: actualEvents } =
        this.createPlanConfigFromSchedulerData(this.getSchedulerPro());
      this.plannerItemsForActualsEditor = plannerItems;
      this.actualEventsForActualsEditor = actualEvents;
      this.isActualsEditorOpen = true;
    },
    async handleActualsEditorConfirmed(actualEvents: ActualEvent[]) {
      const actualEventIdsToActualEvents = actualEvents.reduce(
        (acc, actualEvent) => ({
          ...acc,
          [actualEvent._id]: actualEvent,
        }),
        {} as Record<string, ActualEvent>,
      );
      const events = this.getSchedulerPro().eventStore.allRecords.filter(
        (record) => actualEventIdsToActualEvents[record.id],
      ) as EventModel[];
      this.isActualsEditorOpen = false;
      for (const record of events) {
        const actualEvent = actualEventIdsToActualEvents[record.id];
        if (record.getData("startDate").getTime() !== actualEvent.start.getTime()) {
          record.setStartDate(actualEvent.start, false);
        }
        record.set("active", !actualEvent.end);
        if (actualEvent.end) {
          record.setEndDate(actualEvent.end);
        }
        const resource = this.getSchedulerPro().project.resourceStore.getById(
          record.getData("resourceId"),
        );
        await updateParentEventsForResourceAndItsParents(this.getSchedulerPro(), resource.parent);
        await updateParentEventsForDependencies(this.getSchedulerPro(), resource);
      }
    },
    updateHasChanges() {
      this.hasChanges =
        (this.currentMode === "revision" &&
          (!!this.getSchedulerPro()?.resourceStore.changes ||
            !!this.getSchedulerPro()?.eventStore.changes ||
            !!this.getSchedulerPro()?.dependencyStore.changes ||
            this.isImporting)) ||
        (this.currentMode === "tracker" &&
          !!this.getEventStoreChangesWithoutProcesses(this.getSchedulerPro()));
    },
    resetHasChanges() {
      this.hasChanges = false;
    },

    startRevertChanges() {
      this.showConfirmationModal({
        header: "Unsaved edits",
        message: "Unsaved changes will be lost. Continue anyway?",
        confirmAction: "Revert Changes",
        cancelAction: "Continue editing",
      }).then((confirmed) => {
        if (confirmed) {
          this.revertChanges();
        }
      });
    },
    revertChanges() {
      if (this.isImporting) {
        this.planConfig = this.importPlanOriginalPlanConfig;
        this.resetImportPlan();
      } else {
        this.revertAllChanges(this.getSchedulerPro());
      }
      this.resetHasChanges();
    },
    exportXlsx() {
      this.loading = true;
      const { customer_name, site_id } = this.route.params;
      PlannerRepository.loadPlanExportData(customer_name as string, site_id as string)
        .then((data) => {
          const workbook = XLSX.utils.book_new();
          const worksheetPlannerExport = XLSX.utils.json_to_sheet(data);
          XLSX.utils.book_append_sheet(workbook, worksheetPlannerExport, "planner data");
          XLSX.writeFile(workbook, `planner_export_${customer_name}_${site_id}.xlsx`);
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to retrieve export data");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleGridRowDrop(event: {
      context: { parent: Model; oldPositionContext?: { parentId: string }[] };
    }) {
      const parents = [
        event.context.parent,
        ...(event.context.oldPositionContext?.map(({ parentId }) =>
          this.getSchedulerPro().resourceStore.getById(parentId),
        ) || []),
      ];
      for (const parent of parents) {
        await updateParentEventsForResourceAndItsParents(this.getSchedulerPro(), parent);
        await updateParentEventsForDependencies(this.getSchedulerPro(), parent);
      }
      this.hasChanges = true;
    },
    openImportPlanModal() {
      this.importPlanOriginalPlanConfig = this.planConfig;
      this.isImportPlanModalOpen = true;
    },
    handleImportPlanModalConfirmed(importPlan: {
      planConfig: PlanConfig;
      mergedPlannerItems: MergedPlannerItem[];
    }) {
      this.loading = true;
      this.planConfig = importPlan.planConfig;
      this.hasChanges = true;
      this.isImportPlanModalOpen = false;
      this.isImporting = true;
      this.mergedPlannerItems = importPlan.mergedPlannerItems;

      setTimeout(async () => {
        updateAllParentEvents(this.getSchedulerPro())
          .then(() => {
            this.removeOrphanedActualEvents(this.getSchedulerPro());
            this.resetUndoRedo(this.getSchedulerPro());
          })
          .catch((error) => {
            logger.error("Unable to update all parent events", error);
          })
          .finally(() => {
            this.loading = false;
          });
      }, 500);
    },
    handleImportPlanModalClosed() {
      this.resetImportPlan();
    },
    resetImportPlan() {
      this.importPlanOriginalPlanConfig = null;
      this.isImportPlanModalOpen = false;
      this.isImporting = false;
      this.mergedPlannerItems = [];
    },
    async repairSchedule() {
      await updateAllParentEvents(this.getSchedulerPro());
    },
    initPlan() {
      const name = window.prompt("Enter a name for the plan");
      if (!name) {
        return;
      }
      const customer_name = this.route.params.customer_name as string;
      const site_id = this.route.params.site_id as string;
      this.isImporting = true;
      this.importPlanOriginalPlanConfig = this.planConfig;
      this.planConfig = this.getInitialPlanConfig(name, customer_name, site_id);
      this.hasChanges = true;
    },
    updatePresetIndex() {
      const viewPresetName = (this.getSchedulerPro().viewPreset as ViewPreset).id as string;
      this.presetIndex = [...(this.getSchedulerPro().presets as ViewPresetConfig[])].findIndex(
        (preset) => preset.id === viewPresetName,
      );
    },
    handleWheel(e: WheelEvent) {
      if (e.ctrlKey) {
        setTimeout(() => {
          this.updatePresetIndex();
        }, 100);
      }
    },
    handleEventClick(e: SchedulerProBaseEvent) {
      clearTimeout(this.clickTimeoutId);
      this.clickTimeoutId = setTimeout(() => {
        if (e.eventRecord.resource.getData("children")?.length) {
          this.openResource(
            e.eventRecord.resource,
            e.eventRecord.resource.isExpanded(e.eventRecord.resourceStore),
          );
        }
        if (
          (["visitor", "tracker"] as PlannerMode[]).includes(this.currentMode) &&
          this.processes &&
          e.eventRecord.getData("type") === "actual" &&
          ((e.eventRecord.resource.children as Model[]).length === 0 ||
            (e.eventRecord.resource.children as Model[]).every(
              (child) => !child.getData("trackingEnabled"),
            ))
        ) {
          this.toggleProcessEventsForResource(
            this.getSchedulerPro(),
            e.eventRecord.resource,
            this.processes,
          );
          this.toggleOutages(this.getSchedulerPro(), e.eventRecord);
        }
        if (e.eventRecord.getData("type")?.startsWith("process_")) {
          this.processSideBarProcesses = e.eventRecord.getData("processes");
          this.processSideBarResourceFullName = this.getResourceFullName(e.eventRecord.resource);
          this.isProcessSideBarOpen = true;
        }
      }, 200);
    },

    openResource(resource: SchedulerResourceModel, expanded: boolean) {
      this.getSchedulerPro().toggleCollapse(resource, expanded);
      for (const child of resource.children as SchedulerResourceModel[]) {
        if (
          !child.isExpanded(this.getSchedulerPro().resourceStore) &&
          child.getData("children").length
        ) {
          if (child.getData("children").length) this.openResource(child, expanded);
        }
      }
    },

    handleEventDblClick(e: SchedulerProBaseEvent) {
      clearTimeout(this.clickTimeoutId);
      if ((["visitor", "tracker"] as PlannerMode[]).includes(this.currentMode)) {
        editPlannedEvent(
          e.source,
          e.source.project.resourceStore.getById(
            e.eventRecord.getData("resourceId"),
          ) as unknown as string,
        );
      }
    },
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const { streams } = useStreams();
    const isCompleted = useCurrentProject()?.status === "completed";
    const toggleOutages = useOutages(apiClient, streams, isCompleted);
    const refreshHolidaysInScheduler = useHolidaysInScheduler(apiClient);
    const refreshNonWorkingDaysInScheduler = useNonWorkingDaysInScheduler(apiClient);
    const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();
    const showConfirmationModal = useConfirmationModal();
    return {
      router,
      route,
      toggleOutages,
      refreshHolidaysInScheduler,
      refreshNonWorkingDaysInScheduler,
      showSaveBeforeLeaveConfirmationModal,
      showConfirmationModal,
    };
  },
});
</script>

<style>
@import "~@bryntum/schedulerpro/schedulerpro.stockholm.css";
@import "~oai-planner/dist/assets/planner.css";

.extraMenuItem {
  @apply flex w-full items-center px-4 py-2 text-sm !important;
}
</style>
