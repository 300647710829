<template>
  <div class="flex flex-col gap-8">
    <div class="flex flex-col gap-1">
      <div class="text-lg font-semibold">Quality score: {{ formatQualityScore(qualityScore) }}</div>
      <div>Checked: {{ formatPercentage(checkedScore) }}</div>
    </div>
    <div class="flex flex-col gap-1">
      <div
        v-for="typeErrorScore in typeErrorScores"
        :key="typeErrorScore.type"
        :class="{ 'text-gray-300': typeErrorScore.type === 'wrong_bounding_box_size' }"
      >
        {{ typeErrorScore.type }}: {{ formatPercentage(typeErrorScore.errorScore) }} ({{
          typeErrorScore.count
        }})
      </div>
      <div class="font-semibold">
        Total errors: {{ typeErrorScores.reduce((acc, item) => acc + item.count, 0) }}
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <div>Processes: {{ processStats.allProcessCount }}</div>
      <div>Processes for quality score: {{ processStats.qualityScoreProcessesCount }}</div>
      <div>Work intervals: {{ processStats.allWorkIntervalsCount }}</div>
      <div>
        Work intervals for quality score:
        {{ processStats.qualityScoreWorkIntervalsCount }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { OpsReview, OpsReviewedProcessWithErrors, OpsReviewQualityScore } from "@/types/Validation";
import {
  calculateQualityScore,
  calculateTypeErrorScores,
  prepareOpsReviewedProcessesWithErrorsForQualityScore,
} from "@/views/process_validation/services/qualityScore";
import { formatPercentage } from "@/views/process_validation/services/validation";

const props = defineProps<{
  opsReviewedProcessesWithErrors: OpsReviewedProcessWithErrors[];
  opsReviewsForBatch: OpsReview[];
}>();

const qualityScore = computed(() => calculateQualityScore(props.opsReviewedProcessesWithErrors));

const checkedScore = computed(() => {
  const allOpsReviews = props.opsReviewsForBatch || [];
  const checkedOpsReviews = allOpsReviews.filter((process) => process.checked_by);
  return allOpsReviews.length > 0 ? checkedOpsReviews.length / allOpsReviews.length : 0;
});

const processStats = computed(() => {
  const allProcesses = props.opsReviewedProcessesWithErrors || [];
  const qualityScoreProcesses = prepareOpsReviewedProcessesWithErrorsForQualityScore(allProcesses);
  return {
    allProcessCount: allProcesses.length,
    qualityScoreProcessesCount: qualityScoreProcesses.length,
    allWorkIntervalsCount: allProcesses.reduce(
      (acc, process) => acc + process.work_intervals.length,
      0,
    ),
    qualityScoreWorkIntervalsCount: qualityScoreProcesses.reduce(
      (acc, process) => acc + process.work_intervals.length,
      0,
    ),
  };
});

const typeErrorScores = computed(() =>
  calculateTypeErrorScores(props.opsReviewedProcessesWithErrors || []),
);

const formatQualityScore = (qualityScore: OpsReviewQualityScore) => {
  const { score, workIntervalsWithoutErrors, totalWorkIntervals } = qualityScore;
  return `${formatPercentage(score)} (${workIntervalsWithoutErrors}/${totalWorkIntervals})`;
};
</script>
