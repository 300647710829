<template>
  <!-- @vue-skip fix https://github.com/vuejs/language-tools/issues/3138-->
  <div class="p-3 border border-gray-300 rounded whitespace-nowrap">
    <div v-if="!hasSomeChange">No changes detected</div>
    <div v-if="hasSomeChange" class="flex flex-col gap-10">
      <div v-if="changes.updatedPlannedEvents.length > 0" class="flex flex-col gap-1">
        <div class="font-bold flex gap-1 border-b border-b-gray-300">
          <input
            type="checkbox"
            class="cursor-pointer"
            ref="updatedPlannedEventsCheckbox"
            @click="
              setChanges(
                changes.updatedPlannedEvents.map((change) => ({
                  changeId: change._id,
                  selected: $event.currentTarget?.checked,
                })),
              )
            "
          /><span>Updated start/end ({{ changes.updatedPlannedEvents.length }})</span>
        </div>
        <div class="flex flex-col gap-1">
          <div
            v-for="change in changes.updatedPlannedEvents"
            :key="change.from._id"
            class="flex gap-1 cursor-pointer"
            @click="toggleChange(change._id)"
          >
            <input
              type="checkbox"
              :checked="selectedChangeIds.has(change._id)"
              class="cursor-pointer"
            />
            <div>{{ change.plannerItem.name }}:</div>
            <div class="text-sm px-2 py-0.5 text-gray-600 bg-gray-200">
              <FormattedDate :date="change.from.start" />
              <span> - </span>
              <FormattedDate :date="change.from.end" />
            </div>
            ⇨
            <div class="text-sm px-2 py-0.5 text-gray-600 bg-gray-200">
              <FormattedDate :date="change.to.start" />
              <span> - </span>
              <FormattedDate :date="change.to.end" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="changes.updatedPlannerItems.length > 0" class="flex flex-col gap-1">
        <div class="font-bold flex gap-1 border-b border-b-gray-300">
          <input
            type="checkbox"
            class="cursor-pointer"
            ref="updatedPlannerItemsCheckbox"
            @click="
              setChanges(
                changes.updatedPlannerItems.map((change) => ({
                  changeId: change._id,
                  selected: $event.currentTarget?.checked,
                })),
              )
            "
          /><span>Updated name ({{ changes.updatedPlannerItems.length }})</span>
        </div>
        <div class="flex flex-col gap-1">
          <ImportPlanPlannerItemFromTo
            v-for="change in updatedPlannerItemsAsHierarchy"
            :key="change._id"
            :change="change"
            :selectedChangeIds="selectedChangeIds"
            @setChange="handleSetChange"
          />
        </div>
      </div>
      <div v-if="changes.updatedParentPlannerItems.length > 0" class="flex flex-col gap-1">
        <div class="font-bold flex gap-1 border-b border-b-gray-300">
          <input
            type="checkbox"
            class="cursor-pointer"
            ref="updatedParentPlannerItemsCheckbox"
            @click="
              setChanges(
                changes.updatedParentPlannerItems.map((change) => ({
                  changeId: change._id,
                  selected: $event.currentTarget?.checked,
                })),
              )
            "
          /><span>Updated hierarchy ({{ changes.updatedParentPlannerItems.length }})</span>
        </div>
        <div class="flex flex-col gap-1">
          <div
            v-for="change in changes.updatedParentPlannerItems"
            :key="change.plannerItem._id"
            class="flex gap-1 cursor-pointer"
            @click="toggleChange(change._id)"
          >
            <input
              type="checkbox"
              :checked="selectedChangeIds.has(change._id)"
              class="cursor-pointer"
            />
            <span>{{ change.plannerItem.name }}:</span>
            <span class="text-sm px-2 py-0.5 text-gray-600 bg-gray-200">{{
              change.from?.name || "&lt;root&gt;"
            }}</span>
            ⇨
            <span class="text-sm px-2 py-0.5 text-gray-600 bg-gray-200">{{
              change.to?.name || "&lt;root&gt;"
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="changes.addedPlannerItems.length > 0" class="flex flex-col gap-1">
        <div class="font-bold flex gap-1 border-b border-b-gray-300">
          <input
            type="checkbox"
            class="cursor-pointer"
            ref="addedPlannerItemsCheckbox"
            @click="
              setChanges(
                changes.addedPlannerItems.map((change) => ({
                  changeId: change._id,
                  selected: $event.currentTarget?.checked,
                })),
              )
            "
          />
          <span>Added ({{ changes.addedPlannerItems.length }})</span>
        </div>
        <div class="flex flex-col gap-1">
          <ImportPlanPlannerItemWithMerge
            v-for="change in addedPlannerItemsAsHierarchy"
            :key="change._id"
            :change="change"
            :selectedChangeIds="selectedChangeIds"
            @setChange="handleSetChange"
            :deletedPlannerItems="changes.deletedPlannerItems"
            :merges="merges"
            @setMerge="handleSetMerge"
          />
        </div>
      </div>
      <div v-if="changes.connectedPlannedEvents.length > 0" class="flex flex-col gap-1">
        <div class="font-bold flex gap-1 border-b border-b-gray-300">
          <input
            type="checkbox"
            class="cursor-pointer"
            ref="connectedPlannedEventsCheckbox"
            @click="
              setChanges(
                changes.connectedPlannedEvents.map((change) => ({
                  changeId: change._id,
                  selected: $event.currentTarget?.checked,
                })),
              )
            "
          /><span>Added connections ({{ changes.connectedPlannedEvents.length }})</span>
        </div>
        <div class="flex flex-col gap-1">
          <div
            v-for="change in changes.connectedPlannedEvents"
            :key="change._id"
            class="cursor-pointer"
            @click="toggleChange(change._id)"
          >
            <input
              type="checkbox"
              :checked="selectedChangeIds.has(change._id)"
              class="cursor-pointer"
            />
            {{ change.from.name }} ⟶ {{ change.to.name }}
          </div>
        </div>
      </div>
      <div v-if="changes.disconnectedPlannedEvents.length > 0" class="flex flex-col gap-1">
        <div class="font-bold flex gap-1 border-b border-b-gray-300">
          <input
            type="checkbox"
            class="cursor-pointer"
            ref="disconnectedPlannedEventsCheckbox"
            @click="
              setChanges(
                changes.disconnectedPlannedEvents.map((change) => ({
                  changeId: change._id,
                  selected: $event.currentTarget?.checked,
                })),
              )
            "
          /><span>Removed connections ({{ changes.disconnectedPlannedEvents.length }})</span>
        </div>
        <div class="flex flex-col gap-1">
          <div
            v-for="change in changes.disconnectedPlannedEvents"
            :key="change._id"
            class="cursor-pointer"
            @click="toggleChange(change._id)"
          >
            <input
              type="checkbox"
              :checked="selectedChangeIds.has(change._id)"
              class="cursor-pointer"
            />
            {{ change.from.name }} × {{ change.to.name }}
          </div>
        </div>
      </div>
      <div v-if="getVisibleDeletedPlannerItems(changes).length > 0" class="flex flex-col gap-1">
        <div class="font-bold flex gap-1 border-b border-b-gray-300">
          <input
            type="checkbox"
            class="cursor-pointer"
            ref="deletedPlannerItemsCheckbox"
            @click="
              setChanges(
                getVisibleDeletedPlannerItems(changes).map((change) => ({
                  changeId: change._id,
                  selected: $event.currentTarget?.checked,
                })),
              )
            "
          /><span>Deleted ({{ getVisibleDeletedPlannerItems(changes).length }})</span>
        </div>
        <div class="flex flex-col gap-1">
          <ImportPlanPlannerItem
            v-for="change in deletedPlannerItemsAsHierarchy"
            :key="change._id"
            :change="change"
            :selectedChangeIds="selectedChangeIds"
            @setChange="handleSetChange"
          />
        </div>
      </div>
      <div v-if="changes.updatedPlan" class="flex flex-col gap-1">
        <div class="font-bold border-b border-b-gray-300">Updated plan name</div>
        <div class="flex gap-1 cursor-pointer" @click="toggleChange(changes.updatedPlan._id)">
          <input
            type="checkbox"
            :checked="selectedChangeIds.has(changes.updatedPlan._id)"
            class="cursor-pointer"
          />
          <div>{{ changes.updatedPlan.from.name }}</div>
          ⇨
          <div>{{ changes.updatedPlan.to.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import FormattedDate from "@/components/other/FormattedDate.vue";
import { Changes } from "@/views/planner/components/import/ImportPlanMixins";
import ImportPlanMixins from "@/views/planner/components/import/ImportPlanMixins";
import ImportPlanPlannerItem from "@/views/planner/components/import/ImportPlanPlannerItem.vue";
import ImportPlanPlannerItemFromTo from "@/views/planner/components/import/ImportPlanPlannerItemFromTo.vue";
import ImportPlanPlannerItemWithMerge from "@/views/planner/components/import/ImportPlanPlannerItemWithMerge.vue";

export default defineComponent({
  name: "ImportPlanChanges",
  props: {
    changes: {
      type: Object as PropType<Changes>,
      required: true,
    },
    selectedChangeIds: {
      type: Object as PropType<Set<string>>,
      required: true,
    },
    merges: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
  },
  components: {
    ImportPlanPlannerItemWithMerge,
    FormattedDate,
    ImportPlanPlannerItem,
    ImportPlanPlannerItemFromTo,
  },
  emits: ["setChange", "setChanges", "setMerge"],
  mixins: [ImportPlanMixins],
  mounted() {
    this.update(this.changes, this.selectedChangeIds);
  },
  watch: {
    changes(newChanges: Changes) {
      this.update(newChanges, this.selectedChangeIds);
    },
    selectedChangeIds(newselectedChangeIds: Set<string>) {
      this.update(this.changes, newselectedChangeIds);
    },
  },
  computed: {
    addedPlannerItemsAsHierarchy() {
      return this.getChangesAsHierarchy(this.changes.addedPlannerItems);
    },
    deletedPlannerItemsAsHierarchy() {
      return this.getChangesAsHierarchy(this.getVisibleDeletedPlannerItems(this.changes));
    },
    updatedPlannerItemsAsHierarchy() {
      return this.getChangesAsHierarchy(this.changes.updatedPlannerItems);
    },
    hasSomeChange() {
      return (
        this.changes.updatedPlan ||
        this.changes.updatedPlannedEvents.length > 0 ||
        this.changes.updatedPlannerItems.length > 0 ||
        this.getVisibleDeletedPlannerItems(this.changes).length > 0 ||
        this.changes.addedPlannerItems.length > 0 ||
        this.changes.updatedParentPlannerItems.length > 0 ||
        this.changes.connectedPlannedEvents.length > 0 ||
        this.changes.disconnectedPlannedEvents.length > 0
      );
    },
  },
  methods: {
    isSelected(changeId: string) {
      return this.selectedChangeIds.has(changeId);
    },
    toggleChange(changeId: string) {
      this.$emit("setChange", { changeId, selected: !this.selectedChangeIds.has(changeId) });
    },
    setChange(changeId: string, selected: boolean) {
      this.$emit("setChange", { changeId, selected });
    },
    setChanges(changeIds: { changeId: string; selected: boolean }[]) {
      this.$emit("setChanges", changeIds);
    },
    updateCheckbox<T extends { _id: string }>(
      items: T[],
      name: string,
      selectedChangeIds: Set<string>,
    ) {
      const inputElement = this.$refs[name] as HTMLInputElement;
      if (inputElement) {
        const all = items.every((change) => selectedChangeIds.has(change._id));
        const none = items.every((change) => !selectedChangeIds.has(change._id));
        inputElement.checked = all;
        inputElement.indeterminate = !all && !none;
      }
    },
    update(changes: Changes, selectedChangeIds: Set<string>) {
      this.updateCheckbox(
        changes.updatedPlannedEvents,
        "updatedPlannedEventsCheckbox",
        selectedChangeIds,
      );
      this.updateCheckbox(
        changes.updatedPlannerItems,
        "updatedPlannerItemsCheckbox",
        selectedChangeIds,
      );
      this.updateCheckbox(
        changes.addedPlannerItems,
        "addedPlannerItemsCheckbox",
        selectedChangeIds,
      );
      this.updateCheckbox(
        this.getVisibleDeletedPlannerItems(changes),
        "deletedPlannerItemsCheckbox",
        selectedChangeIds,
      );
      this.updateCheckbox(
        changes.updatedParentPlannerItems,
        "updatedParentPlannerItemsCheckbox",
        selectedChangeIds,
      );
      this.updateCheckbox(
        changes.connectedPlannedEvents,
        "connectedPlannedEventsCheckbox",
        selectedChangeIds,
      );
      this.updateCheckbox(
        changes.disconnectedPlannedEvents,
        "disconnectedPlannedEventsCheckbox",
        selectedChangeIds,
      );
    },
    handleSetChange({ changeId, selected }: { changeId: string; selected: boolean }) {
      this.setChange(changeId, selected);
    },
    handleSetMerge(merge: { changeId: string; to: string }) {
      this.$emit("setMerge", merge);
    },
    getVisibleDeletedPlannerItems(changes: Changes) {
      return changes.deletedPlannerItems.filter((change) => !change.hidden);
    },
  },
});
</script>
