import { getProcessClasses, ProcessClass } from "oai-planner";
import { computed } from "vue";
import { useCurrentProject } from "@/composables/project";

export const useProcessClasses = () => {
  const currentProject = useCurrentProject();

  return computed(() => {
    let processGroups = null;
    if (currentProject) {
      processGroups = currentProject.process_groups;
    }
    return getProcessClasses(processGroups) as ProcessClass[];
  });
};
