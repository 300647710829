<template>
  <div
    ref="divRef"
    :class="{ hideScrollBar: hideScrollBar }"
    @scroll="handleScroll"
    v-bind="otherProps"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { ScrollBarSizeEvent, ScrollContext, ScrollEvent, ScrollPosition } from "@/types/Scroll";

const props = defineProps<{
  scrollContext: ScrollContext;
  hideScrollBar?: boolean | undefined;
  position: ScrollPosition;
}>();

const emit = defineEmits<{
  (eventName: "scrollBarSizeChanged", event: ScrollBarSizeEvent): void;
  (eventName: "scrolled", event: ScrollEvent): void;
}>();

const divRef = ref<HTMLDivElement | null>(null);

const otherProps = computed(() => {
  const { scrollContext, hideScrollBar, position, ...rest } = props;
  return rest;
});

const calculateScrollbarSize = () => {
  if (divRef.value) {
    const width = divRef.value.offsetWidth - divRef.value.clientWidth;
    const height = divRef.value.offsetHeight - divRef.value.clientHeight;
    const event: ScrollBarSizeEvent = { width, height, position: props.position };
    emit("scrollBarSizeChanged", event);
  }
};

const handleResize = () => {
  calculateScrollbarSize();
};

const handleScroll = (event: Event) => {
  const target = event.target as HTMLDivElement;
  const scrolledEvent: ScrollEvent = {
    scrollTop: target.scrollTop,
    scrollLeft: target.scrollLeft,
    position: props.position,
  };
  emit("scrolled", scrolledEvent);
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
  setTimeout(() => {
    calculateScrollbarSize();
  }, 100);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

watch(
  () => props.scrollContext.scrollLeft,
  () => {
    if (divRef.value) {
      if (props.position === "horizontal" || props.position === "both") {
        divRef.value.scrollLeft = props.scrollContext.scrollLeft;
      }
    }
  },
);

watch(
  () => props.scrollContext.scrollTop,
  () => {
    if (divRef.value) {
      if (props.position === "vertical" || props.position === "both") {
        divRef.value.scrollTop = props.scrollContext.scrollTop;
      }
    }
  },
);
</script>

<style scoped>
.hideScrollBar {
  scrollbar-width: none;
  overscroll-behavior: none;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}
</style>
