<template>
  <Combobox
    v-model="selected"
    as="div"
    :nullable="nullable"
    :class="cls"
    @update:model-value="
      $emit('updateEvent', selected);
      query = '';
    "
  >
    <div class="relative">
      <ComboboxInput
        :disabled="!editMode"
        :id="type"
        class="w-full rounded-md border border-gray-300 py-2 pl-2 pr-3 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-1 focus:ring-yellow-500 sm:text-sm"
        :class="[
          bgClass || 'bg-white',
          { '!border-red': isError },
          editMode ? 'editInput' : 'overviewInput',
        ]"
        @change="query = $event.target.value"
        :placeholder="placeholder"
        :displayValue="((s) => (nameMap ? nameMap[s as string] : s))"
        @paste="
          pasteClipboard = $event.clipboardData.getData('text');
          $event.target.blur();
          onPaste();
        "
        v-on:keyup.enter="$emit('updateEvent', selected)"
        v-on:keydown.tab.enter="$emit('updateEvent', selected)"
        v-on:keyup.delete="nullable ? $emit('updateEvent', selected) : {}"
      />

      <ComboboxButton
        v-if="editMode"
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        @click="query = ''"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-5 w-5 text-gray-400"
          aria-hidden="true"
          v-if="clock"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" v-else />
      </ComboboxButton>
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <ComboboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full min-w-max overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-500 ring-opacity-5 focus:outline-none sm:text-sm"
          :class="openMenuUpwards ? ['bottom-full'] : []"
        >
          <ComboboxOption
            v-for="option in filteredData"
            :key="option"
            :value="option"
            as="template"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                'relative cursor-default w-full select-none py-2 pl-3 pr-3 flex align-center justify-between',
                active ? 'bg-orange-600 text-white' : 'text-gray-900',
              ]"
            >
              <span :class="['block', selected && 'font-semibold']">
                {{ nameMap ? nameMap[option] : option }}
                <span
                  class="bg-green-200 text-gray-50 px-2 py-1 ml-1 rounded text-xs"
                  v-if="tagsFor?.includes(option)"
                >
                  {{ tagLabel }}
                </span>
              </span>

              <span
                v-if="selected"
                :class="[
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                  active ? 'text-white' : 'text-orange-600',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </transition>
    </div>
  </Combobox>
  <small v-if="selected === '' && validationError" class="text-red">Empty field</small>
</template>

<script lang="ts">
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SearchList",
  props: [
    "selectedValue",
    "defaultOptions",
    "editMode",
    "clock",
    "validationError",
    "errorMessage",
    "placeholder",
    "nullable",
    "type",
    "tagLabel",
    "tagsFor",
    "bgClass",
    "cls",
    "openMenuUpwards",
    "nameMap",
    "isError",
  ],
  emits: ["updateEvent"],
  data() {
    return {
      query: "",
      options: this.defaultOptions,
      selected: this.selectedValue,
      pasteClipboard: "",
    };
  },
  watch: {
    selectedValue(newValue) {
      this.selected = newValue;
    },
    selected(newValue) {
      if (newValue === "" && this.nullable) {
        this.$emit("updateEvent", newValue);
      }
    },
    query(newValue) {
      if (this.clock === true && newValue.length === 5) {
        this.$emit("updateEvent", newValue);
      }
    },
  },
  components: {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },
  computed: {
    filteredData() {
      if (this.query === "") {
        return this.defaultOptions;
      } else {
        return this.defaultOptions.filter((option: string) => {
          return this.nameMap
            ? this.nameMap[option]?.toLowerCase().includes(this.query.toLowerCase())
            : option.toLowerCase().includes(this.query.toLowerCase());
        });
      }
    },
  },
  methods: {
    onPaste() {
      this.$emit("updateEvent", this.pasteClipboard);
    },
  },
});
</script>
