<template>
  <!-- Tabs -->
  <div class="lg:hidden">
    <label for="selected-tab" class="sr-only">Select a tab</label>
    <select
      id="selected-tab"
      name="selected-tab"
      class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-yellow-500 focus:ring-yellow-500 sm:text-sm"
      v-on:change="changeTabSmall"
    >
      <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
    </select>
  </div>
  <div class="hidden lg:block">
    <div class="border-b border-gray-200 flex justify-between">
      <nav class="-mb-px flex space-x-8">
        <a
          v-for="tab in tabs"
          :href="tab.href"
          :key="tab.name"
          :class="[
            tab.current
              ? 'border-yellow-500 text-yellow-600'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
          ]"
          @click="changeTabLarge(tab.name)"
        >
          {{ tab.name }}
        </a>
      </nav>
      <div class="flex gap-4" v-if="project.customer_name && project.site_id">
        <router-link
          class="flex gap-1 items-center cursor-pointer border-transparent text-gray-500 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          :to="{
            name: 'Planner',
            params: {
              customer_name: project.customer_name,
              site_id: project.site_id,
            },
            query: {
              mode: 'revision',
            },
          }"
        >
          <ArrowTopRightOnSquareIcon class="w-5 h-5" />
          Planner
        </router-link>
        <a
          class="flex gap-1 items-center cursor-pointer border-transparent text-gray-500 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          :href="`https://app.oculai.de/${project.customer_name}/${project.site_id}/dashboard`"
        >
          <ArrowTopRightOnSquareIcon class="w-5 h-5" />
          Dashboard
        </a>
        <a
          class="flex gap-1 items-center cursor-pointer border-transparent text-gray-500 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          :href="`https://app.oculai.de/${project.customer_name}/${project.site_id}/settings`"
        >
          <ArrowTopRightOnSquareIcon class="w-5 h-5" />
          App Settings
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";

export default {
  name: "NavbarConfigComponent",
  props: ["project", "tabs"],
  data() {
    return {};
  },
  components: { ArrowTopRightOnSquareIcon },
  methods: {
    changeTabLarge(name) {
      this.changeTab(name);
    },
    changeTabSmall: function (event) {
      this.changeTab(event.target.value);
    },
    changeTab(tab_name) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i]["name"] === tab_name) {
          // eslint-disable-next-line vue/no-mutating-props
          this.tabs[i]["current"] = true;
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.tabs[i]["current"] = false;
        }
      }
      const currentTab = this.tabs.find((tab) => tab.current);
      this.$router.replace({
        query: {
          ...this.$route.query,
          tab: currentTab.identifier,
        },
      });
    },
  },
};
</script>
