import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center h-screen"
}
const _hoisted_2 = {
  key: 1,
  id: "wrapper",
  class: "h-screen overflow-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!
  const _component_SessionExpired = _resolveComponent("SessionExpired")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.authResolved && !_ctx.$store.state.isSessionExpired)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LoadingSpinner)
        ]))
      : _createCommentVNode("", true),
    (_ctx.authResolved && !_ctx.$store.state.isSessionExpired)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(_component_router_view, {
            key: _ctx.route.path
          }))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ConfirmationModal),
    (_ctx.$store.state.isSessionExpired)
      ? (_openBlock(), _createBlock(_component_SessionExpired, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}