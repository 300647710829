<template>
  <div class="flex gap-1 items-center cursor-pointer">
    <div class="text-gray-400">
      {{ level.sections.filter((section) => section.has_mask).length }}/{{ level.sections.length }}
    </div>
    <template v-if="level.sections.length > 0">
      <CheckIcon
        class="w-5 h-5 text-green-400 shrink-0"
        v-if="levelState === 'all'"
        title="All sections have masks"
      />
      <XMarkIcon
        class="w-5 h-5 text-red-400 shrink-0"
        v-if="levelState === 'none'"
        title="No sections have masks"
      />
      <ExclamationTriangleIcon
        class="w-5 h-5 text-yellow-400 shrink-0"
        v-if="levelState === 'partial'"
        title="Some sections masks are missing"
      />
    </template>
    <ExclamationTriangleIcon class="w-5 h-5 text-yellow-400" v-else title="No sections" />
  </div>
</template>

<script setup lang="ts">
import { CheckIcon, ExclamationTriangleIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { computed, PropType } from "vue";
import { ProjectLevelStatusLevel } from "@/types/Project";

const props = defineProps({
  level: {
    type: Object as PropType<ProjectLevelStatusLevel>,
    required: true,
  },
});

const levelState = computed(() => {
  if (props.level.sections.every((section) => section.has_mask)) {
    return "all";
  }
  if (props.level.sections.every((section) => !section.has_mask)) {
    return "none";
  }
  return "partial";
});
</script>
