import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex gap-2 flex-col lg:flex-row lg:items-center" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "hidden lg:block relative text-xs bg-red-500 right-0 text-white rounded-full px-2 py-1",
  style: {"top":"-20px","right":"20px","padding-left":"7px"}
}
const _hoisted_5 = {
  key: 1,
  class: "absolute bg-green-200 rounded-full top-0 right-0 p-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchList = _resolveComponent("SearchList")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchList, {
      defaultOptions: _ctx.customerNames,
      editMode: true,
      placeholder: "Customer",
      selectedValue: _ctx.filters.customerName || '',
      onUpdateEvent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCustomerName($event))),
      nullable: true
    }, null, 8, ["defaultOptions", "selectedValue"]),
    _createVNode(_component_SearchList, {
      defaultOptions: _ctx.siteIds,
      editMode: true,
      placeholder: "Site",
      selectedValue: _ctx.filters.siteId || '',
      onUpdateEvent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSiteId($event))),
      nullable: true
    }, null, 8, ["defaultOptions", "selectedValue"]),
    _createVNode(_component_SearchList, {
      defaultOptions: _ctx.cameraIds,
      editMode: true,
      placeholder: "Camera",
      selectedValue: _ctx.filters.cameraId || '',
      onUpdateEvent: _cache[2] || (_cache[2] = ($event: any) => (
        _ctx.$emit('changed', {
          ..._ctx.filters,
          cameraId: $event || undefined,
        })
      )),
      nullable: true
    }, null, 8, ["defaultOptions", "selectedValue"]),
    _createVNode(_component_SearchList, {
      defaultOptions: _ctx.decodedProcessLabels,
      editMode: true,
      placeholder: "Process Class",
      selectedValue: _ctx.filters.processClass || '',
      onUpdateEvent: _cache[3] || (_cache[3] = ($event: any) => (
        _ctx.$emit('changed', {
          ..._ctx.filters,
          processClass: $event || undefined,
        })
      )),
      nullable: true
    }, null, 8, ["defaultOptions", "selectedValue"]),
    _createElementVNode("input", {
      type: "date",
      class: "shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2",
      value: _ctx.formatDate(_ctx.filters.startDate),
      onChange: _cache[4] || (_cache[4] = ($event: any) => (
        _ctx.$emit('changed', {
          ..._ctx.filters,
          startDate: _ctx.parseDate(($event.target as HTMLInputElement).value),
        })
      )),
      title: "Start date"
    }, null, 40, _hoisted_2),
    _createElementVNode("input", {
      type: "date",
      class: "shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2",
      value: _ctx.formatDate(_ctx.filters.endDate),
      onChange: _cache[5] || (_cache[5] = ($event: any) => (
        _ctx.$emit('changed', {
          ..._ctx.filters,
          endDate: _ctx.parseDate(($event.target as HTMLInputElement).value),
        })
      )),
      title: "End date"
    }, null, 40, _hoisted_3),
    _createVNode(_component_SearchList, {
      defaultOptions: _ctx.buildings,
      editMode: true,
      placeholder: "Building",
      selectedValue: _ctx.filters.building || '',
      onUpdateEvent: _cache[6] || (_cache[6] = ($event: any) => (
        _ctx.$emit('changed', {
          ..._ctx.filters,
          building: $event || undefined,
        })
      )),
      nullable: true
    }, null, 8, ["defaultOptions", "selectedValue"]),
    _createVNode(_component_SearchList, {
      defaultOptions: _ctx.levels,
      editMode: true,
      placeholder: "Level",
      selectedValue: _ctx.filters.level || '',
      onUpdateEvent: _cache[7] || (_cache[7] = ($event: any) => (
        _ctx.$emit('changed', {
          ..._ctx.filters,
          level: $event || undefined,
        })
      )),
      nullable: true
    }, null, 8, ["defaultOptions", "selectedValue"]),
    _createVNode(_component_SearchList, {
      defaultOptions: _ctx.sections,
      editMode: true,
      placeholder: "Section",
      selectedValue: _ctx.filters.section || '',
      onUpdateEvent: _cache[8] || (_cache[8] = ($event: any) => (
        _ctx.$emit('changed', {
          ..._ctx.filters,
          section: $event || undefined,
        })
      )),
      nullable: true
    }, null, 8, ["defaultOptions", "selectedValue"]),
    _createVNode(_component_SearchList, {
      defaultOptions: ['all', 'conflicting', 'ignored'],
      editMode: true,
      placeholder: "Mapping State",
      selectedValue: _ctx.filters.mappingState || 'all',
      onUpdateEvent: _cache[9] || (_cache[9] = ($event: any) => (
        _ctx.$emit('changed', {
          ..._ctx.filters,
          mappingState: ['conflicting', 'ignored'].includes($event) ? $event : undefined,
        })
      ))
    }, null, 8, ["selectedValue"]),
    (_ctx.$store.state.conflictingProcessesCount > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$store.state.conflictingProcessesCount), 1))
      : _createCommentVNode("", true),
    (_ctx.$store.state.conflictingProcessesCount === 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
          _createVNode(_component_CheckIcon, { class: "w-4 h-4 text-white" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[10] || (_cache[10] = ($event: any) => (
        _ctx.$emit('changed', {
          customerName: undefined,
          siteId: undefined,
          cameraId: undefined,
          processClass: undefined,
          startDate: undefined,
          endDate: undefined,
          building: undefined,
          level: undefined,
          section: undefined,
          mappingState: undefined,
        })
      )),
      class: "lg:ml-3 rounded-md bg-gray-400 px-4 py-0 text-sm text-white hover:bg-gray-600 h-9"
    }, " Reset ")
  ]))
}