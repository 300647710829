import { AxiosResponse } from "axios";
import { apiClient } from "@/repositories/clients";
import { CurrentWeatherResponse } from "@/types/Weather";

const loadByCountryCode = (countryCode: string, zipCode: string): Promise<CurrentWeatherResponse> =>
  apiClient
    .get<void, AxiosResponse<CurrentWeatherResponse>>(
      `/weather/by-country-code/${countryCode}/${zipCode}`,
    )
    .then((response) => response.data);

export default {
  loadByCountryCode,
};
