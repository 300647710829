import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);

export const day = (dateString: string): string => {
  return dayjs(dateString).format("DD.MM.YYYY");
};
export const time = (timeString: string): string => {
  return dayjs(timeString).format("HH:mm");
};

export const dateString = (dateObject: Date): string => {
  return dayjs(dateObject).format("YYYY-MM-DD");
};

export const roundToNearest15 = (date: Date): string => {
  const minutes = 15;
  const ms = 1000 * 60 * minutes;

  const roundedDate = new Date(Math.round(date.getTime() / ms) * ms);
  return dayjs(roundedDate).format("YYYY-MM-DD HH:mm:ss");
};

export const today = (): Dayjs => {
  return dayjs();
};

export const daysBetweenToday = (dateString: string): number => {
  const date = dayjs(dateString);
  const today = dayjs();

  return today.diff(date, "day");
};

export const calendarDay = (date: string): number => {
  const dateNo = dayjs(date).date();
  return dateNo;
};

export const timeInputs = [
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
];
