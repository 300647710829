<template>
  <div class="flex overflow-hidden border-l border-transparent">
    <div class="flex flex-col" :style="`width: ${tableSizes.projectColumnsWidth}px`">
      <div
        class="unitValuesProjectColumns shrink-0"
        :style="`height: ${tableSizes.rowHeight}px;`"
        v-for="(row, index) in aggregateRows"
        :key="row.field"
      >
        <div class="border-r border-gray-600" />
        <div
          class="truncate p-1 border-r border-gray-600 border-b text-center bg-white"
          :class="{ 'border-t border-gray-600': index === 0 }"
          :style="`${index === aggregateRows.length - 1 ? '' : 'border-bottom-color: inherit'}`"
        >
          {{ row.label }}
        </div>
      </div>
    </div>
    <div
      class="flex flex-col"
      :style="`max-width: calc(100% - ${
        tableSizes.projectColumnsWidth + scrollContext.scrollBarContainerWidth
      }px)`"
    >
      <ScrollDiv
        position="horizontal"
        :scrollContext="scrollContext"
        :hideScrollBar="true"
        class="overflow-auto overscroll-none flex border-r border-gray-600 bg-white"
        @scrolled="emit('scrolled', $event)"
      >
        <div
          v-for="(type, typeIndex) in types"
          :key="type.key"
          :style="`width: ${tableSizes.valueColumnWidth}px;`"
          class="flex flex-col shrink-0"
        >
          <div
            v-for="(row, rowIndex) in aggregateRows"
            :key="row.field"
            class="truncate text-center shrink-0 p-1 border-b border-gray-600"
            :class="{
              'border-t': rowIndex === 0,
              'border-r': typeIndex !== types.length - 1,
            }"
            :style="`height: ${tableSizes.rowHeight}px; border-right-color: inherit; ${
              rowIndex !== aggregateRows.length - 1 ? 'border-bottom-color: inherit' : ''
            }`"
            :title="
              formatNumberOrRaw(getUnitValueAggregateValueByTypeAndField(type.key, row.field), {
                raw: row.raw,
                hideWhenZero: row.hideWhenZero,
              })
            "
          >
            {{
              formatNumberOrRaw(getUnitValueAggregateValueByTypeAndField(type.key, row.field), {
                raw: row.raw,
                hideWhenZero: row.hideWhenZero,
              })
            }}
          </div>
        </div>
      </ScrollDiv>
      <ScrollDiv
        position="horizontal"
        :style="`min-height: ${scrollContext.scrollBarContainerHeight}px; transform: translateY(-${
          scrollContext.scrollBarContainerHeight - scrollContext.scrollBarHeight
        }px)`"
        :scrollContext="scrollContext"
        class="flex shrink-0 overflow-auto"
        @scrolled="emit('scrolled', $event)"
      >
        <div :style="`width: ${types.length * tableSizes.valueColumnWidth}px;`" class="shrink-0" />
      </ScrollDiv>
      <div
        class="self-end text-xs cursor-pointer flex gap-4"
        :style="`transform: translateY(-${
          scrollContext.scrollBarContainerHeight - scrollContext.scrollBarHeight
        }px)`"
        @click="isParameterModalOpen = true"
        v-if="
          typeof confidenceAlpha === 'number' &&
          typeof tolerancePercentile === 'number' &&
          typeof toleranceAlpha === 'number'
        "
      >
        <span>confidence alpha: {{ formatNumber(confidenceAlpha) }}</span>
        <span>tolerance percentile: {{ formatNumber(tolerancePercentile) }}</span>
        <span>tolerance alpha: {{ formatNumber(toleranceAlpha) }}</span>
      </div>
    </div>
  </div>
  <ParameterModal
    v-if="
      isParameterModalOpen &&
      typeof confidenceAlpha === 'number' &&
      typeof tolerancePercentile === 'number' &&
      typeof toleranceAlpha === 'number'
    "
    :confidenceAlpha="confidenceAlpha"
    :tolerancePercentile="tolerancePercentile"
    :toleranceAlpha="toleranceAlpha"
    @closed="isParameterModalOpen = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ScrollContext, ScrollEvent } from "@/types/Scroll";
import {
  UnitValueAggregate,
  UnitValueAggregateByProject,
  UnitValueTableSizes,
  UnitValueType,
} from "@/types/UnitValue";
import ParameterModal from "@/views/unit_values/components/ParameterModal.vue";
import ScrollDiv from "@/views/unit_values/components/ScrollDiv.vue";
import {
  calculateTypes,
  calculateUnitValueAggregatesByType,
  formatNumber,
} from "@/views/unit_values/unitValues";
import "../style.css";

const props = defineProps<{
  tableSizes: UnitValueTableSizes;
  scrollContext: ScrollContext;
  unitValueAggregates: UnitValueAggregate[];
  unitValueAggregatesByProject: UnitValueAggregateByProject[];
  showAllColumns?: boolean | undefined;
}>();

const emit = defineEmits<{
  (eventName: "scrolled", event: ScrollEvent): void;
}>();

const isParameterModalOpen = ref(false);

type AggregateRow = {
  label: string;
  field: keyof UnitValueAggregate;
  raw?: boolean;
  hideWhenZero?: boolean;
};
const aggregateRows: AggregateRow[] = [
  { label: "Count", field: "count", raw: true, hideWhenZero: true },
  { label: "Average", field: "average" },
  { label: "Median", field: "quantile_50th" },
  { label: "Min", field: "min" },
  { label: "Max", field: "max" },
  { label: "Standard deviation", field: "standard_deviation" },
  { label: "Confidence", field: "confidence" },
  { label: "Confidence lower limit", field: "confidence_lower_limit" },
  { label: "Confidence upper limit", field: "confidence_upper_limit" },
  { label: "Tolerance", field: "tolerance" },
  { label: "Tolerance lower limit", field: "tolerance_lower_limit" },
  { label: "Tolerance upper limit", field: "tolerance_upper_limit" },
];

const confidenceAlpha = computed(() => props.unitValueAggregates[0]?.confidence_alpha);
const tolerancePercentile = computed(() => props.unitValueAggregates[0]?.tolerance_percentile);
const toleranceAlpha = computed(() => props.unitValueAggregates[0]?.tolerance_alpha);

const types = computed(() =>
  calculateTypes(props.unitValueAggregatesByProject, { all: props.showAllColumns }),
);

const unitValueAggregatesByType = computed(() =>
  calculateUnitValueAggregatesByType(props.unitValueAggregates),
);

const getUnitValueAggregateValueByTypeAndField = (
  type: UnitValueType,
  field: keyof UnitValueAggregate,
) => {
  const aggregate = unitValueAggregatesByType.value[type];
  if (aggregate) {
    return aggregate[field] as number | null;
  }
  return null;
};

const formatNumberOrRaw = (
  number: number | null,
  { raw, hideWhenZero }: { raw?: boolean; hideWhenZero?: boolean },
) => {
  if (raw) {
    if (number === null || (number === 0 && hideWhenZero)) {
      return "";
    }
    return number.toString();
  }
  return formatNumber(number);
};
</script>
