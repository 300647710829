<template>
  <MainLayout :activeItem="'ProjectConsole'">
    <div class="flex h-full">
      <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
        <main class="flex flex-1 overflow-hidden">
          <div class="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
            <!-- Secondary sidebar small -->
            <nav
              aria-label="project-dropdown-nav"
              class="border-blue-gray-200 border-b bg-white px-8 py-2 xl:hidden"
            >
              <div class="flex h-16 items-center">
                <label for="selected-tab" class="sr-only">Select a tab</label>
                <select
                  id="selected-tab"
                  name="selected-tab"
                  class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-yellow-500 focus:ring-yellow-500 sm:text-sm"
                  v-on:change="changeProjectSmallTab"
                >
                  <option
                    v-for="(item, index) in projects"
                    :key="item.name"
                    :selected="item.current"
                    :value="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <span class="ml-3 pt-1">
                  <button
                    type="button"
                    class="hover:text-yellow-900focus:ring-2 inline-flex items-center rounded-lg border border-gray-300 bg-white px-2 py-2 text-sm font-bold text-yellow-700 shadow-sm hover:bg-yellow-300 focus:ring-yellow-500 focus:ring-offset-2"
                    @click="openAddProject()"
                  >
                    <PlusIcon class="h-4 w-4" aria-hidden="true" />
                  </button>
                </span>
              </div>
            </nav>

            <div class="flex flex-1 xl:overflow-hidden">
              <!-- Secondary sidebar large -->
              <nav
                aria-label="Sections"
                class="border-blue-gray-200 hidden w-96 shrink-0 border-r bg-white xl:flex xl:flex-col"
              >
                <div class="flex h-16 shrink-0 items-center border-b px-6">
                  <p class="text-blue-gray-900 text-lg font-medium">Projekte</p>
                  <span class="ml-3">
                    <button
                      type="button"
                      class="hover:text-yellow-900focus:ring-2 inline-flex items-center rounded-lg border border-gray-300 bg-white px-2 py-2 text-sm font-bold text-yellow-700 shadow-sm hover:bg-yellow-300 focus:ring-yellow-500 focus:ring-offset-2"
                      @click="openAddProject()"
                    >
                      <PlusIcon class="h-4 w-4" aria-hidden="true" />
                    </button>
                  </span>
                </div>
                <div class="min-h-0 flex-1 overflow-y-auto">
                  <a
                    :id="`project_${item._id}`"
                    v-for="(item, index) in projects"
                    :key="item.name"
                    href="#"
                    :class="[
                      item.current
                        ? 'bg-yellow-200/80'
                        : item.status === 'active'
                        ? 'hover:bg-yellow-200/[0.08]'
                        : 'bg-gray-200 hover:bg-red-200/80',
                      'flex border-b border-gray-200 p-6',
                    ]"
                    :aria-current="item.current ? 'page' : undefined"
                    @click="changeProject(index)"
                  >
                    <component
                      :is="'BuildingOfficeIcon'"
                      class="text-blue-gray-400 -mt-0.5 h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    <div class="ml-3 text-sm">
                      <p class="font-medium text-gray-900">
                        {{ item.customer_name }}_{{ item.site_id }}
                      </p>
                      <p class="text-blue-gray-500 mt-1">{{ item.name }}</p>
                    </div>
                  </a>
                </div>
              </nav>

              <!-- Main content -->

              <div class="flex-1 xl:overflow-y-auto">
                <div class="px-8 py-4">
                  <div v-show="showEditTabs">
                    <div class="pb-4">
                      <NavbarConfigComponent
                        v-bind:project="this.currentProject"
                        v-bind:tabs="this.tabs"
                      ></NavbarConfigComponent>
                    </div>
                    <div v-show="currentTab === 'project'">
                      <EditProjectComponent
                        @reloadmetadata="getProjectMetaData"
                        v-bind:project="this.currentProject"
                      ></EditProjectComponent>
                    </div>
                    <div v-show="currentTab === 'streams'">
                      <EditStreamsComponent
                        v-bind:project="this.currentProject"
                      ></EditStreamsComponent>
                    </div>
                    <div v-if="currentTab === 'analytics' && currentProject.features">
                      <Features
                        :project="currentProject"
                        @update="currentProject.features = $event.features"
                      />
                    </div>
                  </div>
                  <div v-show="showAddProjectComponent">
                    <AddProjectComponent
                      @showsuccesstrigger="showSuccessNotification = $event"
                      @showsuccessmessage="successMesssage = $event"
                      @reloadmetadata="getProjectMetaData"
                    ></AddProjectComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </MainLayout>
  <SuccessNotification
    v-bind:showNotification="showSuccessNotification"
    v-bind:message="successMesssage"
    @closed="showSuccessNotification = false"
  ></SuccessNotification>
</template>

<script>
import { BuildingOfficeIcon, PlusIcon } from "@heroicons/vue/24/solid";
import MainLayout from "@/components/layout/MainLayout.vue";
import ProjectRepository from "@/repositories/ProjectRepository";
import StreamRepository from "@/repositories/StreamRepository";
import logger from "@/services/logger";
import AddProjectComponent from "@/views/project_console/components/AddProjectComponent.vue";
import EditProjectComponent from "@/views/project_console/components/EditProjectComponent.vue";
import EditStreamsComponent from "@/views/project_console/components/EditStreamsComponent.vue";
import Features from "@/views/project_console/components/Features.vue";
import NavbarConfigComponent from "@/views/project_console/components/NavbarConfigComponent.vue";
import SuccessNotification from "@/views/project_console/components/SuccessNotification.vue";

export default {
  name: "ProjectConsole",
  data() {
    return {
      mobileMenuOpen: false,
      currentProject: {},
      projects: [],
      currentProjectIndex: 0,
      tabs: [],
      showProjectTab: true,
      showStreamsTab: false,
      showAnalyticsTab: false,
      showEditTabs: true, // Default edit view
      showAddProjectComponent: false,
      currentTab: "project",
      showSuccessNotification: false,
      successMesssage: "",
    };
  },
  watch: {
    tabs: {
      handler(newValue) {
        for (let i = 0; i < newValue.length; i++) {
          if (newValue[i]["current"]) {
            this.currentTab = newValue[i]["identifier"];
          }
        }
      },
      deep: true,
    },
    "$route.query"() {
      if (this.$route.query.customer_name && this.$route.query.site_id) {
        const currentProjectValue = this.currentProject;
        const currentProjectIndex = this.projects.findIndex(
          (project) =>
            project.customer_name === this.$route.query.customer_name &&
            project.site_id === this.$route.query.site_id,
        );
        if (currentProjectIndex > -1) {
          this.projects[currentProjectIndex].current = true;
          this.currentProjectIndex = currentProjectIndex;
          this.currentProject = this.projects[currentProjectIndex];
          if (currentProjectValue !== this.currentProject) {
            currentProjectValue.current = false;
            this.scrollToCurrentProject();
          }
        }
      }
    },
  },
  mounted() {
    this.getProjectMetaData(this.getInitialProjectSelector());
    this.assignTabs();
    this.scrollToCurrentProject();
  },
  components: {
    Features,
    MainLayout,
    SuccessNotification,
    EditProjectComponent,
    EditStreamsComponent,
    AddProjectComponent,
    NavbarConfigComponent,
    BuildingOfficeIcon,
    PlusIcon,
  },
  methods: {
    async getProjectMetaData(project_selector) {
      this.projects = []; // Reinitialize projects
      this.showEditTabs = true;
      this.showAddProjectComponent = false;

      try {
        const [projects, streams] = await Promise.all([
          ProjectRepository.loadAllProjects(),
          StreamRepository.loadAllStreams(),
        ]);

        for (let i = 0; i < projects.length; i++) {
          const entry = projects[i];

          entry.streams = streams.filter(
            (stream) =>
              stream.customer_name === entry.customer_name && stream.site_id === entry.site_id,
          );
          entry.streams.sort((a, b) => a.camera_id.localeCompare(b.camera_id));
          if (
            (project_selector === "first" && i === 0) ||
            (project_selector.customer_name === entry.customer_name &&
              project_selector.site_id === entry.site_id)
          ) {
            entry["current"] = true;
            this.currentProjectIndex = i;
            this.currentProject = entry;
          } else {
            entry["current"] = false;
          }
          this.projects.push(entry);
        }
        this.scrollToCurrentProject();
      } catch (error) {
        logger.error(error);
      }
    },
    changeProject(idx) {
      // Switch to edit project view if add project is open
      this.showAddProjectComponent = false;
      this.showEditTabs = true;

      // Switch current project
      this.projects[this.currentProjectIndex]["current"] = false;
      this.projects[idx]["current"] = true;
      this.currentProject = this.projects[idx];
      this.currentProjectIndex = idx;
      this.$router.replace({
        query: {
          ...this.$route.query,
          customer_name: this.currentProject.customer_name,
          site_id: this.currentProject.site_id,
        },
      });
    },
    assignTabs() {
      this.tabs = [
        { identifier: "project", name: "Projektdaten", href: "#", current: false },
        { identifier: "streams", name: "Streams", href: "#", current: false },
        { identifier: "analytics", name: "Features", href: "#", current: false },
      ];
      const initialTab = this.$route.query.tab || "project";
      this.tabs.forEach((tab) => {
        if (tab.identifier === initialTab) {
          tab.current = true;
        }
      });
    },
    changeProjectSmallTab: function (event) {
      this.changeProject(event.target.value);
    },
    openAddProject() {
      this.showEditTabs = false;
      this.showAddProjectComponent = true;
    },
    getInitialProjectSelector() {
      if (this.$route.query.customer_name && this.$route.query.site_id) {
        return {
          customer_name: this.$route.query.customer_name,
          site_id: this.$route.query.site_id,
        };
      }
      return "first";
    },
    scrollToCurrentProject() {
      setTimeout(() => {
        const element = document.getElementById(`project_${this.currentProject._id}`);
        element?.scrollIntoView({ block: "center" });
      }, 0);
    },
  },
};
</script>
