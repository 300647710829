<template>
  <ModalTW :open="open" @onCloseModal="$emit('closed')" customCls="w-full m-5 xl:w-2/3">
    <template #title>Actuals Editor</template>
    <template #content>
      <div class="flex flex-col gap-7 text-left">
        <div v-if="editedActualEvents.length === 0">No actuals in progress to edit</div>
        <div v-if="editedActualEvents.length > 0" class="flex flex-col gap-4">
          <div class="flex gap-7 font-bold pr-3">
            <div class="flex-1" />
            <div style="width: 280px">Start</div>
            <div style="width: 350px">End</div>
          </div>
          <div class="flex flex-col overflow-auto pr-3" style="max-height: 500px">
            <div
              v-for="(actualEvent, index) in editedActualEvents!"
              :key="actualEvent._id"
              :class="['flex flex-col p-2 gap-1', { 'bg-gray-50': index % 2 === 0 }]"
            >
              <div :class="['flex gap-7 items-center']">
                <div class="flex-1 text-right">
                  <PlannerItemName :name="actualEvent.name" />
                </div>
                <div style="flex: 0 0 280px">
                  <DateTime
                    :name="actualEvent._id"
                    :value="actualEvent.start"
                    @changed="actualEvent.start = $event"
                    :maxDate="maxDate"
                    :timeDisabled="timeDisabled"
                  />
                </div>
                <div class="flex gap-5 items-center" style="flex: 0 0 350px">
                  <DateTime
                    :name="actualEvent._id"
                    :value="actualEvent.end"
                    @changed="actualEvent.end = $event"
                    :disabled="!actualEvent.isCompleted"
                    :timeDisabled="timeDisabled"
                    :maxDate="maxDate"
                  />
                  <ActualEventCompletedSwitch
                    :value="actualEvent.isCompleted"
                    @changed="toggleComplete(actualEvent, $event)"
                  />
                </div>
              </div>
              <div v-if="getValidationMessage(actualEvent)" class="text-red-500 text-xs text-right">
                {{ getValidationMessage(actualEvent) }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-3 justify-between items-center">
          <div>
            {{
              editedActualEvents.length > 0
                ? `${editedActualEvents.length} ${
                    editedActualEvents.length === 1 ? "event" : "events"
                  }`
                : ""
            }}
          </div>
          <div class="flex gap-2">
            <button
              @click="$emit('closed')"
              type="button"
              class="inline-flex items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              @click="apply"
              v-if="editedActualEvents.length > 0"
              type="button"
              :disabled="!isValid"
              class="inline-flex items-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:bg-red-500"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </template>
  </ModalTW>
</template>

<script lang="ts">
import { set, subDays, isMonday } from "date-fns";
import { PlanMixins, ActualEvent, PlannerItem } from "oai-planner";
import { defineComponent, PropType } from "vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import ActualEventCompletedSwitch from "@/views/planner/components/ActualEventCompletedSwitch.vue";
import DateTime from "@/views/planner/components/DateTime.vue";
import PlannerItemName from "@/views/planner/components/PlannerItemName.vue";

type EditedActualEvent = ActualEvent & {
  isCompleted: boolean;
  name: string[];
  start: Date | null;
};

export default defineComponent({
  name: "ActualsEditor",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    plannerItems: {
      type: Array as PropType<PlannerItem[]>,
      required: false,
    },
    actualEvents: {
      type: Array as PropType<ActualEvent[]>,
      required: false,
    },
    now: {
      type: Date,
      required: true,
    },
  },
  data: () => ({
    editedActualEvents: [] as EditedActualEvent[],
    maxDate: new Date(),
    timeDisabled: true,
  }),
  mounted() {
    this.update(this.actualEvents, this.plannerItems);
  },
  watch: {
    actualEvents(newActualEvents: ActualEvent[] | undefined) {
      this.update(newActualEvents, this.plannerItems);
    },
    plannerItems(newPlannerItems: PlannerItem[] | undefined) {
      this.update(this.actualEvents, newPlannerItems);
    },
  },
  mixins: [PlanMixins],
  emits: ["closed", "confirmed"],
  components: {
    DateTime,
    ModalTW,
    ActualEventCompletedSwitch,
    PlannerItemName,
  },
  computed: {
    isValid() {
      return this.editedActualEvents.every(
        (editedActualEvent) => !this.getValidationMessage(editedActualEvent),
      );
    },
  },
  methods: {
    toggleComplete(editedActualEvent: EditedActualEvent, isCompleted: boolean) {
      editedActualEvent.isCompleted = isCompleted;
      editedActualEvent.end = isCompleted ? this.getDefaultPreviousDayAt5pm() : this.now;
    },

    getDefaultPreviousDayAt5pm() {
      return set(subDays(this.now, isMonday(this.now) ? 2 : 1), {
        hours: 17,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
    },
    update(actualEvents: ActualEvent[] | undefined, plannerItems: PlannerItem[] | undefined) {
      if (!actualEvents || !plannerItems) {
        return;
      }
      const sourceIdsToNames = this.getSourceIdsToNames(plannerItems);
      const inProgressLeafActualEvents = this.getLeafActualEvents(
        plannerItems,
        actualEvents,
      ).filter((actualEvent) => !actualEvent.end);
      const sortedInProgressLeafActualEvents = this.sortEventsByPlannerItems(
        plannerItems,
        inProgressLeafActualEvents,
      );
      this.editedActualEvents = sortedInProgressLeafActualEvents.map((actualEvent) => ({
        ...actualEvent,
        end: actualEvent.end || this.now,
        isCompleted: !!actualEvent.end,
        name: sourceIdsToNames[actualEvent.source_id],
      }));
    },
    apply() {
      const finalActualEvents = this.editedActualEvents?.map((editedActualEvent) => {
        const { isCompleted, name, ...rest } = editedActualEvent;
        return { ...rest, end: isCompleted ? editedActualEvent.end : null } as ActualEvent;
      });
      this.$emit("confirmed", finalActualEvents);
    },
    getValidationMessage(editedActualEvent: EditedActualEvent) {
      return this.getStartEndDateValidationMessageForActualEvent(
        editedActualEvent.start,
        editedActualEvent.end,
        this.now,
        false,
      );
    },
  },
});
</script>
