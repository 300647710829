import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-1" }
const _hoisted_2 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImportPlanPlannerItem = _resolveComponent("ImportPlanPlannerItem", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex gap-1 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleChange(_ctx.change._id)))
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        checked: _ctx.selectedChangeIds.has(_ctx.change._id)
      }, null, 8, _hoisted_2),
      _createElementVNode("div", null, _toDisplayString(_ctx.change.plannerItem.name), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.change.children, (childChange) => {
      return (_openBlock(), _createElementBlock("div", {
        key: childChange._id,
        class: "ml-5"
      }, [
        _createVNode(_component_ImportPlanPlannerItem, {
          selectedChangeIds: _ctx.selectedChangeIds,
          change: childChange,
          onSetChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('setChange', $event)))
        }, null, 8, ["selectedChangeIds", "change"])
      ]))
    }), 128))
  ]))
}