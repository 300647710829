<template>
  <div class="flex overflow-hidden border-l border-gray-600 flex-1">
    <div
      class="flex flex-col border-t border-b border-gray-600 bg-white shrink-0"
      :style="`width: ${tableSizes.projectColumnsWidth}px`"
    >
      <div
        class="unitValuesProjectColumns shrink-0 border-b font-semibold border-gray-600"
        :style="`height: ${headerHeight}px;`"
      >
        <div class="text-center p-1 border-r flex items-center justify-center">Project</div>
        <div class="text-center p-1 border-r border-gray-600 flex items-center justify-center">
          Customer
        </div>
      </div>
      <ScrollDiv
        position="vertical"
        :scrollContext="scrollContext"
        :hideScrollBar="true"
        class="flex flex-col overflow-auto"
        @scrolled="emit('scrolled', $event)"
      >
        <div
          v-for="(project, index) in projects"
          :key="project._id"
          class="unitValuesProjectColumns shrink-0"
          :class="{
            'border-b': index !== projects.length - 1,
            'bg-yellow-300': project.status === 'active' && !project.legacy,
            'bg-gray-100': project.legacy,
          }"
          :style="`height: ${tableSizes.rowHeight}px;`"
        >
          <div class="truncate p-1 border-r" :title="project.name">
            <a
              :href="`https://app.oculai.de/${project.customer_name}/${project.site_id}/unit_values_editor`"
            >
              {{ project.name }}
            </a>
          </div>
          <div
            class="truncate p-1 border-r border-gray-600"
            :title="`${project.customer_name}/${project.site_id}`"
          >
            {{ [project.customer_name, project.site_id].filter((item) => item).join("/") }}
          </div>
        </div>
      </ScrollDiv>
    </div>
    <div
      class="flex flex-col border-t border-b border-r border-gray-600 bg-white"
      :style="`max-width: calc(100% - ${
        tableSizes.projectColumnsWidth + scrollContext.scrollBarContainerWidth
      }px)`"
    >
      <ScrollDiv
        position="horizontal"
        :scrollContext="scrollContext"
        :hideScrollBar="true"
        class="flex shrink-0 overflow-auto"
        @scrolled="emit('scrolled', $event)"
      >
        <div class="flex">
          <div v-for="(group, groupIndex) in typeGroups" :key="group.key" class="flex">
            <div class="flex flex-col">
              <div
                :style="`height: ${tableSizes.rowHeight}px`"
                class="text-center border-b border-gray-600 text-xs font-semibold flex items-center justify-center"
                :class="{ 'border-r': groupIndex !== typeGroups.length - 1 }"
              >
                {{ group.label }}
              </div>
              <div class="flex">
                <div
                  v-for="(type, typeIndex) in group.items"
                  :key="type.key"
                  class="p-1 border-b font-semibold border-gray-600 flex items-center justify-center text-xs text-center"
                  :class="{
                    'border-r':
                      typeIndex !== group.items.length - 1 || groupIndex !== typeGroups.length - 1,
                    'cursor-pointer': unitValueAggregatesByType[type.key].confidence !== null,
                  }"
                  :style="`width: ${tableSizes.valueColumnWidth}px; height: ${
                    headerHeight - tableSizes.rowHeight
                  }px; ${
                    typeIndex === group.items.length - 1 ? '' : 'border-right-color: inherit'
                  }`"
                  :title="`${type.label} [${type.unit}]`"
                  @click="
                    typeDistributionPlotModalAggregate =
                      unitValueAggregatesByType[type.key].confidence === null
                        ? null
                        : unitValueAggregatesByType[type.key]
                  "
                >
                  <span class="break-words">
                    {{ type.label }}
                    <span class="break-normal"> [{{ type.unit }}] </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollDiv>
      <ScrollDiv
        position="both"
        :scrollContext="scrollContext"
        :hideScrollBar="true"
        class="overflow-auto overscroll-none flex"
        @scrolled="emit('scrolled', $event)"
      >
        <div
          v-for="(type, typeIndex) in types"
          :key="type.key"
          :style="`width: ${tableSizes.valueColumnWidth}px;`"
          class="flex flex-col shrink-0"
        >
          <ProjectUnitValue
            :value="getUnitValueForProjectAndType(project, type.key)"
            :lowerLimit="unitValueAggregatesByType[type.key].confidence_lower_limit"
            :upperLimit="unitValueAggregatesByType[type.key].confidence_upper_limit"
            v-for="(project, projectIndex) in projects"
            :key="`${project._id}_${unitValueAggregatesByType[type.key]._id}`"
            class="truncate text-center shrink-0 p-1 border-gray-600"
            :class="{
              'border-b': projectIndex !== projects.length - 1,
              'border-r': typeIndex !== types.length - 1,
            }"
            :style="`height: ${tableSizes.rowHeight}px; border-bottom-color: inherit; ${
              type.lastInGroup ? '' : 'border-right-color: inherit'
            }`"
          />
        </div>
      </ScrollDiv>
    </div>
    <ScrollDiv
      position="vertical"
      :style="`margin-top: ${headerHeight}px; min-width: ${
        scrollContext.scrollBarContainerWidth
      }px; transform: translateX(-${
        scrollContext.scrollBarContainerWidth - scrollContext.scrollBarWidth
      }px)`"
      :scrollContext="scrollContext"
      class="flex shrink-0 overflow-auto"
      @scrolled="emit('scrolled', $event)"
      @scrollBarSizeChanged="emit('scrollBarSizeChanged', $event)"
    >
      <div :style="`height: ${projects.length * tableSizes.rowHeight}px;`" class="shrink-0" />
    </ScrollDiv>
  </div>
  <div
    :style="`margin-left: ${tableSizes.projectColumnsWidth + 1}px;margin-right: ${
      scrollContext.scrollBarContainerWidth
    }px;`"
  >
    <ScrollDiv
      position="horizontal"
      :style="`min-height: ${scrollContext.scrollBarContainerHeight}px; transform: translateY(-${
        scrollContext.scrollBarContainerHeight - scrollContext.scrollBarHeight
      }px)`"
      :scrollContext="scrollContext"
      class="flex shrink-0 overflow-auto"
      @scrolled="emit('scrolled', $event)"
      @scrollBarSizeChanged="emit('scrollBarSizeChanged', $event)"
    >
      <div :style="`width: ${types.length * tableSizes.valueColumnWidth}px;`" class="shrink-0" />
    </ScrollDiv>
  </div>
  <TypeDistributionPlotModal
    v-if="typeDistributionPlotModalAggregate"
    :unitValueAggregate="typeDistributionPlotModalAggregate"
    @closed="typeDistributionPlotModalAggregate = null"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { Project } from "@/types/Project";
import { ScrollBarSizeEvent, ScrollContext, ScrollEvent } from "@/types/Scroll";
import {
  UnitValueAggregate,
  UnitValueAggregateByProject,
  UnitValueTableSizes,
  UnitValueType,
} from "@/types/UnitValue";
import ProjectUnitValue from "@/views/unit_values/components/ProjectUnitValue.vue";
import ScrollDiv from "@/views/unit_values/components/ScrollDiv.vue";
import TypeDistributionPlotModal from "@/views/unit_values/components/TypeDistributionPlotModal.vue";
import {
  calculateProjects,
  calculateTypeGroups,
  calculateTypes,
  calculateUnitValueAggregatesByType,
  calculateUnitValuesByProjectAndType,
} from "@/views/unit_values/unitValues";
import "../style.css";

const props = defineProps<{
  tableSizes: UnitValueTableSizes;
  scrollContext: ScrollContext;
  unitValueAggregates: UnitValueAggregate[];
  unitValueAggregatesByProject: UnitValueAggregateByProject[];
  allProjects: Project[];
  showAllColumns?: boolean | undefined;
}>();

const emit = defineEmits<{
  (eventName: "scrollBarSizeChanged", event: ScrollBarSizeEvent): void;
  (eventName: "scrolled", event: ScrollEvent): void;
}>();

const typeDistributionPlotModalAggregate = ref<UnitValueAggregate | null>(null);

const headerHeight = computed(() => props.tableSizes.rowHeight * 4);

const projects = computed(() =>
  calculateProjects(props.unitValueAggregatesByProject, props.allProjects),
);

const unitValueAggregatesByType = computed(() =>
  calculateUnitValueAggregatesByType(props.unitValueAggregates),
);

const unitValuesByProjectAndType = computed(() =>
  calculateUnitValuesByProjectAndType(props.unitValueAggregatesByProject),
);

const typeGroups = computed(() =>
  calculateTypeGroups(props.unitValueAggregatesByProject, { all: props.showAllColumns }),
);

const types = computed(() =>
  calculateTypes(props.unitValueAggregatesByProject, { all: props.showAllColumns }),
);

const getUnitValueForProjectAndType = (project: Project, type: UnitValueType) => {
  const { customer_name, site_id } = project;
  const key = `${customer_name}_${site_id}_${type}`;
  return unitValuesByProjectAndType.value[key]?.value;
};
</script>
