import { polygon } from "@turf/helpers";
import kinks from "@turf/kinks";
import { v4 as uuidv4 } from "uuid";
import { useCustomToast } from "@/composables/toast";
import { SectionMask, TagCombination, HierarchyTagBase, HierarchyType } from "@/types/HierarchyTag";

type Rect = {
  startX: number;
  startY: number;
  width: number;
  height: number;
};

export class Drawing {
  private img: HTMLImageElement;
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private customerName: string;
  private siteId: string;
  private cameraId: string;
  private sectionMasks: Array<SectionMask>;
  private currentSectionMask: SectionMask | null;
  private currentSubMask: [number, number][];
  private currentTagOption: TagCombination;
  private globalColor: string | null;
  private tagMap: Record<string, HierarchyTagBase>;
  private activeDrawing: boolean;
  private isDragging: boolean;
  private draggingIndex: number | null;
  private draggingInitialCoord: [number, number] | null;
  private currBbox: [number, number][];
  private prevBbox: [number, number][];
  private allBboxes: [number, number][][];
  private isDrawingBbox: boolean;
  private isMulti: boolean;

  constructor(
    canvas: HTMLCanvasElement,
    img: HTMLImageElement,
    sectionMasks: Array<SectionMask>,
    customerName: string,
    siteId: string,
    cameraId: string,
    tagMap: Record<string, HierarchyTagBase>,
    isMulti = false,
  ) {
    this.customerName = customerName as string;
    this.siteId = siteId as string;
    this.cameraId = cameraId as string;

    this.canvas = canvas;
    this.context = this.canvas.getContext("2d") as CanvasRenderingContext2D;
    this.canvas.width = img.naturalWidth;
    this.canvas.height = img.naturalHeight;
    this.img = img;
    this.context.lineWidth = 7;

    this.sectionMasks = this.convertRelativeToAbsoluteCoordinates(sectionMasks);
    this.tagMap = tagMap;

    // Section Mask Editing
    this.currentSectionMask = null;
    this.currentSubMask = [];
    this.currentTagOption = {} as TagCombination;
    this.globalColor = null;
    this.activeDrawing = false;

    // Dragging
    this.isDragging = false;
    this.draggingIndex = null;
    this.draggingInitialCoord = null as [number, number] | null;

    // bbox
    this.currBbox = [] as [number, number][];
    this.prevBbox = [] as [number, number][];
    this.allBboxes = [] as [number, number][][];
    this.isDrawingBbox = false as boolean;

    this.isMulti = isMulti;
  }

  public clearCanvas() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  public getCanvas(): HTMLCanvasElement {
    return this.canvas;
  }

  public getContext(): CanvasRenderingContext2D {
    return this.context;
  }

  public getImg(): HTMLImageElement {
    return this.img;
  }

  public setImg(img: HTMLImageElement) {
    this.canvas.width = img.naturalWidth;
    this.canvas.height = img.naturalHeight;
    this.img = img;
    this.render();
    if (this.currentSubMask.length > 0) {
      this.drawPartialMask(this.currentSubMask);
    }
  }

  public getSectionMasks(): Array<SectionMask> {
    this.closePolygon();
    return this.convertAbsoluteToRelativeCoordinates(this.sectionMasks);
  }

  public setSectionMasks(sectionMasks: Array<SectionMask>, renderBbox = false): void {
    this.sectionMasks = this.convertRelativeToAbsoluteCoordinates(sectionMasks);
    this.render();
    if (renderBbox) {
      this.drawAllBbox();
    }
  }

  public setTagMap(tagMap: Record<string, HierarchyTagBase>) {
    this.tagMap = tagMap;
  }

  public setActiveTag(tagOption: TagCombination) {
    this.currentTagOption = tagOption;
  }

  public setGlobalColor(color: string) {
    this.globalColor = color;
  }

  public getCurrentMask(): SectionMask | null {
    return this.currentSectionMask;
  }

  public setCurrentMask(id: string): void {
    const sectionMaskItem = this.sectionMasks.find((mask) => mask._id === id);
    if (sectionMaskItem) {
      this.currentSectionMask = sectionMaskItem;
      this.context.strokeStyle = this.currentSectionMask.color;
    }
  }

  public updateCurrentMaskCoordinates(maskCoordinates: [number, number][][]) {
    if (this.currentSectionMask) {
      this.currentSectionMask.mask = this.convertRelativeToAbsoluteList(maskCoordinates);
    }
  }

  public resetCurrentMask(): void {
    this.currentSectionMask = null;
    this.currentSubMask = [];
    this.activeDrawing = false;
  }

  public addMask(
    validity_start_local: null | Date = null,
    validity_end_local: null | Date = null,
  ): SectionMask {
    const newMask: SectionMask = {
      _id: uuidv4(),
      building_id: this.currentTagOption.building_id,
      level_id: this.currentTagOption.level_id,
      section_id: this.currentTagOption.section_id,
      level_split_id: this.currentTagOption.level_split_id,
      customer_name: this.customerName,
      site_id: this.siteId,
      camera_id: this.cameraId,
      color: this.globalColor ? this.globalColor : "",
      mask: [],
      show_on_canvas: true,
      validity_start_local: validity_start_local,
      validity_end_local: validity_end_local,
    };

    this.sectionMasks.push(newMask);
    return newMask;
  }

  private drawMask(displayedName: string, sectionMask: SectionMask): void {
    if (!sectionMask) {
      return;
    }
    const color = sectionMask.color;

    sectionMask.mask.forEach((subMask) => {
      const pointColor = subMask === this.currentSubMask ? "white" : color;
      const opacity = subMask === this.currentSubMask ? "0.1" : "0.2";

      this.context.strokeStyle = color;

      this.context.fillStyle = color;
      this.context.beginPath();
      for (let i = 0; i < subMask.length; i++) {
        const [x, y] = subMask[i];
        if (i == 0) {
          this.context.moveTo(x, y);
        } else {
          this.context.lineTo(x, y);
        }
      }
      this.context.closePath();
      this.context.stroke();

      this.context.fillStyle = color.slice(0, -4) + `${opacity})`;
      this.context.fill();

      this.context.fillStyle = pointColor;
      for (let i = 0; i < subMask.length; i++) {
        const [x, y] = subMask[i];
        this.drawFilledPoint(x, y, 7);
      }

      const centroid = this.centroidPolygon(subMask);
      this.context.font = "bold 30px arial";
      this.context.strokeStyle = "black";
      this.context.strokeText(displayedName, centroid.x - displayedName.length * 3, centroid.y);
      this.context.fillStyle = "white";
      this.context.fillText(displayedName, centroid.x - displayedName.length * 3, centroid.y);
    });
    this.context.strokeStyle = color;
  }

  public render() {
    this.context.lineWidth = 7;
    this.context.drawImage(this.img, 0, 0);
    this.sectionMasks.forEach((mask) => {
      if (mask.show_on_canvas) {
        if (mask.section_id && mask.section_id in this.tagMap) {
          this.drawMask(this.formatTagText(this.tagMap[mask.section_id]), mask);
        }
        if (!mask.section_id) {
          this.drawMask("Overlap Mask", mask);
        }
      }
    });
  }

  public async drawAllMasks(initial: boolean): Promise<void> {
    if (initial) {
      await this.setInitialBackgroundImage();
    }
    this.render();
  }

  private getCanvasCoord(e: MouseEvent) {
    // Open CV Format -> (0,0) - top left
    const canvas = this.getCanvas();
    const x = Math.abs((e.offsetX / canvas.clientWidth) * canvas.width);
    const y = Math.abs((e.offsetY / canvas.clientHeight) * canvas.height);
    return { x, y };
  }

  public drawLine = (e: MouseEvent): void => {
    if (!this.currentSectionMask) {
      const mask = this.addMask();
      this.setCurrentMask(mask._id);
    }

    const coords = this.getCanvasCoord(e);
    const coordinate = [Math.trunc(coords.x) as number, Math.trunc(coords.y)];
    if (
      this.currentSubMask.some((coord) => coord[0] === coordinate[0] && coord[1] === coordinate[1])
    ) {
      return;
    }

    const color = this.currentSectionMask ? this.currentSectionMask.color : "white";

    if (!this.activeDrawing) {
      this.currentSubMask = [];
      this.context.beginPath();
      this.context.moveTo(coordinate[0], coordinate[1]);
      this.activeDrawing = true;
    } else {
      this.context.lineTo(coordinate[0], coordinate[1]);
    }
    this.context.strokeStyle = color;
    this.context.stroke();

    this.context.fillStyle = color;
    this.drawFilledPoint(coordinate[0], coordinate[1], 7);
    this.context.moveTo(coordinate[0], coordinate[1]);

    this.currentSubMask.push(coordinate as [number, number]);
  };

  public undo = (): void => {
    if (this.currentSubMask.length > 0) {
      this.currentSubMask.pop();
      this.render();
      if (this.currentSubMask.length === 0) {
        this.activeDrawing = false;
      } else {
        this.drawPartialMask(this.currentSubMask);
      }
    }
  };

  private drawPartialMask = (mask: number[][]): void => {
    const color = this.currentSectionMask ? this.currentSectionMask.color : "white";
    this.context.fillStyle = color;
    this.context.strokeStyle = color;

    for (let i = 0; i < mask.length; i++) {
      const [x, y] = mask[i];
      if (i == 0) {
        this.context.beginPath();
        this.context.moveTo(x, y);
      } else {
        this.context.lineTo(x, y);
      }
    }
    this.context.stroke();

    for (let i = 0; i < mask.length; i++) {
      const [x, y] = mask[i];
      this.drawFilledPoint(x, y, 7);
    }
  };

  public closePolygon = (): void => {
    if (this.activeDrawing && this.currentSectionMask && this.currentSubMask.length > 2) {
      this.context.closePath();
      this.context.stroke();

      if (this.isSimplePolygon(this.currentSubMask)) {
        this.currentSectionMask.mask.push(structuredClone(this.currentSubMask));
      } else {
        useCustomToast().warning("Polygon must be simple (no intersections).");
      }

      this.activeDrawing = false;
      this.currentSubMask = [];
      this.render();
    }
  };

  private drawFilledPoint(x: number, y: number, radius: number) {
    this.context.beginPath();
    this.context.arc(x, y, radius, 0, Math.PI * 2);
    this.context.fill();
    this.context.stroke();
  }

  public setInitialBackgroundImage(): Promise<void> {
    return new Promise<void>((resolve) => {
      const ctx = this.context;
      const background = new Image();
      background.src = this.img.src;
      background.onload = function () {
        ctx.drawImage(background, 0, 0);
        resolve();
      };
    });
  }

  private capitalizeSectionType(name: HierarchyType) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  private formatTagText(tag: HierarchyTagBase) {
    return `${tag.number} - ` + (tag.name ? `[${tag.name}]` : this.capitalizeSectionType(tag.type));
  }

  public onMouseDownDragging(e: MouseEvent) {
    const coords = this.getCanvasCoord(e);
    this.draggingInitialCoord = [coords.x, coords.y];

    for (let i = 0; i < this.currentSubMask.length; i++) {
      const dx = this.currentSubMask[i][0] - coords.x;
      const dy = this.currentSubMask[i][1] - coords.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance <= 30) {
        this.isDragging = true;
        this.draggingIndex = i;
        break;
      }
    }
  }

  public onMouseMoveDragging(e: MouseEvent) {
    if (!this.isDragging || this.draggingIndex === null) {
      return;
    }
    const coords = this.getCanvasCoord(e);
    this.currentSubMask[this.draggingIndex] = [coords.x, coords.y];
    this.render();
  }

  public onMouseUpDragging() {
    setTimeout(() => {
      if (
        this.draggingIndex !== null &&
        this.draggingInitialCoord &&
        !this.isSimplePolygon(this.currentSubMask)
      ) {
        this.currentSubMask[this.draggingIndex] = this.draggingInitialCoord;
        this.render();
        useCustomToast().warning("Polygon must be simple (no intersections).");
      }
      this.isDragging = false;
      this.draggingIndex = null;
    }, 0);
  }

  public editSubMask = (e: MouseEvent): void => {
    if (this.isDragging) {
      return;
    }
    const coords = this.getCanvasCoord(e);
    if (this.currentSubMask.length === 0) {
      this.focusSubMask(e);
    } else {
      if (!this.isPointInPolygon([coords.x, coords.y], this.currentSubMask)) {
        this.focusSubMask(e);
      }
    }
  };

  public unfocusSubMask = (): void => {
    this.currentSubMask = [];
    this.render();
  };

  private focusSubMask(e: MouseEvent) {
    const coords = this.getCanvasCoord(e);

    let foundMask = false;
    this.sectionMasks.forEach((sectionMask) => {
      if (sectionMask.show_on_canvas) {
        sectionMask.mask.forEach((subMask) => {
          if (this.isPointInPolygon([coords.x, coords.y], subMask as [number, number][])) {
            this.currentSubMask = subMask as [number, number][];
            this.render();
            foundMask = true;
          }
        });
      }
    });

    if (!foundMask) {
      this.unfocusSubMask();
    }
  }

  private centroidPolygon(polygonPts: Array<Array<number>>) {
    const pts = polygonPts.slice();
    const first = pts[0];
    const last = pts[pts.length - 1];

    if (first[0] != last[0] || first[1] != last[1]) {
      pts.push(first);
    }

    let twicearea = 0,
      x = 0,
      y = 0;

    const nPts = pts.length;
    let p1, p2, f;
    for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
      p1 = pts[i];
      p2 = pts[j];
      f = p1[0] * p2[1] - p2[0] * p1[1];
      twicearea += f;
      x += (p1[0] + p2[0]) * f;
      y += (p1[1] + p2[1]) * f;
    }
    f = twicearea * 3;
    return { x: x / f, y: y / f };
  }

  private isPointInPolygon(point: [number, number], polygon: [number, number][]) {
    const [x, y] = point;
    let inside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const [xi, yi] = polygon[i];
      const [xj, yj] = polygon[j];

      if (yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi) {
        inside = !inside;
      }
    }

    return inside;
  }

  private convertRelativeToAbsoluteCoordinates(masks: Array<SectionMask>) {
    const masksClone = structuredClone(masks);
    masksClone.forEach((item) => {
      item.mask = this.convertRelativeToAbsoluteList(item.mask);
    });
    return masksClone;
  }

  private convertRelativeToAbsoluteList(maskList: [number, number][][]): [number, number][][] {
    return maskList.map((sublist) =>
      sublist.map(([x, y]) => [x * this.canvas.width, y * this.canvas.height]),
    );
  }

  private convertAbsoluteToRelativeCoordinates(masks: Array<SectionMask>) {
    const masksClone = structuredClone(masks);
    masksClone.forEach((item) => {
      item.mask = item.mask.map((sublist) =>
        sublist.map(([x, y]) => [x / this.canvas.width, y / this.canvas.height]),
      );
    });
    return masksClone;
  }

  // bbox
  public setAllBbox(bboxes: [number, number][][]) {
    this.allBboxes = bboxes.map((bbox) =>
      bbox.map(([x, y]) => [x * this.canvas.width, y * this.canvas.height]),
    );
    this.currBbox = this.allBboxes[0];
  }

  public clearAllBbox() {
    this.currBbox = [];
    this.allBboxes = [];
  }

  public getAllBbox(): [number, number][][] {
    return this.allBboxes.map((bbox) =>
      bbox.map(([x, y]) => [
        Math.min(1, Math.max(0, x / this.canvas.width)),
        Math.min(1, Math.max(0, y / this.canvas.height)),
      ]),
    );
  }

  public onMouseDownBbox(e: MouseEvent) {
    const leftButton = 0;
    if (e.button === leftButton) {
      this.prevBbox = structuredClone(this.currBbox);
      const coords = this.getCanvasCoord(e);
      const coordinate = [Math.trunc(coords.x) as number, Math.trunc(coords.y)];
      this.currBbox = [coordinate as [number, number]]; // Initialize array with first element
      this.isDrawingBbox = true;
    } else {
      this.prevBbox = [];
      this.currBbox = [];
      this.allBboxes.splice(-1, 1);
      this.renderAll();
    }
  }

  public onMouseMoveBbox(e: MouseEvent) {
    if (this.isDrawingBbox && this.currBbox.length > 0) {
      const coords = this.getCanvasCoord(e);
      const coordinate = [Math.trunc(coords.x) as number, Math.trunc(coords.y)];

      const rect = {
        startX: this.currBbox[0][0],
        startY: this.currBbox[0][1],
        width: coordinate[0] - this.currBbox[0][0],
        height: coordinate[1] - this.currBbox[0][1],
      };
      this.renderBboxAndMasks(rect);
    }
  }

  public onMouseOutBbox(e: MouseEvent) {
    if (this.isDrawingBbox) {
      this.onMouseUpBbox(e);
    }
  }

  public onMouseUpBbox(e: MouseEvent, validate?: CallableFunction) {
    if (!this.isDrawingBbox) {
      return;
    }

    const coords = this.getCanvasCoord(e);
    const coordinate = [Math.trunc(coords.x) as number, Math.trunc(coords.y)];
    this.isDrawingBbox = false;

    const newBox = this.getCoordsFromStartAndEnd(this.currBbox[0], coordinate as [number, number]);
    const newBoxCoords = newBox.map(([x, y]) => [
      Math.min(1, Math.max(0, x / this.canvas.width)),
      Math.min(1, Math.max(0, y / this.canvas.height)),
    ]);

    const isNewBoxValid = validate ? validate([newBoxCoords]) : true;

    if (isNewBoxValid) {
      this.currBbox = newBox;
      this.allBboxes = this.isMulti ? [...this.allBboxes, this.currBbox] : [this.currBbox];
    } else {
      this.currBbox = [];
    }
  }

  private getXywhFromCoords(bbox: [number, number][]) {
    if (bbox?.length === 4) {
      return {
        startX: bbox[0][0],
        startY: bbox[0][1],
        width: bbox[2][0] - bbox[0][0],
        height: bbox[2][1] - bbox[0][1],
      } as Rect;
    }
    return null;
  }

  private getCoordsFromStartAndEnd(startCoord: [number, number], endCoord: [number, number]) {
    if (startCoord && endCoord) {
      return [
        [startCoord[0], startCoord[1]],
        [endCoord[0], startCoord[1]],
        [endCoord[0], endCoord[1]],
        [startCoord[0], endCoord[1]],
      ] as [number, number][];
    }
    return [];
  }
  public renderBboxAndMasks(rect: Rect) {
    this.render();
    this.drawBbox(rect);
  }

  private drawBbox(rect: Rect) {
    if (this.isMulti) {
      this.drawAllBbox();
    } else {
      this.context.strokeStyle = "green";
      const prev = this.getXywhFromCoords(this.prevBbox);
      if (prev) {
        this.context.strokeRect(prev.startX, prev.startY, prev.width, prev.height);
      }
    }

    this.context.strokeStyle = "red";
    this.context.strokeRect(rect.startX, rect.startY, rect.width, rect.height);
  }

  private drawAllBbox() {
    this.context.strokeStyle = "red";
    for (const bbox of this.allBboxes) {
      const curr = this.getXywhFromCoords(bbox);
      if (curr) {
        this.context.strokeRect(curr.startX, curr.startY, curr.width, curr.height);
      }
    }
  }

  public renderAll() {
    this.render();
    this.drawAllBbox();
  }

  public setIsMulti(isMulti: boolean) {
    this.isMulti = isMulti;
  }

  public onContextMenuBbox(event: MouseEvent) {
    event.preventDefault();
    return false;
  }

  public getIsDraggingStatus() {
    return this.isDragging;
  }
  public isSimplePolygon(mask: [number, number][]) {
    mask = [...mask, mask[0]];
    const polygonMask = polygon([mask]);
    return kinks(polygonMask).features.length === 0;
  }
}
