<template>
  <table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr>
        <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
          Customer
        </th>
        <th
          scope="col"
          class="px-3 py-3 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"
        >
          Site ID
        </th>
        <th
          scope="col"
          class="px-3 py-3 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"
        >
          Camera ID
        </th>
        <th scope="col" class="px-3 py-3 text-left text-sm font-semibold text-gray-900">Date</th>
        <th scope="col" class="px-3 py-3 text-left text-sm font-semibold text-gray-900">State</th>
        <th scope="col" class="px-3 py-3 text-center text-sm font-semibold text-gray-900">
          Processes
        </th>
        <th scope="col" class="px-3 py-3 text-center text-sm font-semibold text-gray-900">
          Work Intervals
        </th>
        <th scope="col" class="px-3 py-3 text-center text-sm font-semibold text-gray-900">
          Quality Score
        </th>
        <th
          scope="col"
          class="px-3 py-3 text-left text-sm font-semibold text-gray-900"
          v-for="typeErrorScore in typeErrorScores"
          :key="typeErrorScore.type"
          :title="typeErrorScore.type"
        >
          {{ typeErrorScore.type }}
        </th>
        <th scope="col" class="px-3 py-3 text-left text-sm font-semibold text-gray-900">
          Checked by
        </th>
        <th scope="col" class="px-3 py-3 text-center text-sm font-semibold text-gray-900">Links</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr v-for="opsReview in opsReviewsForBatch" :key="opsReview._id">
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {{ opsReview.customer_name }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {{ opsReview.site_id }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {{ opsReview.camera_id }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {{ opsReview.date }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div
            class="bg-green-200 text-white px-1 py-0.5 rounded w-min text-xs"
            :class="opsReviewStateClasses[getOpsReviewState(opsReview)]"
          >
            {{ opsReviewStateLabels[getOpsReviewState(opsReview)] }}
          </div>
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
          {{ processesByOpsReview[getId(opsReview)]?.length || 0 }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
          {{ qualityScoreByOpsReview[opsReview._id].workIntervalsWithoutErrors }}/{{
            calculateWorkIntervalCount(processesByOpsReview[getId(opsReview)])
          }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
          {{ formatPercentage(qualityScoreByOpsReview[opsReview._id].score) }}
        </td>
        <td
          class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"
          v-for="typeErrorScore in typeErrorScores"
          :key="typeErrorScore.type"
        >
          {{ getErrorTypeCount(opsReview, typeErrorScore.type) }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
          {{ opsReview.checked_by }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div class="flex justify-center">
            <router-link
              :to="{
                name: 'ValidationPrdDate',
                params: {
                  customer_name: opsReview.customer_name,
                  site_id: opsReview.site_id,
                  date: opsReview.date,
                },
                query: {
                  camera_id: opsReview.camera_id,
                  back_route: JSON.stringify(backRoute),
                },
              }"
              target="_blank"
            >
              <ArrowTopRightOnSquareIcon class="w-5 h-5" />
            </router-link>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/20/solid";
import { computed } from "vue";
import { useRoute } from "vue-router";
import {
  OpsReview,
  OpsReviewedProcessErrorType,
  OpsReviewedProcessWithErrors,
  OpsReviewQualityScore,
} from "@/types/Validation";
import {
  calculateErrorsByType,
  calculateQualityScore,
  calculateTypeErrorScores,
} from "@/views/process_validation/services/qualityScore";
import { formatPercentage } from "../services/validation";

type OpsReviewState = "ready" | "reviewed" | "checked";

const opsReviewStateLabels: Record<OpsReviewState, string> = {
  ready: "ready",
  reviewed: "reviewed",
  checked: "checked",
};

const opsReviewStateClasses: Record<OpsReviewState, string> = {
  ready: "bg-yellow-300 text-yellow-900",
  reviewed: "bg-green-200 text-green-900",
  checked: "bg-purple-200 text-purple-900",
};

const props = defineProps<{
  opsReviewedProcessesWithErrors: OpsReviewedProcessWithErrors[];
  opsReviewsForBatch: OpsReview[];
}>();

const route = useRoute();

const typeErrorScores = computed(() =>
  calculateTypeErrorScores(props.opsReviewedProcessesWithErrors),
);

const backRoute = computed(() => ({
  name: "QualitySamplingDetailView",
  path: route.path,
  query: route.query,
}));

const getOpsReviewState = (opsReview: OpsReview): OpsReviewState => {
  if (!opsReview.reviewed && !opsReview.checked) {
    return "ready";
  }
  if (opsReview.checked) {
    return "checked";
  }
  return "reviewed";
};

const getId = (processOrOpsReview: OpsReviewedProcessWithErrors | OpsReview) =>
  `${processOrOpsReview.customer_name}_${processOrOpsReview.site_id}_${processOrOpsReview.camera_id}_${processOrOpsReview.date}`;

const processesByOpsReview = computed(() =>
  props.opsReviewedProcessesWithErrors.reduce((acc, processWithErrors) => {
    const key = getId(processWithErrors);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(processWithErrors);
    return acc;
  }, {} as Record<string, OpsReviewedProcessWithErrors[]>),
);

const qualityScoreByOpsReview = computed(() =>
  props.opsReviewsForBatch.reduce((acc, opsReview) => {
    acc[opsReview._id] = calculateQualityScore(processesByOpsReview.value[getId(opsReview)] || []);
    return acc;
  }, {} as Record<string, OpsReviewQualityScore>),
);

const getErrorTypeCount = (opsReview: OpsReview, type: OpsReviewedProcessErrorType) => {
  const id = getId(opsReview);
  const errorsByType = calculateErrorsByType(processesByOpsReview.value[id] ?? []);
  return errorsByType[type];
};

const calculateWorkIntervalCount = (processes?: OpsReviewedProcessWithErrors[]) =>
  processes?.reduce((acc, process) => acc + process.work_intervals.length, 0) || 0;
</script>

<style scoped>
tr:hover {
  background-color: #fcf6ea;
}
</style>
