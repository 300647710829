import { AxiosResponse } from "axios";
import { DailyPipelineMetricsResponse, RangePipelineMetricsPlots } from "@/views/metrics/types";
import { apiClient } from "./clients";

const getPipelineMetricsDaily = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
  filter: string,
): Promise<DailyPipelineMetricsResponse> => {
  const searchParams = new URLSearchParams({ filter });

  return apiClient
    .get<void, AxiosResponse<DailyPipelineMetricsResponse>>(
      `/prd-validation/pipeline-metrics/daily/${customerName}/${siteId}/${cameraId}/${date}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const getPipelineMetricsRange = (
  customerName: string,
  siteId: string,
  cameraId: string,
  startDate: string,
  endDate: string,
  filter: string,
): Promise<RangePipelineMetricsPlots> => {
  const searchParams = new URLSearchParams({ filter });

  return apiClient
    .get<void, AxiosResponse<RangePipelineMetricsPlots>>(
      `/prd-validation/pipeline-metrics/range/${customerName}/${siteId}/${cameraId}/${startDate}/${endDate}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

export default {
  getPipelineMetricsDaily,
  getPipelineMetricsRange,
};
