import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full flex items-center justify-center"
}
const _hoisted_2 = {
  key: 1,
  class: "h-full flex items-center justify-center"
}
const _hoisted_3 = {
  key: 2,
  class: "overflow-y-auto flex flex-col gap-2 text-left"
}
const _hoisted_4 = { class: "flex-1 min-w-0" }
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 0,
  class: "bg-yellow-400 text-white px-1 py-0.5 text-xs"
}
const _hoisted_7 = { class: "text-xm" }
const _hoisted_8 = { class: "flex justify-between gap-4 items-center" }
const _hoisted_9 = ["title"]
const _hoisted_10 = {
  key: 0,
  class: "flex gap-1"
}
const _hoisted_11 = {
  key: 0,
  class: "text-center text-xs text-gray-500 mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_FormattedDate = _resolveComponent("FormattedDate")!
  const _component_ArrowUpOnSquareIcon = _resolveComponent("ArrowUpOnSquareIcon")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_SlideOver = _resolveComponent("SlideOver")!

  return (_openBlock(), _createBlock(_component_SlideOver, {
    open: _ctx.open,
    onCloseSlideOver: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closed'))),
    customCls: "sm:w-1/3"
  }, {
    title: _withCtx(() => [
      _createTextVNode("Revisions")
    ]),
    content: _withCtx(() => [
      (!_ctx.loaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_LoadingSpinner)
          ]))
        : _createCommentVNode("", true),
      (_ctx.loaded && _ctx.plans.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " No revisions yet "))
        : _createCommentVNode("", true),
      (_ctx.loaded && _ctx.plans.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan) => {
              return (_openBlock(), _createElementBlock("div", {
                key: plan._id,
                class: _normalizeClass(['flex flex-row gap-4 p-3', { 'bg-yellow-200': _ctx.currentPlanId === plan._id }])
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "font-bold truncate",
                    title: plan.name
                  }, [
                    (plan.created && _ctx.isNew(plan.created))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, "new"))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(plan.name), 1)
                  ], 8, _hoisted_5),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(plan.planner_item_count) + " planner items", 1),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", {
                      class: "flex-1 truncate text-gray-500",
                      title: plan.created_by
                    }, _toDisplayString(plan.created_by), 9, _hoisted_9)
                  ]),
                  _createVNode(_component_FormattedDate, {
                    "date-string": plan.created,
                    cls: "text-xs text-gray-500"
                  }, null, 8, ["date-string"])
                ]),
                (_ctx.currentPlanId !== plan._id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_ArrowUpOnSquareIcon, {
                        class: _normalizeClass([
                'h-5 w-5',
                {
                  'text-gray-400': _ctx.isActionLoading,
                  'text-green-500': !_ctx.isActionLoading,
                  'cursor-pointer': !_ctx.isActionLoading,
                },
              ]),
                        "aria-hidden": "true",
                        title: "Activate Revision",
                        onClick: ($event: any) => (!_ctx.isActionLoading && _ctx.activateRevision(plan))
                      }, null, 8, ["class", "onClick"]),
                      _createVNode(_component_TrashIcon, {
                        class: _normalizeClass([
                'h-5 w-5',
                {
                  'text-gray-400': _ctx.isActionLoading,
                  'text-red-500': !_ctx.isActionLoading,
                  'cursor-pointer': !_ctx.isActionLoading,
                },
              ]),
                        "aria-hidden": "true",
                        title: "Delete Revision",
                        onClick: ($event: any) => (!_ctx.isActionLoading && _ctx.deleteRevision(plan))
                      }, null, 8, ["class", "onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128)),
            (_ctx.plans.length >= 100)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Note: Only 100 items are shown "))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open"]))
}