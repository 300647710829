<template>
  <MainLayout activeItem="PRD">
    <div class="flex flex-col flex-1 overflow-hidden">
      <div
        class="flex justify-between items-center bg-gray-50 px-6 py-4 gap-6 flex-col lg:flex-row"
      >
        <div class="flex items-center gap-6 flex-col lg:flex-row">
          <router-link
            :to="{
              name: 'QualitySamplingOverviewView',
              query: {
                start: format(startDate, urlDateFormat),
                end: format(endDate, urlDateFormat),
              },
            }"
            class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 whitespace-nowrap"
          >
            <ArrowLeftIcon class="-ml-1 h-5 w-5" aria-hidden="true" />
            <span class="block lg:hidden ml-3 xl:block">Back to overview</span>
          </router-link>
          <div>
            <div
              class="text-lg font-semibold text-gray-900 text-center lg:text-left whitespace-nowrap"
            >
              Week {{ calendarWeek }}
            </div>
            <div class="text-xs whitespace-nowrap">
              {{ `${formatDate(startDate)} - ${formatDate(endDate)} ` }}
            </div>
          </div>
        </div>
        <div class="flex gap-4 items-center flex-col lg:flex-row">
          <Menu
            as="div"
            class="relative"
            v-if="selectedBatch && batchesForCurrentInterval.length > 0"
          >
            <MenuButton
              type="button"
              class="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 capitalize whitespace-nowrap"
            >
              {{ selectedBatch.name }} [{{ formatPercentage(selectedBatch.percentage) }}]
              <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none"
              >
                <div class="py-1 capitalize">
                  <MenuItem
                    v-slot="{ active }"
                    v-for="opsReviewBatch in batchesForCurrentInterval"
                    :key="opsReviewBatch._id"
                    @click="changeSelectedBatch(opsReviewBatch)"
                  >
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      >{{ opsReviewBatch.name }} [{{
                        formatPercentage(opsReviewBatch.percentage)
                      }}]</a
                    >
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
          <WeekSelector
            :startDate="startDate"
            :endDate="endDate"
            :calendarWeek="calendarWeek"
            @change="handleWeekChange"
          />
          <button
            type="button"
            class="inline-flex items-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none"
            @click="isCreateBatchModalOpen = true"
            v-if="hasPermission(['pct_admin'])"
          >
            <PlusIcon class="h-5 w-5 text-white" aria-hidden="true" />
            <span class="pl-1 whitespace-nowrap">Create Batch</span>
          </button>
          <button
            type="button"
            class="inline-flex items-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none"
            @click="handleDeleteBatchClick"
            v-if="selectedBatch && hasPermission(['pct_admin'])"
          >
            <TrashIcon
              class="h-5 w-5 text-white"
              aria-hidden="true"
              v-if="!isDeleteOpsReviewBatchLoading"
            />
            <LoadingSpinner size="h-5 w-5 text-white" v-if="isDeleteOpsReviewBatchLoading" />
            <span class="pl-2 whitespace-nowrap">Delete Batch</span>
          </button>
          <button
            type="button"
            class="inline-flex items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none"
            @click="resetStartEndToDefault"
          >
            <ArrowUturnLeftIcon class="h-5 w-5 text-white" aria-hidden="true" />
            <span class="pl-2">Reset</span>
          </button>
        </div>
      </div>
      <div class="flex-1 flex items-center justify-center" v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-if="!isLoading" class="flex-1 flex flex-col overflow-hidden">
        <div
          v-if="opsReviewsForBatch && opsReviewsForBatch.length > 0"
          class="flex-1 grid overflow-hidden border-t"
          :style="{ gridTemplateColumns: '3fr 1fr' }"
        >
          <div class="flex-1 overflow-auto">
            <QualitySamplingDetailTable
              :opsReviewedProcessesWithErrors="opsReviewedProcessesWithErrors as (typeof QualitySamplingDetailTable)['opsReviewedProcessesWithErrors']"
              :opsReviewsForBatch="opsReviewsForBatch"
            />
          </div>
          <div class="border-l px-4 py-8 overflow-auto">
            <QualitySamplingDetailSidebar
              :opsReviewedProcessesWithErrors="opsReviewedProcessesWithErrors as (typeof QualitySamplingDetailSidebar)['opsReviewedProcessesWithErrors']"
              :opsReviewsForBatch="opsReviewsForBatch"
            />
          </div>
        </div>
        <div v-else class="flex-1 flex items-center justify-center">No items found 🤷‍♂️</div>
      </div>
    </div>
    <CreateOpsReviewBatchModal
      v-if="isCreateBatchModalOpen"
      @closed="handleCreateOpsReviewBatchModalClosed"
      :startDate="startDate"
      :endDate="endDate"
    />
  </MainLayout>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ArrowLeftIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";
import { ArrowUturnLeftIcon, PlusIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import MainLayout from "@/components/layout/MainLayout.vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import { DateInterval } from "@/types/DateInterval";
import { OpsReviewBatch } from "@/types/Validation";
import CreateOpsReviewBatchModal from "@/views/process_validation/components/CreateOpsReviewBatchModal.vue";
import QualitySamplingDetailSidebar from "@/views/process_validation/components/QualitySamplingDetailSidebar.vue";
import QualitySamplingDetailTable from "@/views/process_validation/components/QualitySamplingDetailTable.vue";
import WeekSelector from "@/views/process_validation/components/inputs/WeekSelector.vue";
import { useStartEndDate } from "@/views/process_validation/composables/general";
import {
  useDeleteOpsReviewBatch,
  useOpsReviewBatches,
} from "@/views/process_validation/composables/opsReviewBatch";
import {
  useOpsReviewedProcessesWithErrorsForBatches,
  useOpsReviewsForBatch,
} from "@/views/process_validation/composables/validation";
import { formatPercentage, urlDateFormat } from "../services/validation";

const router = useRouter();
const route = useRoute();

const isCreateBatchModalOpen = ref(false);

const { opsReviewBatches, isLoading: areOpsReviewBatchesLoading } = useOpsReviewBatches();
const { deleteOpsReviewBatch, isLoading: isDeleteOpsReviewBatchLoading } =
  useDeleteOpsReviewBatch();
const { startDate, endDate, calendarWeek, changeStartAndEndDate, resetStartEndToDefault } =
  useStartEndDate();

const batchesForCurrentInterval = computed(() => {
  const batches = (opsReviewBatches.value || []).filter(
    (opsReviewBatch) =>
      opsReviewBatch.start_date_local >= startDate.value &&
      opsReviewBatch.end_date_local <= endDate.value,
  );
  batches.sort((a, b) => a.name.localeCompare(b.name));
  return batches;
});

const defaultBatch = computed(() => batchesForCurrentInterval.value[0]);

const selectedBatch = computed(() => {
  const batchId = route.query.batch_id as string;
  return (
    batchesForCurrentInterval.value?.find((opsReviewBatch) => opsReviewBatch._id === batchId) ||
    defaultBatch.value
  );
});

const { opsReviewedProcessesWithErrors, isLoading: areOpsReviewedProcessesWithErrorsLoading } =
  useOpsReviewedProcessesWithErrorsForBatches(
    computed(() => (selectedBatch.value ? [selectedBatch.value._id] : [])),
  );

const selectedBatchId = computed(() => selectedBatch.value?._id);

const { opsReviewsForBatch, isLoading: areOpsReviewsForBatchLoading } =
  useOpsReviewsForBatch(selectedBatchId);

const isLoading = computed(
  () =>
    areOpsReviewBatchesLoading.value ||
    areOpsReviewsForBatchLoading.value ||
    areOpsReviewedProcessesWithErrorsLoading.value,
);

const changeSelectedBatch = async (newBatch: OpsReviewBatch | undefined) => {
  await router.replace({
    query: {
      ...route.query,
      batch_id: newBatch?._id ?? undefined,
    },
  });
};

const formatDate = (date: Date) => format(date, "dd.MM.yyyy");

const handleWeekChange = async (event: DateInterval) => {
  await changeStartAndEndDate(event.startDate, event.endDate);
};

const handleCreateOpsReviewBatchModalClosed = async (opsReviewBatch?: OpsReviewBatch) => {
  isCreateBatchModalOpen.value = false;
  if (opsReviewBatch) {
    await changeSelectedBatch(opsReviewBatch);
  }
};

const handleDeleteBatchClick = () => {
  if (!selectedBatch.value || isDeleteOpsReviewBatchLoading.value) {
    return;
  }
  if (window.confirm(`Are you sure to delete "${selectedBatch.value.name}"?`)) {
    deleteOpsReviewBatch(selectedBatch.value._id);
  }
};
</script>
