import {
  OpsReviewedProcessErrorType,
  OpsReviewedProcessWithErrors,
  OpsReviewQualityScore,
} from "@/types/Validation";

const ignoreErrors: OpsReviewedProcessErrorType[] = ["wrong_bounding_box_size"];

const countPerWorkInterval: OpsReviewedProcessErrorType[] = ["wrong_time", "wrong_people_count"];

export const prepareOpsReviewedProcessesWithErrorsForQualityScore = (
  processesWithErrors: OpsReviewedProcessWithErrors[],
  { useAllErrors }: { useAllErrors: boolean } = { useAllErrors: false },
) =>
  processesWithErrors
    .filter((processWithErrors) => processWithErrors.is_checked)
    .map((processWithErrors) => ({
      ...processWithErrors,
      errors: processWithErrors.errors.filter(
        (error) => useAllErrors || (!useAllErrors && !ignoreErrors.includes(error.type)),
      ),
    }));

const calculateErrorsByTypeForProcessWithErrors = (
  processWithErrors: OpsReviewedProcessWithErrors,
) =>
  processWithErrors.errors.reduce((acc, error) => {
    acc[error.type] = countPerWorkInterval.includes(error.type)
      ? (acc[error.type] ?? 0) + 1
      : processWithErrors.work_intervals.length;
    return acc;
  }, {} as Record<Partial<OpsReviewedProcessErrorType>, number>);

export const calculateErrorsByType = (processesWithErrors: OpsReviewedProcessWithErrors[]) => {
  const processesWithErrorsToConsider = prepareOpsReviewedProcessesWithErrorsForQualityScore(
    processesWithErrors,
    { useAllErrors: true },
  );
  return processesWithErrorsToConsider
    .map((processWithErrors) => calculateErrorsByTypeForProcessWithErrors(processWithErrors))
    .reduce((acc, item) => {
      for (const [key, value] of Object.entries(item)) {
        const type = key as OpsReviewedProcessErrorType;
        acc[type] = (acc[type] ?? 0) + value;
      }
      return acc;
    }, {} as Record<Partial<OpsReviewedProcessErrorType>, number>);
};

export const calculateQualityScore = (
  processesWithErrors: OpsReviewedProcessWithErrors[],
): OpsReviewQualityScore => {
  const processesWithErrorsToConsider =
    prepareOpsReviewedProcessesWithErrorsForQualityScore(processesWithErrors);
  const { totalWorkIntervals, workIntervalErrors } = processesWithErrorsToConsider.reduce(
    (acc, processWithErrors) => {
      const errorsByType = calculateErrorsByTypeForProcessWithErrors(processWithErrors);
      const allErrorCount = Object.values(errorsByType).reduce(
        (acc, errorCount) => acc + errorCount,
        0,
      );
      acc.totalWorkIntervals += processWithErrors.work_intervals.length;
      acc.workIntervalErrors += Math.min(allErrorCount, processWithErrors.work_intervals.length);
      return acc;
    },
    { totalWorkIntervals: 0, workIntervalErrors: 0 },
  );
  const score = totalWorkIntervals > 0 ? 1 - workIntervalErrors / totalWorkIntervals : 0;
  const workIntervalsWithoutErrors = totalWorkIntervals - workIntervalErrors;
  return {
    score,
    workIntervalsWithoutErrors,
    totalWorkIntervals,
  };
};

export const calculateTypeErrorScores = (processesWithErrors: OpsReviewedProcessWithErrors[]) => {
  const errorsByType = calculateErrorsByType(processesWithErrors);
  const allErrorsCount = Object.values(errorsByType).reduce((acc, count) => acc + count, 0);
  const result = Object.entries(errorsByType).map(([key, count]) => ({
    type: key as OpsReviewedProcessErrorType,
    count,
    errorScore: allErrorsCount > 0 ? count / allErrorsCount : 0,
  }));
  result.sort((a, b) => a.type.localeCompare(b.type));
  return result;
};
