import { endOfWeek, format, getISOWeek, parse, startOfWeek } from "date-fns";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { urlDateFormat } from "@/views/process_validation/services/validation";

export const useStartEndDate = () => {
  const router = useRouter();
  const route = useRoute();

  const defaultStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  const defaultEndDate = endOfWeek(new Date(), { weekStartsOn: 1 });

  const startDate = computed(() =>
    route.query.start
      ? parse(route.query.start as string, urlDateFormat, new Date())
      : defaultStartDate,
  );

  const endDate = computed(() =>
    route.query.end ? parse(route.query.end as string, urlDateFormat, new Date()) : defaultEndDate,
  );

  const calendarWeek = computed(() => getISOWeek(startDate.value));

  const changeStartAndEndDate = (newStartDate: Date, newEndDate: Date) =>
    router.replace({
      query: {
        ...route.query,
        start: format(newStartDate, urlDateFormat),
        end: format(newEndDate, urlDateFormat),
      },
    });

  const resetStartEndToDefault = () => changeStartAndEndDate(defaultStartDate, defaultEndDate);

  return {
    startDate,
    endDate,
    calendarWeek,
    changeStartAndEndDate,
    resetStartEndToDefault,
  };
};
