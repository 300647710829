<template>
  <div :title="formattedValue" :style="`background-color: ${backgroundColor}`" v-bind="otherProps">
    {{ formattedValue }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { formatNumber } from "@/views/unit_values/unitValues";

const props = defineProps<{
  value?: number | null;
  lowerLimit?: number | null;
  upperLimit?: number | null;
}>();

const otherProps = computed(() => {
  const { value, lowerLimit, upperLimit, ...rest } = props;
  return rest;
});

const backgroundColor = computed(() => {
  if (
    typeof props.lowerLimit !== "number" ||
    typeof props.upperLimit !== "number" ||
    typeof props.value !== "number"
  ) {
    return "none";
  }
  const interval = props.upperLimit - props.lowerLimit;
  const colors = ["#63be7b80", "#84c77c80", "#e2e28280", "#ffe98480", "#fdc57d80", "#f8696b80"];
  const step = interval / colors.length;
  const index = Math.floor((props.value - props.lowerLimit) / step);
  const finalIndex = Math.max(Math.min(index, colors.length - 1), 0);
  return colors[finalIndex];
});

const formattedValue = computed(() => formatNumber(props.value ?? null));
</script>
