import { useQuery } from "@tanstack/vue-query";
import { useCustomToast } from "@/composables/toast";
import HierarchyRepository from "@/repositories/HierarchyRepository";
import logger from "@/services/logger";
import { HierarchyTagStore } from "@/types/HierarchyTag";

export const useAllHierarchyTags = () => {
  const { data, isLoading } = useQuery<HierarchyTagStore[]>({
    queryKey: ["hierarchy-tags-all"],
    queryFn: () => HierarchyRepository.loadAllHierarchyData(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load all hierarchy tags");
      return false;
    },
  });

  return { hierarchyTags: data, areHierarchyTagsLoading: isLoading };
};
