import { UnitValueType } from "@/types/UnitValue";

const softHyphen = "\u00ad";

export const typeLabelsAndUnits: Record<UnitValueType, { label: string; unit: string }> = {
  uv_wall_default: { label: `Ortbeton${softHyphen}wände`, unit: "m²" },
  uv_wall_default_formwork_m2: { label: `Ortbeton${softHyphen}wände Schalung`, unit: "m²" },
  uv_wall_default_rebar_t: { label: `Ortbeton${softHyphen}wände Bewehrung`, unit: "t" },
  uv_wall_default_concrete_m3: {
    label: `Ortbeton${softHyphen}wände Betonage Kübel/Pumpe`,
    unit: "m³",
  },
  uv_wall_default_concrete_bucket_m3: {
    label: `Ortbeton${softHyphen}wände Betonage Kübel`,
    unit: "m³",
  },
  uv_wall_default_concrete_pump_m3: {
    label: `Ortbeton${softHyphen}wände Betonage Pumpe`,
    unit: "m³",
  },
  uv_wall_default_element_m2: {
    label: `Fertigteil${softHyphen}wände/${softHyphen}Hohl${softHyphen}kammer${softHyphen}wände`,
    unit: "m²",
  },
  uv_wall_parapet_m2: { label: "Brüstung", unit: "m²" },
  uv_wall_parapet_formwork_m2: { label: "Brüstung Schalung", unit: "m²" },
  uv_wall_parapet_rebar_t: { label: "Brüstung Bewehrung", unit: "t" },
  uv_wall_parapet_concrete_m3: { label: "Brüstung Betonage Kübel/Pumpe", unit: "m³" },
  uv_wall_parapet_concrete_bucket_m3: { label: "Brüstung Betonage Kübel", unit: "m³" },
  uv_wall_parapet_concrete_pump_m3: { label: "Brüstung Betonage Pumpe", unit: "m³" },
  uv_wall_column_m3: { label: `Ortbeton${softHyphen}stützen`, unit: "m³" },
  uv_wall_column_pc: { label: `Ortbeton${softHyphen}stützen`, unit: "Stk" },
  uv_wall_column_m2: { label: `Ortbeton${softHyphen}stützen`, unit: "m²" },
  uv_wall_column_formwork_m2: { label: `Ortbeton${softHyphen}stützen Schalung`, unit: "m²" },
  uv_wall_column_rebar_t: { label: `Ortbeton${softHyphen}stützen Bewehrung`, unit: "t" },
  uv_wall_column_concrete_bucket_m3: {
    label: `Ortbeton${softHyphen}stützen Betonage Kübel/${softHyphen}Pumpe`,
    unit: "m³",
  },
  uv_wall_column_concrete_bucket: {
    label: `Ortbeton${softHyphen}stützen Betonage Kübel`,
    unit: "m³",
  },
  uv_wall_column_concrete_pump: {
    label: `Ortbeton${softHyphen}stützen Betonage Pumpe`,
    unit: "m³",
  },
  uv_wall_column_element_pc: { label: `Fertigteil${softHyphen}stützen`, unit: "Stk" },
  uv_wall_brickwork_all_m2: { label: "Mauerwerk ges.", unit: "m²" },
  uv_wall_brick_outline_lm: { label: "Kimmschicht", unit: "lfm" },
  uv_wall_brickwork_m2: { label: "Mauerwerk", unit: "m²" },
  uv_ceiling_m2: { label: "Decke", unit: "m²" },
  uv_ceiling_default_formwork_m2: { label: `Decken${softHyphen}schalung`, unit: "m²" },
  uv_ceiling_element_semi_m2: { label: `Element${softHyphen}decken`, unit: "m²" },
  uv_ceiling_default_rebar: { label: `Decken${softHyphen}bewehrung`, unit: "t" },
  uv_ceiling_default_concrete_m3: { label: `Decken${softHyphen}betonage Kübel/Pumpe`, unit: "m³" },
  uv_ceiling_default_concrete_bucket_m3: { label: `Decken${softHyphen}betonage Kübel`, unit: "m³" },
  uv_ceiling_default_concrete_pump_m3: { label: `Decken${softHyphen}betonage Pumpe`, unit: "m³" },
  uv_ceiling_element_full_m2: { label: `Fertigteil${softHyphen}decken`, unit: "m²" },
  uv_ceiling_underbeams_m3: { label: "Unterzüge", unit: "m³" },
  uv_ceiling_underbeams_formwork_m2: { label: "Unterzug Schalung", unit: "m²" },
  uv_ceiling_underbeams_rebar_t: { label: "Unterzug Bewehrung", unit: "t" },
  uv_ceiling_underbeams_concrete_m3: {
    label: "Unterzug Betonage Kübel/Pumpe",
    unit: "m³",
  },
  uv_ceiling_underbeams_concrete_bucket_m3: {
    label: "Unterzug Betonage Kübel",
    unit: "m³",
  },
  uv_ceiling_underbeams_concrete_pump_m3: {
    label: "Unterzug Betonage Pumpe",
    unit: "m³",
  },
  uv_foundation_slab_m2: { label: "Bodenplatte", unit: "m²" },
  uv_foundation_slab_formwork_lm: { label: "Bodenplatte Schalung", unit: "lfm" },
  uv_foundation_slab_rebar_t: { label: "Bodenplatte Bewehrung", unit: "t" },
  uv_foundation_slab_concrete_m3: {
    label: "Bodenplatte Betonage Kübel/Pumpe",
    unit: "m³",
  },
  uv_foundation_slab_concrete_bucket_m3: {
    label: "Bodenplatte Betonage Kübel",
    unit: "m³",
  },
  uv_foundation_slab_concrete_pump_m3: {
    label: "Bodenplatte Betonage Pumpe",
    unit: "m³",
  },
  uv_foundation_point_m2: { label: `Einzel${softHyphen}fundamente`, unit: "m²" },
  uv_foundation_point_formwork_lm: { label: `Einzel${softHyphen}fundamente Schalung`, unit: "lfm" },
  uv_foundation_point_rebar_t: { label: `Einzel${softHyphen}fundamente Bewehrung`, unit: "t" },
  uv_foundation_point_concrete_m3: {
    label: `Einzel${softHyphen}fundamente Betonage Kübel/Pumpe`,
    unit: "m³",
  },
  uv_foundation_point_concrete_bucket_m3: {
    label: `Einzel${softHyphen}fundamente Betonage Kübel`,
    unit: "m³",
  },
  uv_foundation_point_concrete_pump_m3: {
    label: `Einzel${softHyphen}fundamente Betonage Pumpe`,
    unit: "m³",
  },
  uv_foundation_stripe_m2: { label: `Streifen${softHyphen}fundamente`, unit: "m²" },
  uv_foundation_stripe_formwork_lm: {
    label: `Streifen${softHyphen}fundamente Schalung`,
    unit: "lfm",
  },
  uv_foundation_stripe_rebar_t: { label: `Streifen${softHyphen}fundamente Bewehrung`, unit: "t" },
  uv_foundation_stripe_concrete_m3: {
    label: `Streifen${softHyphen}fundamente Betonage Kübel/${softHyphen}Pumpe`,
    unit: "m³",
  },
  uv_foundation_stripe_concrete_bucket_m3: {
    label: `Streifen${softHyphen}fundamente Betonage Kübel`,
    unit: "m³",
  },
  uv_foundation_stripe_concrete_pump_m3: {
    label: `Streifen${softHyphen}fundamente Betonage Pumpe`,
    unit: "m³",
  },
};

const getTypeItems = (keyword: string) => {
  const typeItems = Object.entries(typeLabelsAndUnits)
    .filter(([key]) => key.includes(keyword))
    .map(([key, value]) => ({
      ...value,
      key,
    }));
  typeItems.sort((a, b) => a.label.localeCompare(b.label));
  return typeItems;
};

export const allTypeGroups = [
  {
    key: "foundation",
    label: "Foundation",
    items: getTypeItems("foundation"),
  },
  {
    key: "wall",
    label: "Wall",
    items: getTypeItems("wall"),
  },
  {
    key: "ceiling",
    label: "Ceiling",
    items: getTypeItems("ceiling"),
  },
];
