import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import { ConfirmationModalArgs } from "@/types/Modals";

export const useCustomToast = () => {
  const toast = useToast();

  const modes = {
    error: (message: string) => toast.error(message, { toastClassName: "custom-toast-class" }),
    warning: (message: string) => toast.warning(message, { toastClassName: "custom-toast-class" }),
    success: (message: string) => toast.success(message, { toastClassName: "custom-toast-class" }),
  };

  return modes;
};

export const useConfirmationModal = () => {
  const store = useStore();

  return async (confirmationModalArgs: ConfirmationModalArgs) => {
    store.commit("setConfirmationModalArgs", confirmationModalArgs);
    return await new Promise<boolean>((resolve) => {
      const unsubscribe = store.subscribe((mutation) => {
        if (mutation.type === "setConfirmationModalConfirmed") {
          unsubscribe();
          store.commit("clearConfirmationModal");
          resolve(mutation.payload);
        }
      });
    });
  };
};

export const useSaveBeforeLeaveConfirmationModal = () => {
  const showConfirmationModal = useConfirmationModal();
  return async (confirmationModalArgs?: Partial<ConfirmationModalArgs>) => {
    const finalArgs = {
      header: "Unsaved edits",
      message: "You have edit sessions open. Please save before leaving!",
      confirmAction: "Leave anyway",
      cancelAction: "Continue editing",
      ...confirmationModalArgs,
    };
    return await showConfirmationModal(finalArgs);
  };
};
