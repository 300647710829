<template>
  <div class="grid grid-cols-3 gap-6 my-4" v-if="table">
    <div
      class="col-span-2 inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 borderedSection"
    >
      <h3 class="text-lg leading-4 font-semibold text-gray-900">@k-Metrics</h3>
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr class="divide-x divide-gray-200">
            <td></td>
            <th
              scope="col"
              class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
              v-for="threshold in thresholds"
              :key="threshold"
            >
              {{ threshold }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="variable in variables" :key="variable" class="divide-x divide-gray-200">
            <th class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
              {{ variable }}
            </th>
            <td
              class="whitespace-nowrap p-4 text-sm text-gray-500"
              v-for="threshold in thresholds"
              :key="threshold"
            >
              {{ roundFloatingNumber(table[`${variable}@${threshold}`]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-span-1 borderedSection">
      <h3 class="text-lg leading-4 font-semibold text-gray-900">IoU-Metrics</h3>
      <table class="min-w-full divide-y divide-gray-300">
        <tr v-for="(value, key) in IoUMetrics" :key="value" class="divide-x divide-gray-200">
          <th class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">{{ key }}</th>
          <td class="whitespace-nowrap p-4 text-sm text-gray-500">
            {{ roundFloatingNumber(value) }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    table: {
      type: [Object, null] as PropType<Record<string, number> | null>,
      required: true,
    },
  },

  data() {
    return {
      IoUMetrics: {} as Record<string, number>,
      thresholds: [] as Array<string>,
      variables: [] as Array<string>,
    };
  },

  watch: {
    table() {
      if (!this.table) return;
      this.IoUMetrics = Object.entries(this.table)
        .filter(([key]) => !key.includes("@"))
        .reduce((acc: Record<string, number>, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      this.tableHeader();
    },
  },

  methods: {
    roundFloatingNumber(value: number): number | string {
      if (Number.isFinite(value) && !Number.isInteger(value)) {
        return value.toFixed(3);
      } else {
        return value;
      }
    },
    tableHeader() {
      for (const key in this.table) {
        if (key.includes("@")) {
          const el = key.split("@");
          if (!this.thresholds.includes(el[1])) {
            this.thresholds.push(el[1]);
          }
          if (!this.variables.includes(el[0])) {
            this.variables.push(el[0]);
          }
        }
      }
    },
  },
});
</script>
