import { computed, Ref, ref } from "vue";
import { ScrollBarSizeEvent, ScrollContext, ScrollEvent } from "@/types/Scroll";

export const useScroll = () => {
  const defaultScrollBarContainerWidth = 32;
  const defaultScrollBarContainerHeight = 32;
  const scrollBarContainerSizeFactor = 2;

  const scrollTop = ref(0);
  const scrollLeft = ref(0);
  const scrollBarWidth = ref(0);
  const scrollBarHeight = ref(0);

  const handleScroll = (event: ScrollEvent) => {
    if (event.position === "vertical" || event.position === "both") {
      scrollTop.value = event.scrollTop;
    }
    if (event.position === "horizontal" || event.position === "both") {
      scrollLeft.value = event.scrollLeft;
    }
  };

  const handleScrollBarSizeChanged = (event: ScrollBarSizeEvent) => {
    if (event.position === "horizontal" || event.position === "both") {
      scrollBarHeight.value = event.height;
    }
    if (event.position === "vertical" || event.position === "both") {
      scrollBarWidth.value = event.width;
    }
  };

  const scrollBarContainerWidth = computed(() =>
    Math.max(defaultScrollBarContainerWidth, scrollBarWidth.value * scrollBarContainerSizeFactor),
  );

  const scrollBarContainerHeight = computed(() =>
    Math.max(defaultScrollBarContainerHeight, scrollBarHeight.value * scrollBarContainerSizeFactor),
  );

  const context: Ref<ScrollContext> = computed(() => ({
    scrollTop: scrollTop.value,
    scrollLeft: scrollLeft.value,
    scrollBarWidth: scrollBarWidth.value,
    scrollBarHeight: scrollBarHeight.value,
    scrollBarContainerWidth: scrollBarContainerWidth.value,
    scrollBarContainerHeight: scrollBarContainerHeight.value,
  }));

  return {
    context,
    handleScroll,
    handleScrollBarSizeChanged,
  };
};
