<template>
  <ModalTW :open="true" @onCloseModal="$emit('closed')" customCls="w-full m-2 lg:w-1/3">
    <template #content>
      <Chart :options="chartOptions" class="font-light" />
    </template>
  </ModalTW>
</template>

<script lang="ts" setup>
import Highcharts, { Options } from "highcharts";
import { Chart } from "highcharts-vue";
import more from "highcharts/highcharts-more";
import { computed, Ref } from "vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import { useAllHierarchyTags } from "@/composables/hierarchyTags";
import { useUnitValuesByType } from "@/composables/unitValues";
import numberService from "@/services/numberService";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { UnitValue, UnitValueAggregate, UnitValueTag } from "@/types/UnitValue";
import { precision } from "@/views/unit_values/unitValues";
import { typeLabelsAndUnits } from "../types";

more(Highcharts);

const props = defineProps<{ unitValueAggregate: UnitValueAggregate }>();

defineEmits<{ (eventName: "closed"): void }>();

const { unitValues } = useUnitValuesByType(props.unitValueAggregate.type);
const { hierarchyTags } = useAllHierarchyTags();

const typeLabelAndUnit = computed(() => typeLabelsAndUnits[props.unitValueAggregate.type]);

const hierarchyTagsById = computed(() =>
  (hierarchyTags.value || []).reduce((acc, hierarchyTag) => {
    acc[hierarchyTag._id] = hierarchyTag;
    return acc;
  }, {} as Record<string, HierarchyTagStore>),
);

const fixToPrecision = (number: number | null) =>
  number !== null ? numberService.fixToPrecision(number, precision) : undefined;

const unitValuesForPlot = computed(() =>
  unitValues.value?.filter(
    (unitValue) => unitValue.approved && !unitValue.ignored && unitValue.oai_value !== null,
  ),
);

const dedupe = (items: string[]) => {
  const set = new Set<string>();
  const result: string[] = [];
  for (const item of items) {
    if (!set.has(item)) {
      result.push(item);
      set.add(item);
    }
  }
  return result;
};

const getHierarchyTagLabels = (tags: UnitValueTag[]) => {
  const building = dedupe(
    tags
      .map((tag) => tag.building_id && hierarchyTagsById.value[tag.building_id]?.name)
      .filter((name) => name) as string[],
  ).join(", ");
  const level = dedupe(
    tags
      .map((tag) => tag.level_id && hierarchyTagsById.value[tag.level_id]?.name)
      .filter((name) => name) as string[],
  ).join(", ");
  const section = dedupe(
    tags
      .map((tag) => hierarchyTagsById.value[tag.section_id]?.name)
      .filter((name) => name) as string[],
  ).join(", ");
  return [building, level, section].filter((item) => item).join(" / ");
};

const getUnitValueLabel = (unitValue: UnitValue) => {
  const projectLabel = `${unitValue.customer_name}/${unitValue.site_id}`;
  const tagsLabel = getHierarchyTagLabels(unitValue.tags);
  return [projectLabel, tagsLabel]
    .filter((label) => label)
    .map((label) => `[${label}]`)
    .join(" ");
};

const chartOptions: Ref<Options> = computed(() => ({
  chart: {
    type: "boxplot",
  },
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  title: {
    text: `${typeLabelAndUnit.value.label} [${typeLabelAndUnit.value.unit}]`,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    categories: [],
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    title: {
      text: null,
    },
    plotLines: [
      {
        value: fixToPrecision(props.unitValueAggregate.average),
        color: "#E8A281",
        width: 2,
        dashStyle: "Dot",
        zIndex: 1000,
        label: {
          text: "Mean",
        },
      },
    ],
  },
  series: [
    {
      name: "Aggregates",
      type: "boxplot",
      color: "#60a5fa",
      data: [
        {
          low: fixToPrecision(props.unitValueAggregate.min),
          q1: fixToPrecision(props.unitValueAggregate.quantile_25th),
          median: fixToPrecision(props.unitValueAggregate.quantile_50th),
          q3: fixToPrecision(props.unitValueAggregate.quantile_75th),
          high: fixToPrecision(props.unitValueAggregate.max),
        },
      ],
      tooltip: {
        headerFormat: "",
      },
    },
    {
      name: "Unit values",
      type: "scatter",
      color: "#3b82f6",
      data: unitValuesForPlot.value?.map((unitValue) => ({
        x: 0,
        y: unitValue.oai_value,
        label: getUnitValueLabel(unitValue),
      })),
      marker: {
        fillColor: "#fff",
        lineWidth: 1,
        lineColor: "#60a5fa",
      },
      tooltip: {
        pointFormat: "{point.y} {point.label}",
      },
    },
  ],
}));
</script>
