import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { User, UserEveryGroup, UserGroup, UserProjectPermissionGroup } from "@/types/User";

export default defineComponent({
  computed: {
    currentCustomerName(): string | undefined {
      return this.route?.params?.customer_name as string | undefined;
    },
    currentSiteId(): string | undefined {
      return this.route?.params?.site_id as string | undefined;
    },
  },
  methods: {
    hasPermissionForUser(
      user: User,
      groups: UserEveryGroup | UserEveryGroup[] | (UserEveryGroup[] | UserEveryGroup)[],
      customerName?: string,
      siteId?: string,
    ) {
      const requiredGroups: UserEveryGroup[] | (UserEveryGroup[] | UserEveryGroup)[] =
        Array.isArray(groups) ? groups : [groups];

      if (
        requiredGroups.some((requiredGroup) =>
          Array.isArray(requiredGroup)
            ? requiredGroup.every((userGroup) => user.groups.includes(userGroup as UserGroup))
            : user.groups.includes(requiredGroup as UserGroup),
        )
      ) {
        return true;
      }

      customerName = customerName || this.currentCustomerName;
      siteId = siteId || this.currentSiteId;

      if (customerName && siteId) {
        const project = user.projects.find(
          (project) => project.customer_name === customerName && project.site_id === siteId,
        );

        if (
          requiredGroups.some((requiredGroup) =>
            Array.isArray(requiredGroup)
              ? requiredGroup.every((UserProjectPermissionGroup) =>
                  project?.groups.includes(
                    UserProjectPermissionGroup as UserProjectPermissionGroup,
                  ),
                )
              : project?.groups.includes(requiredGroup as UserProjectPermissionGroup),
          )
        ) {
          return true;
        }
      }

      return false;
    },
    hasPermission(
      groups: UserEveryGroup | UserEveryGroup[] | (UserEveryGroup[] | UserEveryGroup)[],
      customerName?: string,
      siteId?: string,
    ) {
      const user = this.$store.state.user as User | undefined;
      if (!user) {
        return false;
      }

      return this.hasPermissionForUser(user, groups, customerName, siteId);
    },
  },
  setup() {
    const route = useRoute();
    return { route };
  },
});
