import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EllipsisHorizontalIcon = _resolveComponent("EllipsisHorizontalIcon")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_Switch = _resolveComponent("Switch")!

  return (_openBlock(), _createBlock(_component_Switch, {
    "model-value": _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changed', $event))),
    class: _normalizeClass([
      _ctx.value && !_ctx.disabled ? 'bg-green-600' : 'bg-gray-200',
      !_ctx.disabled ? 'cursor-pointer' : 'cursor-default',
      'relative inline-flex h-6 w-11 shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
    ]),
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass([
        _ctx.value ? 'translate-x-5' : 'translate-x-0',
        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
      ])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass([
          _ctx.value ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
        ]),
          "aria-hidden": "true"
        }, [
          _createVNode(_component_EllipsisHorizontalIcon, { class: "h-3 w-3 text-gray-400" })
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass([
          _ctx.value ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
        ]),
          "aria-hidden": "true"
        }, [
          _createVNode(_component_CheckIcon, {
            class: _normalizeClass(["h-3 w-3", [_ctx.disabled ? 'text-gray-200' : 'text-green-600']])
          }, null, 8, ["class"])
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["model-value", "class", "disabled"]))
}