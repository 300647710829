<template>
  <div
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    v-show="open"
  >
    <div class="fixed inset-0 bg-gray-500/75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto" @click="$emit('onCloseModal')">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div
          :style="`max-height: ${maxHeight}`"
          :class="`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6 ${width}`"
          @click.stop
        >
          <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
            <button
              type="button"
              class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              @click="$emit('onCloseModal')"
            >
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="sm:items-start">
            <div class="text-md font-semibold leading-6 text-gray-900">
              <slot name="title" />
            </div>
            <div class="w-full">
              <div class="overflow-auto" :style="`max-height: calc(${maxHeight} - 150px);`">
                <slot name="content-full" />
              </div>
              <div class="grid grid-cols-4 gap-4">
                <div class="col-span-3">
                  <div class="overflow-auto" :style="`max-height: calc(${maxHeight} - 150px);`">
                    <slot name="content-left" />
                  </div>
                </div>
                <div class="col-span-1">
                  <slot name="content-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",
  props: ["open", "width", "maxHeight"],
  emits: ["onCloseModal"],
  components: {
    XMarkIcon,
  },
  data() {
    return {};
  },
});
</script>
