import { AxiosResponse } from "axios";
import { apiClient } from "@/repositories/clients";
import { Demo, DemoPayload } from "@/views/demo/types";

const loadDemos = () =>
  apiClient.get<void, AxiosResponse<Demo[]>>(`/demo/`).then((response) => response.data);

const createDemo = (demo: DemoPayload) =>
  apiClient.post<void, AxiosResponse<Demo>>(`/demo/`, demo).then((response) => response.data);

const updateDemo = (id: string, demo: DemoPayload) =>
  apiClient.patch<void, AxiosResponse<Demo>>(`/demo/${id}`, demo).then((response) => response.data);

const deleteDemo = (demoId: string) =>
  apiClient.delete<void, AxiosResponse<void>>(`/demo/${demoId}`).then((response) => response.data);

const deployDemos = () => apiClient.post<void, AxiosResponse<void>>(`/demo/deploy`);

export default {
  loadDemos,
  createDemo,
  updateDemo,
  deleteDemo,
  deployDemos,
};
